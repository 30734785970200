const ubigeoDetails = {
  100101: {
    code: '100101',
    district: 'Ica',
    province: 'Ica',
    department: 'Ica',
  },
  100102: {
    code: '100102',
    district: 'La Tinguiña',
    province: 'Ica',
    department: 'Ica',
  },
  100103: {
    code: '100103',
    district: 'Los Aquijes',
    province: 'Ica',
    department: 'Ica',
  },
  100104: {
    code: '100104',
    district: 'Parcona',
    province: 'Ica',
    department: 'Ica',
  },
  100105: {
    code: '100105',
    district: 'Pueblo Nuevo',
    province: 'Ica',
    department: 'Ica',
  },
  100106: {
    code: '100106',
    district: 'Salas',
    province: 'Ica',
    department: 'Ica',
  },
  100107: {
    code: '100107',
    district: 'San Jose de los Molinos',
    province: 'Ica',
    department: 'Ica',
  },
  100108: {
    code: '100108',
    district: 'San Juan Bautista',
    province: 'Ica',
    department: 'Ica',
  },
  100109: {
    code: '100109',
    district: 'Santiago',
    province: 'Ica',
    department: 'Ica',
  },
  100110: {
    code: '100110',
    district: 'Subtanjalla',
    province: 'Ica',
    department: 'Ica',
  },
  100111: {
    code: '100111',
    district: 'Yauca del Rosario',
    province: 'Ica',
    department: 'Ica',
  },
  100112: {
    code: '100112',
    district: 'Tate',
    province: 'Ica',
    department: 'Ica',
  },
  100113: {
    code: '100113',
    district: 'Pachacutec',
    province: 'Ica',
    department: 'Ica',
  },
  100114: {
    code: '100114',
    district: 'Ocucaje',
    province: 'Ica',
    department: 'Ica',
  },
  100201: {
    code: '100201',
    district: 'Chincha Alta',
    province: 'Chincha',
    department: 'Ica',
  },
  100202: {
    code: '100202',
    district: 'Chavin',
    province: 'Chincha',
    department: 'Ica',
  },
  100203: {
    code: '100203',
    district: 'Chincha Baja',
    province: 'Chincha',
    department: 'Ica',
  },
  100204: {
    code: '100204',
    district: 'El Carmen',
    province: 'Chincha',
    department: 'Ica',
  },
  100205: {
    code: '100205',
    district: 'Grocio Prado',
    province: 'Chincha',
    department: 'Ica',
  },
  100206: {
    code: '100206',
    district: 'San Pedro de Huacarpana',
    province: 'Chincha',
    department: 'Ica',
  },
  100207: {
    code: '100207',
    district: 'Sunampe',
    province: 'Chincha',
    department: 'Ica',
  },
  100208: {
    code: '100208',
    district: 'Tambo de Mora',
    province: 'Chincha',
    department: 'Ica',
  },
  100209: {
    code: '100209',
    district: 'Alto Laran',
    province: 'Chincha',
    department: 'Ica',
  },
  100210: {
    code: '100210',
    district: 'Pueblo Nuevo',
    province: 'Chincha',
    department: 'Ica',
  },
  100211: {
    code: '100211',
    district: 'San Juan de Yanac',
    province: 'Chincha',
    department: 'Ica',
  },
  100301: {
    code: '100301',
    district: 'Nazca',
    province: 'Nazca',
    department: 'Ica',
  },
  100302: {
    code: '100302',
    district: 'Changuillo',
    province: 'Nazca',
    department: 'Ica',
  },
  100303: {
    code: '100303',
    district: 'El Ingenio',
    province: 'Nazca',
    department: 'Ica',
  },
  100304: {
    code: '100304',
    district: 'Marcona',
    province: 'Nazca',
    department: 'Ica',
  },
  100305: {
    code: '100305',
    district: 'Vista Alegre',
    province: 'Nazca',
    department: 'Ica',
  },
  100401: {
    code: '100401',
    district: 'Pisco',
    province: 'Pisco',
    department: 'Ica',
  },
  100402: {
    code: '100402',
    district: 'Huancano',
    province: 'Pisco',
    department: 'Ica',
  },
  100403: {
    code: '100403',
    district: 'Humay',
    province: 'Pisco',
    department: 'Ica',
  },
  100404: {
    code: '100404',
    district: 'Independencia',
    province: 'Pisco',
    department: 'Ica',
  },
  100405: {
    code: '100405',
    district: 'Paracas',
    province: 'Pisco',
    department: 'Ica',
  },
  100406: {
    code: '100406',
    district: 'San Andres',
    province: 'Pisco',
    department: 'Ica',
  },
  100407: {
    code: '100407',
    district: 'San Clemente',
    province: 'Pisco',
    department: 'Ica',
  },
  100408: {
    code: '100408',
    district: 'Tupac Amaru Inca',
    province: 'Pisco',
    department: 'Ica',
  },
  100501: {
    code: '100501',
    district: 'Palpa',
    province: 'Palpa',
    department: 'Ica',
  },
  100502: {
    code: '100502',
    district: 'Llipata',
    province: 'Palpa',
    department: 'Ica',
  },
  100503: {
    code: '100503',
    district: 'Rio Grande',
    province: 'Palpa',
    department: 'Ica',
  },
  100504: {
    code: '100504',
    district: 'Santa Cruz',
    province: 'Palpa',
    department: 'Ica',
  },
  100505: {
    code: '100505',
    district: 'Tibillo',
    province: 'Palpa',
    department: 'Ica',
  },
  110101: {
    code: '110101',
    district: 'Huancayo',
    province: 'Huancayo',
    department: 'Junin',
  },
  110103: {
    code: '110103',
    district: 'Carhuacallanga',
    province: 'Huancayo',
    department: 'Junin',
  },
  110104: {
    code: '110104',
    district: 'Colca',
    province: 'Huancayo',
    department: 'Junin',
  },
  110105: {
    code: '110105',
    district: 'Cullhuas',
    province: 'Huancayo',
    department: 'Junin',
  },
  110106: {
    code: '110106',
    district: 'Chacapampa',
    province: 'Huancayo',
    department: 'Junin',
  },
  110107: {
    code: '110107',
    district: 'Chicche',
    province: 'Huancayo',
    department: 'Junin',
  },
  110108: {
    code: '110108',
    district: 'Chilca',
    province: 'Huancayo',
    department: 'Junin',
  },
  110109: {
    code: '110109',
    district: 'Chongos Alto',
    province: 'Huancayo',
    department: 'Junin',
  },
  110112: {
    code: '110112',
    district: 'Chupuro',
    province: 'Huancayo',
    department: 'Junin',
  },
  110113: {
    code: '110113',
    district: 'El Tambo',
    province: 'Huancayo',
    department: 'Junin',
  },
  110114: {
    code: '110114',
    district: 'Huacrapuquio',
    province: 'Huancayo',
    department: 'Junin',
  },
  110116: {
    code: '110116',
    district: 'Hualhuas',
    province: 'Huancayo',
    department: 'Junin',
  },
  110118: {
    code: '110118',
    district: 'Huancan',
    province: 'Huancayo',
    department: 'Junin',
  },
  110119: {
    code: '110119',
    district: 'Huasicancha',
    province: 'Huancayo',
    department: 'Junin',
  },
  110120: {
    code: '110120',
    district: 'Huayucachi',
    province: 'Huancayo',
    department: 'Junin',
  },
  110121: {
    code: '110121',
    district: 'Ingenio',
    province: 'Huancayo',
    department: 'Junin',
  },
  110122: {
    code: '110122',
    district: 'Pariahuanca',
    province: 'Huancayo',
    department: 'Junin',
  },
  110123: {
    code: '110123',
    district: 'Pilcomayo',
    province: 'Huancayo',
    department: 'Junin',
  },
  110124: {
    code: '110124',
    district: 'Pucara',
    province: 'Huancayo',
    department: 'Junin',
  },
  110125: {
    code: '110125',
    district: 'Quichuay',
    province: 'Huancayo',
    department: 'Junin',
  },
  110126: {
    code: '110126',
    district: 'Quilcas',
    province: 'Huancayo',
    department: 'Junin',
  },
  110127: {
    code: '110127',
    district: 'San Agustin',
    province: 'Huancayo',
    department: 'Junin',
  },
  110128: {
    code: '110128',
    district: 'San Jeronimo de Tunan',
    province: 'Huancayo',
    department: 'Junin',
  },
  110131: {
    code: '110131',
    district: 'Santo Domingo de Acobamba',
    province: 'Huancayo',
    department: 'Junin',
  },
  110132: {
    code: '110132',
    district: 'Saño',
    province: 'Huancayo',
    department: 'Junin',
  },
  110133: {
    code: '110133',
    district: 'Sapallanga',
    province: 'Huancayo',
    department: 'Junin',
  },
  110134: {
    code: '110134',
    district: 'Sicaya',
    province: 'Huancayo',
    department: 'Junin',
  },
  110136: {
    code: '110136',
    district: 'Viques',
    province: 'Huancayo',
    department: 'Junin',
  },
  110201: {
    code: '110201',
    district: 'Concepcion',
    province: 'Concepcion',
    department: 'Junin',
  },
  110202: {
    code: '110202',
    district: 'Aco',
    province: 'Concepcion',
    department: 'Junin',
  },
  110203: {
    code: '110203',
    district: 'Andamarca',
    province: 'Concepcion',
    department: 'Junin',
  },
  110204: {
    code: '110204',
    district: 'Comas',
    province: 'Concepcion',
    department: 'Junin',
  },
  110205: {
    code: '110205',
    district: 'Cochas',
    province: 'Concepcion',
    department: 'Junin',
  },
  110206: {
    code: '110206',
    district: 'Chambara',
    province: 'Concepcion',
    department: 'Junin',
  },
  110207: {
    code: '110207',
    district: 'Heroinas Toledo',
    province: 'Concepcion',
    department: 'Junin',
  },
  110208: {
    code: '110208',
    district: 'Manzanares',
    province: 'Concepcion',
    department: 'Junin',
  },
  110209: {
    code: '110209',
    district: 'Mariscal Castilla',
    province: 'Concepcion',
    department: 'Junin',
  },
  110210: {
    code: '110210',
    district: 'Matahuasi',
    province: 'Concepcion',
    department: 'Junin',
  },
  110211: {
    code: '110211',
    district: 'Mito',
    province: 'Concepcion',
    department: 'Junin',
  },
  110212: {
    code: '110212',
    district: 'Nueve de Julio',
    province: 'Concepcion',
    department: 'Junin',
  },
  110213: {
    code: '110213',
    district: 'Orcotuna',
    province: 'Concepcion',
    department: 'Junin',
  },
  110214: {
    code: '110214',
    district: 'Santa Rosa de Ocopa',
    province: 'Concepcion',
    department: 'Junin',
  },
  110215: {
    code: '110215',
    district: 'San Jose de Quero',
    province: 'Concepcion',
    department: 'Junin',
  },
  110301: {
    code: '110301',
    district: 'Jauja',
    province: 'Jauja',
    department: 'Junin',
  },
  110302: {
    code: '110302',
    district: 'Acolla',
    province: 'Jauja',
    department: 'Junin',
  },
  110303: {
    code: '110303',
    district: 'Apata',
    province: 'Jauja',
    department: 'Junin',
  },
  110304: {
    code: '110304',
    district: 'Ataura',
    province: 'Jauja',
    department: 'Junin',
  },
  110305: {
    code: '110305',
    district: 'Canchayllo',
    province: 'Jauja',
    department: 'Junin',
  },
  110306: {
    code: '110306',
    district: 'El Mantaro',
    province: 'Jauja',
    department: 'Junin',
  },
  110307: {
    code: '110307',
    district: 'Huamali',
    province: 'Jauja',
    department: 'Junin',
  },
  110308: {
    code: '110308',
    district: 'Huaripampa',
    province: 'Jauja',
    department: 'Junin',
  },
  110309: {
    code: '110309',
    district: 'Huertas',
    province: 'Jauja',
    department: 'Junin',
  },
  110310: {
    code: '110310',
    district: 'Janjaillo',
    province: 'Jauja',
    department: 'Junin',
  },
  110311: {
    code: '110311',
    district: 'Julcan',
    province: 'Jauja',
    department: 'Junin',
  },
  110312: {
    code: '110312',
    district: 'Leonor Ordoñez',
    province: 'Jauja',
    department: 'Junin',
  },
  110313: {
    code: '110313',
    district: 'Llocllapampa',
    province: 'Jauja',
    department: 'Junin',
  },
  110314: {
    code: '110314',
    district: 'Marco',
    province: 'Jauja',
    department: 'Junin',
  },
  110315: {
    code: '110315',
    district: 'Masma',
    province: 'Jauja',
    department: 'Junin',
  },
  110316: {
    code: '110316',
    district: 'Molinos',
    province: 'Jauja',
    department: 'Junin',
  },
  110317: {
    code: '110317',
    district: 'Monobamba',
    province: 'Jauja',
    department: 'Junin',
  },
  110318: {
    code: '110318',
    district: 'Muqui',
    province: 'Jauja',
    department: 'Junin',
  },
  110319: {
    code: '110319',
    district: 'Muquiyauyo',
    province: 'Jauja',
    department: 'Junin',
  },
  110320: {
    code: '110320',
    district: 'Paca',
    province: 'Jauja',
    department: 'Junin',
  },
  110321: {
    code: '110321',
    district: 'Paccha',
    province: 'Jauja',
    department: 'Junin',
  },
  110322: {
    code: '110322',
    district: 'Pancan',
    province: 'Jauja',
    department: 'Junin',
  },
  110323: {
    code: '110323',
    district: 'Parco',
    province: 'Jauja',
    department: 'Junin',
  },
  110324: {
    code: '110324',
    district: 'Pomacancha',
    province: 'Jauja',
    department: 'Junin',
  },
  110325: {
    code: '110325',
    district: 'Ricran',
    province: 'Jauja',
    department: 'Junin',
  },
  110326: {
    code: '110326',
    district: 'San Lorenzo',
    province: 'Jauja',
    department: 'Junin',
  },
  110327: {
    code: '110327',
    district: 'San Pedro de Chunan',
    province: 'Jauja',
    department: 'Junin',
  },
  110328: {
    code: '110328',
    district: 'Sincos',
    province: 'Jauja',
    department: 'Junin',
  },
  110329: {
    code: '110329',
    district: 'Tunan Marca',
    province: 'Jauja',
    department: 'Junin',
  },
  110330: {
    code: '110330',
    district: 'Yauli',
    province: 'Jauja',
    department: 'Junin',
  },
  110331: {
    code: '110331',
    district: 'Curicaca',
    province: 'Jauja',
    department: 'Junin',
  },
  110332: {
    code: '110332',
    district: 'Masma Chicche',
    province: 'Jauja',
    department: 'Junin',
  },
  110333: {
    code: '110333',
    district: 'Sausa',
    province: 'Jauja',
    department: 'Junin',
  },
  110334: {
    code: '110334',
    district: 'Yauyos',
    province: 'Jauja',
    department: 'Junin',
  },
  110401: {
    code: '110401',
    district: 'Junin',
    province: 'Junin',
    department: 'Junin',
  },
  110402: {
    code: '110402',
    district: 'Carhuamayo',
    province: 'Junin',
    department: 'Junin',
  },
  110403: {
    code: '110403',
    district: 'Ondores',
    province: 'Junin',
    department: 'Junin',
  },
  110404: {
    code: '110404',
    district: 'Ulcumayo',
    province: 'Junin',
    department: 'Junin',
  },
  110501: {
    code: '110501',
    district: 'Tarma',
    province: 'Tarma',
    department: 'Junin',
  },
  110502: {
    code: '110502',
    district: 'Acobamba',
    province: 'Tarma',
    department: 'Junin',
  },
  110503: {
    code: '110503',
    district: 'Huaricolca',
    province: 'Tarma',
    department: 'Junin',
  },
  110504: {
    code: '110504',
    district: 'Huasahuasi',
    province: 'Tarma',
    department: 'Junin',
  },
  110505: {
    code: '110505',
    district: 'La Union',
    province: 'Tarma',
    department: 'Junin',
  },
  110506: {
    code: '110506',
    district: 'Palca',
    province: 'Tarma',
    department: 'Junin',
  },
  110507: {
    code: '110507',
    district: 'Palcamayo',
    province: 'Tarma',
    department: 'Junin',
  },
  110508: {
    code: '110508',
    district: 'San Pedro de Cajas',
    province: 'Tarma',
    department: 'Junin',
  },
  110509: {
    code: '110509',
    district: 'Tapo',
    province: 'Tarma',
    department: 'Junin',
  },
  110601: {
    code: '110601',
    district: 'La Oroya',
    province: 'Yauli',
    department: 'Junin',
  },
  110602: {
    code: '110602',
    district: 'Chacapalpa',
    province: 'Yauli',
    department: 'Junin',
  },
  110603: {
    code: '110603',
    district: 'Huay-Huay',
    province: 'Yauli',
    department: 'Junin',
  },
  110604: {
    code: '110604',
    district: 'Marcapomacocha',
    province: 'Yauli',
    department: 'Junin',
  },
  110605: {
    code: '110605',
    district: 'Morococha',
    province: 'Yauli',
    department: 'Junin',
  },
  110606: {
    code: '110606',
    district: 'Paccha',
    province: 'Yauli',
    department: 'Junin',
  },
  110607: {
    code: '110607',
    district: 'Santa Barbara de Carhuacayan',
    province: 'Yauli',
    department: 'Junin',
  },
  110608: {
    code: '110608',
    district: 'Suitucancha',
    province: 'Yauli',
    department: 'Junin',
  },
  110609: {
    code: '110609',
    district: 'Yauli',
    province: 'Yauli',
    department: 'Junin',
  },
  110610: {
    code: '110610',
    district: 'Santa Rosa de Sacco',
    province: 'Yauli',
    department: 'Junin',
  },
  110701: {
    code: '110701',
    district: 'Satipo',
    province: 'Satipo',
    department: 'Junin',
  },
  110702: {
    code: '110702',
    district: 'Coviriali',
    province: 'Satipo',
    department: 'Junin',
  },
  110703: {
    code: '110703',
    district: 'Llaylla',
    province: 'Satipo',
    department: 'Junin',
  },
  110704: {
    code: '110704',
    district: 'Mazamari',
    province: 'Satipo',
    department: 'Junin',
  },
  110705: {
    code: '110705',
    district: 'Pampa Hermosa',
    province: 'Satipo',
    department: 'Junin',
  },
  110706: {
    code: '110706',
    district: 'Pangoa',
    province: 'Satipo',
    department: 'Junin',
  },
  110707: {
    code: '110707',
    district: 'Rio Negro',
    province: 'Satipo',
    department: 'Junin',
  },
  110708: {
    code: '110708',
    district: 'Rio Tambo',
    province: 'Satipo',
    department: 'Junin',
  },
  110801: {
    code: '110801',
    district: 'Chanchamayo',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110802: {
    code: '110802',
    district: 'San Ramon',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110803: {
    code: '110803',
    district: 'Vitoc',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110804: {
    code: '110804',
    district: 'San Luis de Shuaro',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110805: {
    code: '110805',
    district: 'Pichanaqui',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110806: {
    code: '110806',
    district: 'Perene',
    province: 'Chanchamayo',
    department: 'Junin',
  },
  110901: {
    code: '110901',
    district: 'Chupaca',
    province: 'Chupaca',
    department: 'Junin',
  },
  110902: {
    code: '110902',
    district: 'Ahuac',
    province: 'Chupaca',
    department: 'Junin',
  },
  110903: {
    code: '110903',
    district: 'Chongos Bajo',
    province: 'Chupaca',
    department: 'Junin',
  },
  110904: {
    code: '110904',
    district: 'Huachac',
    province: 'Chupaca',
    department: 'Junin',
  },
  110905: {
    code: '110905',
    district: 'Huamancaca Chico',
    province: 'Chupaca',
    department: 'Junin',
  },
  110906: {
    code: '110906',
    district: 'San Juan de Yscos',
    province: 'Chupaca',
    department: 'Junin',
  },
  110907: {
    code: '110907',
    district: 'San Juan de Jarpa',
    province: 'Chupaca',
    department: 'Junin',
  },
  110908: {
    code: '110908',
    district: 'Tres de Diciembre',
    province: 'Chupaca',
    department: 'Junin',
  },
  110909: {
    code: '110909',
    district: 'Yanacancha',
    province: 'Chupaca',
    department: 'Junin',
  },
  120101: {
    code: '120101',
    district: 'Trujillo',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120102: {
    code: '120102',
    district: 'Huanchaco',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120103: {
    code: '120103',
    district: 'Laredo',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120104: {
    code: '120104',
    district: 'Moche',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120105: {
    code: '120105',
    district: 'Salaverry',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120106: {
    code: '120106',
    district: 'Simbal',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120107: {
    code: '120107',
    district: 'Victor Larco Herrera',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120109: {
    code: '120109',
    district: 'Poroto',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120110: {
    code: '120110',
    district: 'El Porvenir',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120111: {
    code: '120111',
    district: 'La Esperanza',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120112: {
    code: '120112',
    district: 'Florencia de Mora',
    province: 'Trujillo',
    department: 'La Libertad',
  },
  120201: {
    code: '120201',
    district: 'Bolivar',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120202: {
    code: '120202',
    district: 'Bambamarca',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120203: {
    code: '120203',
    district: 'Condormarca',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120204: {
    code: '120204',
    district: 'Longotea',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120205: {
    code: '120205',
    district: 'Ucuncha',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120206: {
    code: '120206',
    district: 'Uchumarca',
    province: 'Bolivar',
    department: 'La Libertad',
  },
  120301: {
    code: '120301',
    district: 'Huamachuco',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120302: {
    code: '120302',
    district: 'Cochorco',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120303: {
    code: '120303',
    district: 'Curgos',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120304: {
    code: '120304',
    district: 'Chugay',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120305: {
    code: '120305',
    district: 'Marcabal',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120306: {
    code: '120306',
    district: 'Sanagoran',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120307: {
    code: '120307',
    district: 'Sarin',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120308: {
    code: '120308',
    district: 'Sartimbamba',
    province: 'Sanchez Carrion',
    department: 'La Libertad',
  },
  120401: {
    code: '120401',
    district: 'Otuzco',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120402: {
    code: '120402',
    district: 'Agallpampa',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120403: {
    code: '120403',
    district: 'Charat',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120404: {
    code: '120404',
    district: 'Huaranchal',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120405: {
    code: '120405',
    district: 'La Cuesta',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120408: {
    code: '120408',
    district: 'Paranday',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120409: {
    code: '120409',
    district: 'Salpo',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120410: {
    code: '120410',
    district: 'Sinsicap',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120411: {
    code: '120411',
    district: 'Usquil',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120413: {
    code: '120413',
    district: 'Mache',
    province: 'Otuzco',
    department: 'La Libertad',
  },
  120501: {
    code: '120501',
    district: 'San Pedro de Lloc',
    province: 'Pacasmayo',
    department: 'La Libertad',
  },
  120503: {
    code: '120503',
    district: 'Guadalupe',
    province: 'Pacasmayo',
    department: 'La Libertad',
  },
  120504: {
    code: '120504',
    district: 'Jequetepeque',
    province: 'Pacasmayo',
    department: 'La Libertad',
  },
  120506: {
    code: '120506',
    district: 'Pacasmayo',
    province: 'Pacasmayo',
    department: 'La Libertad',
  },
  120508: {
    code: '120508',
    district: 'San Jose',
    province: 'Pacasmayo',
    department: 'La Libertad',
  },
  120601: {
    code: '120601',
    district: 'Tayabamba',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120602: {
    code: '120602',
    district: 'Buldibuyo',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120603: {
    code: '120603',
    district: 'Chillia',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120604: {
    code: '120604',
    district: 'Huaylillas',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120605: {
    code: '120605',
    district: 'Huancaspata',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120606: {
    code: '120606',
    district: 'Huayo',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120607: {
    code: '120607',
    district: 'Ongon',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120608: {
    code: '120608',
    district: 'Parcoy',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120609: {
    code: '120609',
    district: 'Pataz',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120610: {
    code: '120610',
    district: 'Pias',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120611: {
    code: '120611',
    district: 'Taurija',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120612: {
    code: '120612',
    district: 'Urpay',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120613: {
    code: '120613',
    district: 'Santiago de Challas',
    province: 'Pataz',
    department: 'La Libertad',
  },
  120701: {
    code: '120701',
    district: 'Santiago de Chuco',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120702: {
    code: '120702',
    district: 'Cachicadan',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120703: {
    code: '120703',
    district: 'Mollebamba',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120704: {
    code: '120704',
    district: 'Mollepata',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120705: {
    code: '120705',
    district: 'Quiruvilca',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120706: {
    code: '120706',
    district: 'Santa Cruz de Chuca',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120707: {
    code: '120707',
    district: 'Sitabamba',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120708: {
    code: '120708',
    district: 'Angasmarca',
    province: 'Santiago de Chuco',
    department: 'La Libertad',
  },
  120801: {
    code: '120801',
    district: 'Ascope',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120802: {
    code: '120802',
    district: 'Chicama',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120803: {
    code: '120803',
    district: 'Chocope',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120804: {
    code: '120804',
    district: 'Santiago de Cao',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120805: {
    code: '120805',
    district: 'Magdalena de Cao',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120806: {
    code: '120806',
    district: 'Paijan',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120807: {
    code: '120807',
    district: 'Razuri',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120808: {
    code: '120808',
    district: 'Casa Grande',
    province: 'Ascope',
    department: 'La Libertad',
  },
  120901: {
    code: '120901',
    district: 'Chepen',
    province: 'Chepen',
    department: 'La Libertad',
  },
  120902: {
    code: '120902',
    district: 'Pacanga',
    province: 'Chepen',
    department: 'La Libertad',
  },
  120903: {
    code: '120903',
    district: 'Pueblo Nuevo',
    province: 'Chepen',
    department: 'La Libertad',
  },
  121001: {
    code: '121001',
    district: 'Julcan',
    province: 'Julcan',
    department: 'La Libertad',
  },
  121002: {
    code: '121002',
    district: 'Carabamba',
    province: 'Julcan',
    department: 'La Libertad',
  },
  121003: {
    code: '121003',
    district: 'Calamarca',
    province: 'Julcan',
    department: 'La Libertad',
  },
  121004: {
    code: '121004',
    district: 'Huaso',
    province: 'Julcan',
    department: 'La Libertad',
  },
  121101: {
    code: '121101',
    district: 'Cascas',
    province: 'Gran Chimu',
    department: 'La Libertad',
  },
  121102: {
    code: '121102',
    district: 'Lucma',
    province: 'Gran Chimu',
    department: 'La Libertad',
  },
  121103: {
    code: '121103',
    district: 'Marmot',
    province: 'Gran Chimu',
    department: 'La Libertad',
  },
  121104: {
    code: '121104',
    district: 'Sayapullo',
    province: 'Gran Chimu',
    department: 'La Libertad',
  },
  121201: {
    code: '121201',
    district: 'Viru',
    province: 'Viru',
    department: 'La Libertad',
  },
  121202: {
    code: '121202',
    district: 'Chao',
    province: 'Viru',
    department: 'La Libertad',
  },
  121203: {
    code: '121203',
    district: 'Guadalupito',
    province: 'Viru',
    department: 'La Libertad',
  },
  130101: {
    code: '130101',
    district: 'Chiclayo',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130102: {
    code: '130102',
    district: 'Chongoyape',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130103: {
    code: '130103',
    district: 'Eten',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130104: {
    code: '130104',
    district: 'Eten Puerto',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130105: {
    code: '130105',
    district: 'Lagunas',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130106: {
    code: '130106',
    district: 'Monsefu',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130107: {
    code: '130107',
    district: 'Nueva Arica',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130108: {
    code: '130108',
    district: 'Oyotun',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130109: {
    code: '130109',
    district: 'Picsi',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130110: {
    code: '130110',
    district: 'Pimentel',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130111: {
    code: '130111',
    district: 'Reque',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130112: {
    code: '130112',
    district: 'Jose Leonardo Ortiz',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130113: {
    code: '130113',
    district: 'Santa Rosa',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130114: {
    code: '130114',
    district: 'Saña',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130115: {
    code: '130115',
    district: 'La Victoria',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130116: {
    code: '130116',
    district: 'Cayalti',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130117: {
    code: '130117',
    district: 'Patapo',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130118: {
    code: '130118',
    district: 'Pomalca',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130119: {
    code: '130119',
    district: 'Pucala',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130120: {
    code: '130120',
    district: 'Tuman',
    province: 'Chiclayo',
    department: 'Lambayeque',
  },
  130201: {
    code: '130201',
    district: 'Ferreñafe',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130202: {
    code: '130202',
    district: 'Incahuasi',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130203: {
    code: '130203',
    district: 'Cañaris',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130204: {
    code: '130204',
    district: 'Pitipo',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130205: {
    code: '130205',
    district: 'Pueblo Nuevo',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130206: {
    code: '130206',
    district: 'Manuel Antonio Mesones Muro',
    province: 'Ferreñafe',
    department: 'Lambayeque',
  },
  130301: {
    code: '130301',
    district: 'Lambayeque',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130302: {
    code: '130302',
    district: 'Chochope',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130303: {
    code: '130303',
    district: 'Illimo',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130304: {
    code: '130304',
    district: 'Jayanca',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130305: {
    code: '130305',
    district: 'Mochumi',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130306: {
    code: '130306',
    district: 'Morrope',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130307: {
    code: '130307',
    district: 'Motupe',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130308: {
    code: '130308',
    district: 'Olmos',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130309: {
    code: '130309',
    district: 'Pacora',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130310: {
    code: '130310',
    district: 'Salas',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130311: {
    code: '130311',
    district: 'San Jose',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  130312: {
    code: '130312',
    district: 'Tucume',
    province: 'Lambayeque',
    department: 'Lambayeque',
  },
  140101: {
    code: '140101',
    district: 'Lima',
    province: 'Lima',
    department: 'Lima',
  },
  140102: {
    code: '140102',
    district: 'Ancon',
    province: 'Lima',
    department: 'Lima',
  },
  140103: {
    code: '140103',
    district: 'Ate',
    province: 'Lima',
    department: 'Lima',
  },
  140104: {
    code: '140104',
    district: 'Breña',
    province: 'Lima',
    department: 'Lima',
  },
  140105: {
    code: '140105',
    district: 'Carabayllo',
    province: 'Lima',
    department: 'Lima',
  },
  140106: {
    code: '140106',
    district: 'Comas',
    province: 'Lima',
    department: 'Lima',
  },
  140107: {
    code: '140107',
    district: 'Chaclacayo',
    province: 'Lima',
    department: 'Lima',
  },
  140108: {
    code: '140108',
    district: 'Chorrillos',
    province: 'Lima',
    department: 'Lima',
  },
  140109: {
    code: '140109',
    district: 'La Victoria',
    province: 'Lima',
    department: 'Lima',
  },
  140110: {
    code: '140110',
    district: 'La Molina',
    province: 'Lima',
    department: 'Lima',
  },
  140111: {
    code: '140111',
    district: 'Lince',
    province: 'Lima',
    department: 'Lima',
  },
  140112: {
    code: '140112',
    district: 'Lurigancho',
    province: 'Lima',
    department: 'Lima',
  },
  140113: {
    code: '140113',
    district: 'Lurin',
    province: 'Lima',
    department: 'Lima',
  },
  140114: {
    code: '140114',
    district: 'Magdalena del Mar',
    province: 'Lima',
    department: 'Lima',
  },
  140115: {
    code: '140115',
    district: 'Miraflores',
    province: 'Lima',
    department: 'Lima',
  },
  140116: {
    code: '140116',
    district: 'Pachacamac',
    province: 'Lima',
    department: 'Lima',
  },
  140117: {
    code: '140117',
    district: 'Pueblo Libre',
    province: 'Lima',
    department: 'Lima',
  },
  140118: {
    code: '140118',
    district: 'Pucusana',
    province: 'Lima',
    department: 'Lima',
  },
  140119: {
    code: '140119',
    district: 'Puente Piedra',
    province: 'Lima',
    department: 'Lima',
  },
  140120: {
    code: '140120',
    district: 'Punta Hermosa',
    province: 'Lima',
    department: 'Lima',
  },
  140121: {
    code: '140121',
    district: 'Punta Negra',
    province: 'Lima',
    department: 'Lima',
  },
  140122: {
    code: '140122',
    district: 'Rimac',
    province: 'Lima',
    department: 'Lima',
  },
  140123: {
    code: '140123',
    district: 'San Bartolo',
    province: 'Lima',
    department: 'Lima',
  },
  140124: {
    code: '140124',
    district: 'San Isidro',
    province: 'Lima',
    department: 'Lima',
  },
  140125: {
    code: '140125',
    district: 'Barranco',
    province: 'Lima',
    department: 'Lima',
  },
  140126: {
    code: '140126',
    district: 'San Martin de Porres',
    province: 'Lima',
    department: 'Lima',
  },
  140127: {
    code: '140127',
    district: 'San Miguel',
    province: 'Lima',
    department: 'Lima',
  },
  140128: {
    code: '140128',
    district: 'Santa Maria del Mar',
    province: 'Lima',
    department: 'Lima',
  },
  140129: {
    code: '140129',
    district: 'Santa Rosa',
    province: 'Lima',
    department: 'Lima',
  },
  140130: {
    code: '140130',
    district: 'Santiago de Surco',
    province: 'Lima',
    department: 'Lima',
  },
  140131: {
    code: '140131',
    district: 'Surquillo',
    province: 'Lima',
    department: 'Lima',
  },
  140132: {
    code: '140132',
    district: 'Villa Maria del Triunfo',
    province: 'Lima',
    department: 'Lima',
  },
  140133: {
    code: '140133',
    district: 'Jesus Maria',
    province: 'Lima',
    department: 'Lima',
  },
  140134: {
    code: '140134',
    district: 'Independencia',
    province: 'Lima',
    department: 'Lima',
  },
  140135: {
    code: '140135',
    district: 'El Agustino',
    province: 'Lima',
    department: 'Lima',
  },
  140136: {
    code: '140136',
    district: 'San Juan de Miraflores',
    province: 'Lima',
    department: 'Lima',
  },
  140137: {
    code: '140137',
    district: 'San Juan de Lurigancho',
    province: 'Lima',
    department: 'Lima',
  },
  140138: {
    code: '140138',
    district: 'San Luis',
    province: 'Lima',
    department: 'Lima',
  },
  140139: {
    code: '140139',
    district: 'Cieneguilla',
    province: 'Lima',
    department: 'Lima',
  },
  140140: {
    code: '140140',
    district: 'San Borja',
    province: 'Lima',
    department: 'Lima',
  },
  140141: {
    code: '140141',
    district: 'Villa El Salvador',
    province: 'Lima',
    department: 'Lima',
  },
  140142: {
    code: '140142',
    district: 'Los Olivos',
    province: 'Lima',
    department: 'Lima',
  },
  140143: {
    code: '140143',
    district: 'Santa Anita',
    province: 'Lima',
    department: 'Lima',
  },
  140201: {
    code: '140201',
    district: 'Cajatambo',
    province: 'Cajatambo',
    department: 'Lima',
  },
  140205: {
    code: '140205',
    district: 'Copa',
    province: 'Cajatambo',
    department: 'Lima',
  },
  140206: {
    code: '140206',
    district: 'Gorgor',
    province: 'Cajatambo',
    department: 'Lima',
  },
  140207: {
    code: '140207',
    district: 'Huancapon',
    province: 'Cajatambo',
    department: 'Lima',
  },
  140208: {
    code: '140208',
    district: 'Manas',
    province: 'Cajatambo',
    department: 'Lima',
  },
  140301: {
    code: '140301',
    district: 'Canta',
    province: 'Canta',
    department: 'Lima',
  },
  140302: {
    code: '140302',
    district: 'Arahuay',
    province: 'Canta',
    department: 'Lima',
  },
  140303: {
    code: '140303',
    district: 'Huamantanga',
    province: 'Canta',
    department: 'Lima',
  },
  140304: {
    code: '140304',
    district: 'Huaros',
    province: 'Canta',
    department: 'Lima',
  },
  140305: {
    code: '140305',
    district: 'Lachaqui',
    province: 'Canta',
    department: 'Lima',
  },
  140306: {
    code: '140306',
    district: 'San Buenaventura',
    province: 'Canta',
    department: 'Lima',
  },
  140307: {
    code: '140307',
    district: 'Santa Rosa de Quives',
    province: 'Canta',
    department: 'Lima',
  },
  140401: {
    code: '140401',
    district: 'San Vicente de Cañete',
    province: 'Cañete',
    department: 'Lima',
  },
  140402: {
    code: '140402',
    district: 'Calango',
    province: 'Cañete',
    department: 'Lima',
  },
  140403: {
    code: '140403',
    district: 'Cerro Azul',
    province: 'Cañete',
    department: 'Lima',
  },
  140404: {
    code: '140404',
    district: 'Coayllo',
    province: 'Cañete',
    department: 'Lima',
  },
  140405: {
    code: '140405',
    district: 'Chilca',
    province: 'Cañete',
    department: 'Lima',
  },
  140406: {
    code: '140406',
    district: 'Imperial',
    province: 'Cañete',
    department: 'Lima',
  },
  140407: {
    code: '140407',
    district: 'Lunahuana',
    province: 'Cañete',
    department: 'Lima',
  },
  140408: {
    code: '140408',
    district: 'Mala',
    province: 'Cañete',
    department: 'Lima',
  },
  140409: {
    code: '140409',
    district: 'Nuevo Imperial',
    province: 'Cañete',
    department: 'Lima',
  },
  140410: {
    code: '140410',
    district: 'Pacaran',
    province: 'Cañete',
    department: 'Lima',
  },
  140411: {
    code: '140411',
    district: 'Quilmana',
    province: 'Cañete',
    department: 'Lima',
  },
  140412: {
    code: '140412',
    district: 'San Antonio',
    province: 'Cañete',
    department: 'Lima',
  },
  140413: {
    code: '140413',
    district: 'San Luis',
    province: 'Cañete',
    department: 'Lima',
  },
  140414: {
    code: '140414',
    district: 'Santa Cruz de Flores',
    province: 'Cañete',
    department: 'Lima',
  },
  140415: {
    code: '140415',
    district: 'Zuñiga',
    province: 'Cañete',
    department: 'Lima',
  },
  140416: {
    code: '140416',
    district: 'Asia',
    province: 'Cañete',
    department: 'Lima',
  },
  140501: {
    code: '140501',
    district: 'Huacho',
    province: 'Huaura',
    department: 'Lima',
  },
  140502: {
    code: '140502',
    district: 'Ambar',
    province: 'Huaura',
    department: 'Lima',
  },
  140504: {
    code: '140504',
    district: 'Caleta de Carquin',
    province: 'Huaura',
    department: 'Lima',
  },
  140505: {
    code: '140505',
    district: 'Checras',
    province: 'Huaura',
    department: 'Lima',
  },
  140506: {
    code: '140506',
    district: 'Hualmay',
    province: 'Huaura',
    department: 'Lima',
  },
  140507: {
    code: '140507',
    district: 'Huaura',
    province: 'Huaura',
    department: 'Lima',
  },
  140508: {
    code: '140508',
    district: 'Leoncio Prado',
    province: 'Huaura',
    department: 'Lima',
  },
  140509: {
    code: '140509',
    district: 'Paccho',
    province: 'Huaura',
    department: 'Lima',
  },
  140511: {
    code: '140511',
    district: 'Santa Leonor',
    province: 'Huaura',
    department: 'Lima',
  },
  140512: {
    code: '140512',
    district: 'Santa Maria',
    province: 'Huaura',
    department: 'Lima',
  },
  140513: {
    code: '140513',
    district: 'Sayan',
    province: 'Huaura',
    department: 'Lima',
  },
  140516: {
    code: '140516',
    district: 'Vegueta',
    province: 'Huaura',
    department: 'Lima',
  },
  140601: {
    code: '140601',
    district: 'Matucana',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140602: {
    code: '140602',
    district: 'Antioquia',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140603: {
    code: '140603',
    district: 'Callahuanca',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140604: {
    code: '140604',
    district: 'Carampoma',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140605: {
    code: '140605',
    district: 'San Pedro de Casta',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140606: {
    code: '140606',
    district: 'Cuenca',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140607: {
    code: '140607',
    district: 'Chicla',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140608: {
    code: '140608',
    district: 'Huanza',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140609: {
    code: '140609',
    district: 'Huarochiri',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140610: {
    code: '140610',
    district: 'Lahuaytambo',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140611: {
    code: '140611',
    district: 'Langa',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140612: {
    code: '140612',
    district: 'Mariatana',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140613: {
    code: '140613',
    district: 'Ricardo Palma',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140614: {
    code: '140614',
    district: 'San Andres de Tupicocha',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140615: {
    code: '140615',
    district: 'San Antonio',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140616: {
    code: '140616',
    district: 'San Bartolome',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140617: {
    code: '140617',
    district: 'San Damian',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140618: {
    code: '140618',
    district: 'Sangallaya',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140619: {
    code: '140619',
    district: 'San Juan de Tantaranche',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140620: {
    code: '140620',
    district: 'San Lorenzo de Quinti',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140621: {
    code: '140621',
    district: 'San Mateo',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140622: {
    code: '140622',
    district: 'San Mateo de Otao',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140623: {
    code: '140623',
    district: 'San Pedro de Huancayre',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140624: {
    code: '140624',
    district: 'Santa Cruz de Cocachacra',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140625: {
    code: '140625',
    district: 'Santa Eulalia',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140626: {
    code: '140626',
    district: 'Santiago de Anchucaya',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140627: {
    code: '140627',
    district: 'Santiago de Tuna',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140628: {
    code: '140628',
    district: 'Santo Domingo de los Olleros',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140629: {
    code: '140629',
    district: 'Surco',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140630: {
    code: '140630',
    district: 'Huachupampa',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140631: {
    code: '140631',
    district: 'Laraos',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140632: {
    code: '140632',
    district: 'San Juan de Iris',
    province: 'Huarochiri',
    department: 'Lima',
  },
  140701: {
    code: '140701',
    district: 'Yauyos',
    province: 'Yauyos',
    department: 'Lima',
  },
  140702: {
    code: '140702',
    district: 'Alis',
    province: 'Yauyos',
    department: 'Lima',
  },
  140703: {
    code: '140703',
    district: 'Ayauca',
    province: 'Yauyos',
    department: 'Lima',
  },
  140704: {
    code: '140704',
    district: 'Ayaviri',
    province: 'Yauyos',
    department: 'Lima',
  },
  140705: {
    code: '140705',
    district: 'Azangaro',
    province: 'Yauyos',
    department: 'Lima',
  },
  140706: {
    code: '140706',
    district: 'Cacra',
    province: 'Yauyos',
    department: 'Lima',
  },
  140707: {
    code: '140707',
    district: 'Carania',
    province: 'Yauyos',
    department: 'Lima',
  },
  140708: {
    code: '140708',
    district: 'Cochas',
    province: 'Yauyos',
    department: 'Lima',
  },
  140709: {
    code: '140709',
    district: 'Colonia',
    province: 'Yauyos',
    department: 'Lima',
  },
  140710: {
    code: '140710',
    district: 'Chocos',
    province: 'Yauyos',
    department: 'Lima',
  },
  140711: {
    code: '140711',
    district: 'Huampara',
    province: 'Yauyos',
    department: 'Lima',
  },
  140712: {
    code: '140712',
    district: 'Huancaya',
    province: 'Yauyos',
    department: 'Lima',
  },
  140713: {
    code: '140713',
    district: 'Huangascar',
    province: 'Yauyos',
    department: 'Lima',
  },
  140714: {
    code: '140714',
    district: 'Huantan',
    province: 'Yauyos',
    department: 'Lima',
  },
  140715: {
    code: '140715',
    district: 'Huañec',
    province: 'Yauyos',
    department: 'Lima',
  },
  140716: {
    code: '140716',
    district: 'Laraos',
    province: 'Yauyos',
    department: 'Lima',
  },
  140717: {
    code: '140717',
    district: 'Lincha',
    province: 'Yauyos',
    department: 'Lima',
  },
  140718: {
    code: '140718',
    district: 'Miraflores',
    province: 'Yauyos',
    department: 'Lima',
  },
  140719: {
    code: '140719',
    district: 'Omas',
    province: 'Yauyos',
    department: 'Lima',
  },
  140720: {
    code: '140720',
    district: 'Quinches',
    province: 'Yauyos',
    department: 'Lima',
  },
  140721: {
    code: '140721',
    district: 'Quinocay',
    province: 'Yauyos',
    department: 'Lima',
  },
  140722: {
    code: '140722',
    district: 'San Joaquin',
    province: 'Yauyos',
    department: 'Lima',
  },
  140723: {
    code: '140723',
    district: 'San Pedro de Pilas',
    province: 'Yauyos',
    department: 'Lima',
  },
  140724: {
    code: '140724',
    district: 'Tanta',
    province: 'Yauyos',
    department: 'Lima',
  },
  140725: {
    code: '140725',
    district: 'Tauripampa',
    province: 'Yauyos',
    department: 'Lima',
  },
  140726: {
    code: '140726',
    district: 'Tupe',
    province: 'Yauyos',
    department: 'Lima',
  },
  140727: {
    code: '140727',
    district: 'Tomas',
    province: 'Yauyos',
    department: 'Lima',
  },
  140728: {
    code: '140728',
    district: 'Viñac',
    province: 'Yauyos',
    department: 'Lima',
  },
  140729: {
    code: '140729',
    district: 'Vitis',
    province: 'Yauyos',
    department: 'Lima',
  },
  140730: {
    code: '140730',
    district: 'Hongos',
    province: 'Yauyos',
    department: 'Lima',
  },
  140731: {
    code: '140731',
    district: 'Madean',
    province: 'Yauyos',
    department: 'Lima',
  },
  140732: {
    code: '140732',
    district: 'Putinza',
    province: 'Yauyos',
    department: 'Lima',
  },
  140733: {
    code: '140733',
    district: 'Catahuasi',
    province: 'Yauyos',
    department: 'Lima',
  },
  140801: {
    code: '140801',
    district: 'Huaral',
    province: 'Huaral',
    department: 'Lima',
  },
  140802: {
    code: '140802',
    district: 'Atavillos Alto',
    province: 'Huaral',
    department: 'Lima',
  },
  140803: {
    code: '140803',
    district: 'Atavillos Bajo',
    province: 'Huaral',
    department: 'Lima',
  },
  140804: {
    code: '140804',
    district: 'Aucallama',
    province: 'Huaral',
    department: 'Lima',
  },
  140805: {
    code: '140805',
    district: 'Chancay',
    province: 'Huaral',
    department: 'Lima',
  },
  140806: {
    code: '140806',
    district: 'Ihuari',
    province: 'Huaral',
    department: 'Lima',
  },
  140807: {
    code: '140807',
    district: 'Lampian',
    province: 'Huaral',
    department: 'Lima',
  },
  140808: {
    code: '140808',
    district: 'Pacaraos',
    province: 'Huaral',
    department: 'Lima',
  },
  140809: {
    code: '140809',
    district: 'San Miguel de Acos',
    province: 'Huaral',
    department: 'Lima',
  },
  140810: {
    code: '140810',
    district: 'Veintisiete de Noviembre',
    province: 'Huaral',
    department: 'Lima',
  },
  140811: {
    code: '140811',
    district: 'Santa Cruz de Andamarca',
    province: 'Huaral',
    department: 'Lima',
  },
  140812: {
    code: '140812',
    district: 'Sumbilca',
    province: 'Huaral',
    department: 'Lima',
  },
  140901: {
    code: '140901',
    district: 'Barranca',
    province: 'Barranca',
    department: 'Lima',
  },
  140902: {
    code: '140902',
    district: 'Paramonga',
    province: 'Barranca',
    department: 'Lima',
  },
  140903: {
    code: '140903',
    district: 'Pativilca',
    province: 'Barranca',
    department: 'Lima',
  },
  140904: {
    code: '140904',
    district: 'Supe',
    province: 'Barranca',
    department: 'Lima',
  },
  140905: {
    code: '140905',
    district: 'Supe Puerto',
    province: 'Barranca',
    department: 'Lima',
  },
  141001: {
    code: '141001',
    district: 'Oyon',
    province: 'Oyon',
    department: 'Lima',
  },
  141002: {
    code: '141002',
    district: 'Navan',
    province: 'Oyon',
    department: 'Lima',
  },
  141003: {
    code: '141003',
    district: 'Caujul',
    province: 'Oyon',
    department: 'Lima',
  },
  141004: {
    code: '141004',
    district: 'Andajes',
    province: 'Oyon',
    department: 'Lima',
  },
  141005: {
    code: '141005',
    district: 'Pachangara',
    province: 'Oyon',
    department: 'Lima',
  },
  141006: {
    code: '141006',
    district: 'Cochamarca',
    province: 'Oyon',
    department: 'Lima',
  },
  150101: {
    code: '150101',
    district: 'Iquitos',
    province: 'Maynas',
    department: 'Loreto',
  },
  150102: {
    code: '150102',
    district: 'Alto Nanay',
    province: 'Maynas',
    department: 'Loreto',
  },
  150103: {
    code: '150103',
    district: 'Fernando Lores',
    province: 'Maynas',
    department: 'Loreto',
  },
  150104: {
    code: '150104',
    district: 'Las Amazonas',
    province: 'Maynas',
    department: 'Loreto',
  },
  150105: {
    code: '150105',
    district: 'Mazan',
    province: 'Maynas',
    department: 'Loreto',
  },
  150106: {
    code: '150106',
    district: 'Napo',
    province: 'Maynas',
    department: 'Loreto',
  },
  150107: {
    code: '150107',
    district: 'Putumayo',
    province: 'Maynas',
    department: 'Loreto',
  },
  150108: {
    code: '150108',
    district: 'Torres Causana',
    province: 'Maynas',
    department: 'Loreto',
  },
  150110: {
    code: '150110',
    district: 'Indiana',
    province: 'Maynas',
    department: 'Loreto',
  },
  150111: {
    code: '150111',
    district: 'Punchana',
    province: 'Maynas',
    department: 'Loreto',
  },
  150112: {
    code: '150112',
    district: 'Belen',
    province: 'Maynas',
    department: 'Loreto',
  },
  150113: {
    code: '150113',
    district: 'San Juan Bautista',
    province: 'Maynas',
    department: 'Loreto',
  },
  150114: {
    code: '150114',
    district: 'Teniente Manuel Clavero',
    province: 'Maynas',
    department: 'Loreto',
  },
  150201: {
    code: '150201',
    district: 'Yurimaguas',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150202: {
    code: '150202',
    district: 'Balsapuerto',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150205: {
    code: '150205',
    district: 'Jeberos',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150206: {
    code: '150206',
    district: 'Lagunas',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150210: {
    code: '150210',
    district: 'Santa Cruz',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150211: {
    code: '150211',
    district: 'Teniente Cesar Lopez Rojas',
    province: 'Alto Amazonas',
    department: 'Loreto',
  },
  150301: {
    code: '150301',
    district: 'Nauta',
    province: 'Loreto',
    department: 'Loreto',
  },
  150302: {
    code: '150302',
    district: 'Parinari',
    province: 'Loreto',
    department: 'Loreto',
  },
  150303: {
    code: '150303',
    district: 'Tigre',
    province: 'Loreto',
    department: 'Loreto',
  },
  150304: {
    code: '150304',
    district: 'Urarinas',
    province: 'Loreto',
    department: 'Loreto',
  },
  150305: {
    code: '150305',
    district: 'Trompeteros',
    province: 'Loreto',
    department: 'Loreto',
  },
  150401: {
    code: '150401',
    district: 'Requena',
    province: 'Requena',
    department: 'Loreto',
  },
  150402: {
    code: '150402',
    district: 'Alto Tapiche',
    province: 'Requena',
    department: 'Loreto',
  },
  150403: {
    code: '150403',
    district: 'Capelo',
    province: 'Requena',
    department: 'Loreto',
  },
  150404: {
    code: '150404',
    district: 'Emilio San Martin',
    province: 'Requena',
    department: 'Loreto',
  },
  150405: {
    code: '150405',
    district: 'Maquia',
    province: 'Requena',
    department: 'Loreto',
  },
  150406: {
    code: '150406',
    district: 'Puinahua',
    province: 'Requena',
    department: 'Loreto',
  },
  150407: {
    code: '150407',
    district: 'Saquena',
    province: 'Requena',
    department: 'Loreto',
  },
  150408: {
    code: '150408',
    district: 'Soplin',
    province: 'Requena',
    department: 'Loreto',
  },
  150409: {
    code: '150409',
    district: 'Tapiche',
    province: 'Requena',
    department: 'Loreto',
  },
  150410: {
    code: '150410',
    district: 'Jenaro Herrera',
    province: 'Requena',
    department: 'Loreto',
  },
  150411: {
    code: '150411',
    district: 'Yaquerana',
    province: 'Requena',
    department: 'Loreto',
  },
  150501: {
    code: '150501',
    district: 'Contamana',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150502: {
    code: '150502',
    district: 'Vargas Guerra',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150503: {
    code: '150503',
    district: 'Padre Marquez',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150504: {
    code: '150504',
    district: 'Pampa Hermosa',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150505: {
    code: '150505',
    district: 'Sarayacu',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150506: {
    code: '150506',
    district: 'Inahuaya',
    province: 'Ucayali',
    department: 'Loreto',
  },
  150601: {
    code: '150601',
    district: 'Ramon Castilla',
    province: 'Mariscal Ramon Castilla',
    department: 'Loreto',
  },
  150602: {
    code: '150602',
    district: 'Pebas',
    province: 'Mariscal Ramon Castilla',
    department: 'Loreto',
  },
  150603: {
    code: '150603',
    district: 'Yavari',
    province: 'Mariscal Ramon Castilla',
    department: 'Loreto',
  },
  150604: {
    code: '150604',
    district: 'San Pablo',
    province: 'Mariscal Ramon Castilla',
    department: 'Loreto',
  },
  150701: {
    code: '150701',
    district: 'Barranca',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  150702: {
    code: '150702',
    district: 'Andoas',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  150703: {
    code: '150703',
    district: 'Cahuapanas',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  150704: {
    code: '150704',
    district: 'Manseriche',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  150705: {
    code: '150705',
    district: 'Morona',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  150706: {
    code: '150706',
    district: 'Pastaza',
    province: 'Datem del Marañon',
    department: 'Loreto',
  },
  160101: {
    code: '160101',
    district: 'Tambopata',
    province: 'Tambopata',
    department: 'Madre de Dios',
  },
  160102: {
    code: '160102',
    district: 'Inambari',
    province: 'Tambopata',
    department: 'Madre de Dios',
  },
  160103: {
    code: '160103',
    district: 'Las Piedras',
    province: 'Tambopata',
    department: 'Madre de Dios',
  },
  160104: {
    code: '160104',
    district: 'Laberinto',
    province: 'Tambopata',
    department: 'Madre de Dios',
  },
  160201: {
    code: '160201',
    district: 'Manu',
    province: 'Manu',
    department: 'Madre de Dios',
  },
  160202: {
    code: '160202',
    district: 'Fitzcarrald',
    province: 'Manu',
    department: 'Madre de Dios',
  },
  160203: {
    code: '160203',
    district: 'Madre de Dios',
    province: 'Manu',
    department: 'Madre de Dios',
  },
  160204: {
    code: '160204',
    district: 'Huepetuhe',
    province: 'Manu',
    department: 'Madre de Dios',
  },
  160301: {
    code: '160301',
    district: 'Iñapari',
    province: 'Tahuamanu',
    department: 'Madre de Dios',
  },
  160302: {
    code: '160302',
    district: 'Iberia',
    province: 'Tahuamanu',
    department: 'Madre de Dios',
  },
  160303: {
    code: '160303',
    district: 'Tahuamanu',
    province: 'Tahuamanu',
    department: 'Madre de Dios',
  },
  170101: {
    code: '170101',
    district: 'Moquegua',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170102: {
    code: '170102',
    district: 'Carumas',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170103: {
    code: '170103',
    district: 'Cuchumbaya',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170104: {
    code: '170104',
    district: 'San Cristobal',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170105: {
    code: '170105',
    district: 'Torata',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170106: {
    code: '170106',
    district: 'Samegua',
    province: 'Mariscal Nieto',
    department: 'Moquegua',
  },
  170201: {
    code: '170201',
    district: 'Omate',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170202: {
    code: '170202',
    district: 'Coalaque',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170203: {
    code: '170203',
    district: 'Chojata',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170204: {
    code: '170204',
    district: 'Ichuña',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170205: {
    code: '170205',
    district: 'La Capilla',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170206: {
    code: '170206',
    district: 'Lloque',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170207: {
    code: '170207',
    district: 'Matalaque',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170208: {
    code: '170208',
    district: 'Puquina',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170209: {
    code: '170209',
    district: 'Quinistaquillas',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170210: {
    code: '170210',
    district: 'Ubinas',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170211: {
    code: '170211',
    district: 'Yunga',
    province: 'General Sanchez Cerro',
    department: 'Moquegua',
  },
  170301: {
    code: '170301',
    district: 'Ilo',
    province: 'Ilo',
    department: 'Moquegua',
  },
  170302: {
    code: '170302',
    district: 'El Algarrobal',
    province: 'Ilo',
    department: 'Moquegua',
  },
  170303: {
    code: '170303',
    district: 'Pacocha',
    province: 'Ilo',
    department: 'Moquegua',
  },
  180101: {
    code: '180101',
    district: 'Chaupimarca',
    province: 'Pasco',
    department: 'Pasco',
  },
  180103: {
    code: '180103',
    district: 'Huachon',
    province: 'Pasco',
    department: 'Pasco',
  },
  180104: {
    code: '180104',
    district: 'Huariaca',
    province: 'Pasco',
    department: 'Pasco',
  },
  180105: {
    code: '180105',
    district: 'Huayllay',
    province: 'Pasco',
    department: 'Pasco',
  },
  180106: {
    code: '180106',
    district: 'Ninacaca',
    province: 'Pasco',
    department: 'Pasco',
  },
  180107: {
    code: '180107',
    district: 'Pallanchacra',
    province: 'Pasco',
    department: 'Pasco',
  },
  180108: {
    code: '180108',
    district: 'Paucartambo',
    province: 'Pasco',
    department: 'Pasco',
  },
  180109: {
    code: '180109',
    district: 'San Francisco de Asis de Yarusyacan',
    province: 'Pasco',
    department: 'Pasco',
  },
  180110: {
    code: '180110',
    district: 'Simon Bolivar',
    province: 'Pasco',
    department: 'Pasco',
  },
  180111: {
    code: '180111',
    district: 'Ticlacayan',
    province: 'Pasco',
    department: 'Pasco',
  },
  180112: {
    code: '180112',
    district: 'Tinyahuarco',
    province: 'Pasco',
    department: 'Pasco',
  },
  180113: {
    code: '180113',
    district: 'Vicco',
    province: 'Pasco',
    department: 'Pasco',
  },
  180114: {
    code: '180114',
    district: 'Yanacancha',
    province: 'Pasco',
    department: 'Pasco',
  },
  180201: {
    code: '180201',
    district: 'Yanahuanca',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180202: {
    code: '180202',
    district: 'Chacayan',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180203: {
    code: '180203',
    district: 'Goyllarisquizga',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180204: {
    code: '180204',
    district: 'Paucar',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180205: {
    code: '180205',
    district: 'San Pedro de Pillao',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180206: {
    code: '180206',
    district: 'Santa Ana de Tusi',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180207: {
    code: '180207',
    district: 'Tapuc',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180208: {
    code: '180208',
    district: 'Vilcabamba',
    province: 'Daniel Alcides Carrion',
    department: 'Pasco',
  },
  180301: {
    code: '180301',
    district: 'Oxapampa',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180302: {
    code: '180302',
    district: 'Chontabamba',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180303: {
    code: '180303',
    district: 'Huancabamba',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180304: {
    code: '180304',
    district: 'Puerto Bermudez',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180305: {
    code: '180305',
    district: 'Villa Rica',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180306: {
    code: '180306',
    district: 'Pozuzo',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180307: {
    code: '180307',
    district: 'Palcazu',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  180308: {
    code: '180308',
    district: 'Constitucion',
    province: 'Oxapampa',
    department: 'Pasco',
  },
  190101: {
    code: '190101',
    district: 'Piura',
    province: 'Piura',
    department: 'Piura',
  },
  190103: {
    code: '190103',
    district: 'Castilla',
    province: 'Piura',
    department: 'Piura',
  },
  190104: {
    code: '190104',
    district: 'Catacaos',
    province: 'Piura',
    department: 'Piura',
  },
  190105: {
    code: '190105',
    district: 'La Arena',
    province: 'Piura',
    department: 'Piura',
  },
  190106: {
    code: '190106',
    district: 'La Union',
    province: 'Piura',
    department: 'Piura',
  },
  190107: {
    code: '190107',
    district: 'Las Lomas',
    province: 'Piura',
    department: 'Piura',
  },
  190109: {
    code: '190109',
    district: 'Tambo Grande',
    province: 'Piura',
    department: 'Piura',
  },
  190113: {
    code: '190113',
    district: 'Cura Mori',
    province: 'Piura',
    department: 'Piura',
  },
  190114: {
    code: '190114',
    district: 'El Tallan',
    province: 'Piura',
    department: 'Piura',
  },
  190201: {
    code: '190201',
    district: 'Ayabaca',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190202: {
    code: '190202',
    district: 'Frias',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190203: {
    code: '190203',
    district: 'Lagunas',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190204: {
    code: '190204',
    district: 'Montero',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190205: {
    code: '190205',
    district: 'Pacaipampa',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190206: {
    code: '190206',
    district: 'Sapillica',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190207: {
    code: '190207',
    district: 'Sicchez',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190208: {
    code: '190208',
    district: 'Suyo',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190209: {
    code: '190209',
    district: 'Jilili',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190210: {
    code: '190210',
    district: 'Paimas',
    province: 'Ayabaca',
    department: 'Piura',
  },
  190301: {
    code: '190301',
    district: 'Huancabamba',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190302: {
    code: '190302',
    district: 'Canchaque',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190303: {
    code: '190303',
    district: 'Huarmaca',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190304: {
    code: '190304',
    district: 'Sondor',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190305: {
    code: '190305',
    district: 'Sondorillo',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190306: {
    code: '190306',
    district: 'El Carmen de La Frontera',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190307: {
    code: '190307',
    district: 'San Miguel de El Faique',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190308: {
    code: '190308',
    district: 'Lalaquiz',
    province: 'Huancabamba',
    department: 'Piura',
  },
  190401: {
    code: '190401',
    district: 'Chulucanas',
    province: 'Morropon',
    department: 'Piura',
  },
  190402: {
    code: '190402',
    district: 'Buenos Aires',
    province: 'Morropon',
    department: 'Piura',
  },
  190403: {
    code: '190403',
    district: 'Chalaco',
    province: 'Morropon',
    department: 'Piura',
  },
  190404: {
    code: '190404',
    district: 'Morropon',
    province: 'Morropon',
    department: 'Piura',
  },
  190405: {
    code: '190405',
    district: 'Salitral',
    province: 'Morropon',
    department: 'Piura',
  },
  190406: {
    code: '190406',
    district: 'Santa Catalina de Mossa',
    province: 'Morropon',
    department: 'Piura',
  },
  190407: {
    code: '190407',
    district: 'Santo Domingo',
    province: 'Morropon',
    department: 'Piura',
  },
  190408: {
    code: '190408',
    district: 'La Matanza',
    province: 'Morropon',
    department: 'Piura',
  },
  190409: {
    code: '190409',
    district: 'Yamango',
    province: 'Morropon',
    department: 'Piura',
  },
  190410: {
    code: '190410',
    district: 'San Juan de Bigote',
    province: 'Morropon',
    department: 'Piura',
  },
  190501: {
    code: '190501',
    district: 'Paita',
    province: 'Paita',
    department: 'Piura',
  },
  190502: {
    code: '190502',
    district: 'Amotape',
    province: 'Paita',
    department: 'Piura',
  },
  190503: {
    code: '190503',
    district: 'Arenal',
    province: 'Paita',
    department: 'Piura',
  },
  190504: {
    code: '190504',
    district: 'La Huaca',
    province: 'Paita',
    department: 'Piura',
  },
  190505: {
    code: '190505',
    district: 'Colan',
    province: 'Paita',
    department: 'Piura',
  },
  190506: {
    code: '190506',
    district: 'Tamarindo',
    province: 'Paita',
    department: 'Piura',
  },
  190507: {
    code: '190507',
    district: 'Vichayal',
    province: 'Paita',
    department: 'Piura',
  },
  190601: {
    code: '190601',
    district: 'Sullana',
    province: 'Sullana',
    department: 'Piura',
  },
  190602: {
    code: '190602',
    district: 'Bellavista',
    province: 'Sullana',
    department: 'Piura',
  },
  190603: {
    code: '190603',
    district: 'Lancones',
    province: 'Sullana',
    department: 'Piura',
  },
  190604: {
    code: '190604',
    district: 'Marcavelica',
    province: 'Sullana',
    department: 'Piura',
  },
  190605: {
    code: '190605',
    district: 'Miguel Checa',
    province: 'Sullana',
    department: 'Piura',
  },
  190606: {
    code: '190606',
    district: 'Querecotillo',
    province: 'Sullana',
    department: 'Piura',
  },
  190607: {
    code: '190607',
    district: 'Salitral',
    province: 'Sullana',
    department: 'Piura',
  },
  190608: {
    code: '190608',
    district: 'Ignacio Escudero',
    province: 'Sullana',
    department: 'Piura',
  },
  190701: {
    code: '190701',
    district: 'Pariñas',
    province: 'Talara',
    department: 'Piura',
  },
  190702: {
    code: '190702',
    district: 'El Alto',
    province: 'Talara',
    department: 'Piura',
  },
  190703: {
    code: '190703',
    district: 'La Brea',
    province: 'Talara',
    department: 'Piura',
  },
  190704: {
    code: '190704',
    district: 'Lobitos',
    province: 'Talara',
    department: 'Piura',
  },
  190705: {
    code: '190705',
    district: 'Mancora',
    province: 'Talara',
    department: 'Piura',
  },
  190706: {
    code: '190706',
    district: 'Los Organos',
    province: 'Talara',
    department: 'Piura',
  },
  190801: {
    code: '190801',
    district: 'Sechura',
    province: 'Sechura',
    department: 'Piura',
  },
  190802: {
    code: '190802',
    district: 'Vice',
    province: 'Sechura',
    department: 'Piura',
  },
  190803: {
    code: '190803',
    district: 'Bernal',
    province: 'Sechura',
    department: 'Piura',
  },
  190804: {
    code: '190804',
    district: 'Bellavista de La Union',
    province: 'Sechura',
    department: 'Piura',
  },
  190805: {
    code: '190805',
    district: 'Cristo Nos Valga',
    province: 'Sechura',
    department: 'Piura',
  },
  190806: {
    code: '190806',
    district: 'Rinconada Llicuar',
    province: 'Sechura',
    department: 'Piura',
  },
  200101: {
    code: '200101',
    district: 'Puno',
    province: 'Puno',
    department: 'Puno',
  },
  200102: {
    code: '200102',
    district: 'Acora',
    province: 'Puno',
    department: 'Puno',
  },
  200103: {
    code: '200103',
    district: 'Atuncolla',
    province: 'Puno',
    department: 'Puno',
  },
  200104: {
    code: '200104',
    district: 'Capachica',
    province: 'Puno',
    department: 'Puno',
  },
  200105: {
    code: '200105',
    district: 'Coata',
    province: 'Puno',
    department: 'Puno',
  },
  200106: {
    code: '200106',
    district: 'Chucuito',
    province: 'Puno',
    department: 'Puno',
  },
  200107: {
    code: '200107',
    district: 'Huata',
    province: 'Puno',
    department: 'Puno',
  },
  200108: {
    code: '200108',
    district: 'Mañazo',
    province: 'Puno',
    department: 'Puno',
  },
  200109: {
    code: '200109',
    district: 'Paucarcolla',
    province: 'Puno',
    department: 'Puno',
  },
  200110: {
    code: '200110',
    district: 'Pichacani',
    province: 'Puno',
    department: 'Puno',
  },
  200111: {
    code: '200111',
    district: 'San Antonio',
    province: 'Puno',
    department: 'Puno',
  },
  200112: {
    code: '200112',
    district: 'Tiquillaca',
    province: 'Puno',
    department: 'Puno',
  },
  200113: {
    code: '200113',
    district: 'Vilque',
    province: 'Puno',
    department: 'Puno',
  },
  200114: {
    code: '200114',
    district: 'Plateria',
    province: 'Puno',
    department: 'Puno',
  },
  200115: {
    code: '200115',
    district: 'Amantani',
    province: 'Puno',
    department: 'Puno',
  },
  200201: {
    code: '200201',
    district: 'Azangaro',
    province: 'Azangaro',
    department: 'Puno',
  },
  200202: {
    code: '200202',
    district: 'Achaya',
    province: 'Azangaro',
    department: 'Puno',
  },
  200203: {
    code: '200203',
    district: 'Arapa',
    province: 'Azangaro',
    department: 'Puno',
  },
  200204: {
    code: '200204',
    district: 'Asillo',
    province: 'Azangaro',
    department: 'Puno',
  },
  200205: {
    code: '200205',
    district: 'Caminaca',
    province: 'Azangaro',
    department: 'Puno',
  },
  200206: {
    code: '200206',
    district: 'Chupa',
    province: 'Azangaro',
    department: 'Puno',
  },
  200207: {
    code: '200207',
    district: 'Jose Domingo Choquehuanca',
    province: 'Azangaro',
    department: 'Puno',
  },
  200208: {
    code: '200208',
    district: 'Muñani',
    province: 'Azangaro',
    department: 'Puno',
  },
  200210: {
    code: '200210',
    district: 'Potoni',
    province: 'Azangaro',
    department: 'Puno',
  },
  200212: {
    code: '200212',
    district: 'Saman',
    province: 'Azangaro',
    department: 'Puno',
  },
  200213: {
    code: '200213',
    district: 'San Anton',
    province: 'Azangaro',
    department: 'Puno',
  },
  200214: {
    code: '200214',
    district: 'San Jose',
    province: 'Azangaro',
    department: 'Puno',
  },
  200215: {
    code: '200215',
    district: 'San Juan de Salinas',
    province: 'Azangaro',
    department: 'Puno',
  },
  200216: {
    code: '200216',
    district: 'Santiago de Pupuja',
    province: 'Azangaro',
    department: 'Puno',
  },
  200217: {
    code: '200217',
    district: 'Tirapata',
    province: 'Azangaro',
    department: 'Puno',
  },
  200301: {
    code: '200301',
    district: 'Macusani',
    province: 'Carabaya',
    department: 'Puno',
  },
  200302: {
    code: '200302',
    district: 'Ajoyani',
    province: 'Carabaya',
    department: 'Puno',
  },
  200303: {
    code: '200303',
    district: 'Ayapata',
    province: 'Carabaya',
    department: 'Puno',
  },
  200304: {
    code: '200304',
    district: 'Coasa',
    province: 'Carabaya',
    department: 'Puno',
  },
  200305: {
    code: '200305',
    district: 'Corani',
    province: 'Carabaya',
    department: 'Puno',
  },
  200306: {
    code: '200306',
    district: 'Crucero',
    province: 'Carabaya',
    department: 'Puno',
  },
  200307: {
    code: '200307',
    district: 'Ituata',
    province: 'Carabaya',
    department: 'Puno',
  },
  200308: {
    code: '200308',
    district: 'Ollachea',
    province: 'Carabaya',
    department: 'Puno',
  },
  200309: {
    code: '200309',
    district: 'San Gaban',
    province: 'Carabaya',
    department: 'Puno',
  },
  200310: {
    code: '200310',
    district: 'Usicayos',
    province: 'Carabaya',
    department: 'Puno',
  },
  200401: {
    code: '200401',
    district: 'Juli',
    province: 'Chucuito',
    department: 'Puno',
  },
  200402: {
    code: '200402',
    district: 'Desaguadero',
    province: 'Chucuito',
    department: 'Puno',
  },
  200403: {
    code: '200403',
    district: 'Huacullani',
    province: 'Chucuito',
    department: 'Puno',
  },
  200406: {
    code: '200406',
    district: 'Pisacoma',
    province: 'Chucuito',
    department: 'Puno',
  },
  200407: {
    code: '200407',
    district: 'Pomata',
    province: 'Chucuito',
    department: 'Puno',
  },
  200410: {
    code: '200410',
    district: 'Zepita',
    province: 'Chucuito',
    department: 'Puno',
  },
  200412: {
    code: '200412',
    district: 'Kelluyo',
    province: 'Chucuito',
    department: 'Puno',
  },
  200501: {
    code: '200501',
    district: 'Huancane',
    province: 'Huancane',
    department: 'Puno',
  },
  200502: {
    code: '200502',
    district: 'Cojata',
    province: 'Huancane',
    department: 'Puno',
  },
  200504: {
    code: '200504',
    district: 'Inchupalla',
    province: 'Huancane',
    department: 'Puno',
  },
  200506: {
    code: '200506',
    district: 'Pusi',
    province: 'Huancane',
    department: 'Puno',
  },
  200507: {
    code: '200507',
    district: 'Rosaspata',
    province: 'Huancane',
    department: 'Puno',
  },
  200508: {
    code: '200508',
    district: 'Taraco',
    province: 'Huancane',
    department: 'Puno',
  },
  200509: {
    code: '200509',
    district: 'Vilque Chico',
    province: 'Huancane',
    department: 'Puno',
  },
  200511: {
    code: '200511',
    district: 'Huatasani',
    province: 'Huancane',
    department: 'Puno',
  },
  200601: {
    code: '200601',
    district: 'Lampa',
    province: 'Lampa',
    department: 'Puno',
  },
  200602: {
    code: '200602',
    district: 'Cabanilla',
    province: 'Lampa',
    department: 'Puno',
  },
  200603: {
    code: '200603',
    district: 'Calapuja',
    province: 'Lampa',
    department: 'Puno',
  },
  200604: {
    code: '200604',
    district: 'Nicasio',
    province: 'Lampa',
    department: 'Puno',
  },
  200605: {
    code: '200605',
    district: 'Ocuviri',
    province: 'Lampa',
    department: 'Puno',
  },
  200606: {
    code: '200606',
    district: 'Palca',
    province: 'Lampa',
    department: 'Puno',
  },
  200607: {
    code: '200607',
    district: 'Paratia',
    province: 'Lampa',
    department: 'Puno',
  },
  200608: {
    code: '200608',
    district: 'Pucara',
    province: 'Lampa',
    department: 'Puno',
  },
  200609: {
    code: '200609',
    district: 'Santa Lucia',
    province: 'Lampa',
    department: 'Puno',
  },
  200610: {
    code: '200610',
    district: 'Vilavila',
    province: 'Lampa',
    department: 'Puno',
  },
  200701: {
    code: '200701',
    district: 'Ayaviri',
    province: 'Melgar',
    department: 'Puno',
  },
  200702: {
    code: '200702',
    district: 'Antauta',
    province: 'Melgar',
    department: 'Puno',
  },
  200703: {
    code: '200703',
    district: 'Cupi',
    province: 'Melgar',
    department: 'Puno',
  },
  200704: {
    code: '200704',
    district: 'Llalli',
    province: 'Melgar',
    department: 'Puno',
  },
  200705: {
    code: '200705',
    district: 'Macari',
    province: 'Melgar',
    department: 'Puno',
  },
  200706: {
    code: '200706',
    district: 'Nuñoa',
    province: 'Melgar',
    department: 'Puno',
  },
  200707: {
    code: '200707',
    district: 'Orurillo',
    province: 'Melgar',
    department: 'Puno',
  },
  200708: {
    code: '200708',
    district: 'Santa Rosa',
    province: 'Melgar',
    department: 'Puno',
  },
  200709: {
    code: '200709',
    district: 'Umachiri',
    province: 'Melgar',
    department: 'Puno',
  },
  200801: {
    code: '200801',
    district: 'Sandia',
    province: 'Sandia',
    department: 'Puno',
  },
  200803: {
    code: '200803',
    district: 'Cuyocuyo',
    province: 'Sandia',
    department: 'Puno',
  },
  200804: {
    code: '200804',
    district: 'Limbani',
    province: 'Sandia',
    department: 'Puno',
  },
  200805: {
    code: '200805',
    district: 'Phara',
    province: 'Sandia',
    department: 'Puno',
  },
  200806: {
    code: '200806',
    district: 'Patambuco',
    province: 'Sandia',
    department: 'Puno',
  },
  200807: {
    code: '200807',
    district: 'Quiaca',
    province: 'Sandia',
    department: 'Puno',
  },
  200808: {
    code: '200808',
    district: 'San Juan del Oro',
    province: 'Sandia',
    department: 'Puno',
  },
  200810: {
    code: '200810',
    district: 'Yanahuaya',
    province: 'Sandia',
    department: 'Puno',
  },
  200811: {
    code: '200811',
    district: 'Alto Inambari',
    province: 'Sandia',
    department: 'Puno',
  },
  200812: {
    code: '200812',
    district: 'San Pedro de Putina Punco',
    province: 'Sandia',
    department: 'Puno',
  },
  200901: {
    code: '200901',
    district: 'Juliaca',
    province: 'San Roman',
    department: 'Puno',
  },
  200902: {
    code: '200902',
    district: 'Cabana',
    province: 'San Roman',
    department: 'Puno',
  },
  200903: {
    code: '200903',
    district: 'Cabanillas',
    province: 'San Roman',
    department: 'Puno',
  },
  200904: {
    code: '200904',
    district: 'Caracoto',
    province: 'San Roman',
    department: 'Puno',
  },
  201001: {
    code: '201001',
    district: 'Yunguyo',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201002: {
    code: '201002',
    district: 'Unicachi',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201003: {
    code: '201003',
    district: 'Anapia',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201004: {
    code: '201004',
    district: 'Copani',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201005: {
    code: '201005',
    district: 'Cuturapi',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201006: {
    code: '201006',
    district: 'Ollaraya',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201007: {
    code: '201007',
    district: 'Tinicachi',
    province: 'Yunguyo',
    department: 'Puno',
  },
  201101: {
    code: '201101',
    district: 'Putina',
    province: 'San Antonio de Putina',
    department: 'Puno',
  },
  201102: {
    code: '201102',
    district: 'Pedro Vilca Apaza',
    province: 'San Antonio de Putina',
    department: 'Puno',
  },
  201103: {
    code: '201103',
    district: 'Quilcapuncu',
    province: 'San Antonio de Putina',
    department: 'Puno',
  },
  201104: {
    code: '201104',
    district: 'Ananea',
    province: 'San Antonio de Putina',
    department: 'Puno',
  },
  201105: {
    code: '201105',
    district: 'Sina',
    province: 'San Antonio de Putina',
    department: 'Puno',
  },
  201201: {
    code: '201201',
    district: 'Ilave',
    province: 'El Collao',
    department: 'Puno',
  },
  201202: {
    code: '201202',
    district: 'Pilcuyo',
    province: 'El Collao',
    department: 'Puno',
  },
  201203: {
    code: '201203',
    district: 'Santa Rosa',
    province: 'El Collao',
    department: 'Puno',
  },
  201204: {
    code: '201204',
    district: 'Capazo',
    province: 'El Collao',
    department: 'Puno',
  },
  201205: {
    code: '201205',
    district: 'Conduriri',
    province: 'El Collao',
    department: 'Puno',
  },
  201301: {
    code: '201301',
    district: 'Moho',
    province: 'Moho',
    department: 'Puno',
  },
  201302: {
    code: '201302',
    district: 'Conima',
    province: 'Moho',
    department: 'Puno',
  },
  201303: {
    code: '201303',
    district: 'Tilali',
    province: 'Moho',
    department: 'Puno',
  },
  201304: {
    code: '201304',
    district: 'Huayrapata',
    province: 'Moho',
    department: 'Puno',
  },
  210101: {
    code: '210101',
    district: 'Moyobamba',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210102: {
    code: '210102',
    district: 'Calzada',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210103: {
    code: '210103',
    district: 'Habana',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210104: {
    code: '210104',
    district: 'Jepelacio',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210105: {
    code: '210105',
    district: 'Soritor',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210106: {
    code: '210106',
    district: 'Yantalo',
    province: 'Moyobamba',
    department: 'San Martin',
  },
  210201: {
    code: '210201',
    district: 'Saposoa',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210202: {
    code: '210202',
    district: 'Piscoyacu',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210203: {
    code: '210203',
    district: 'Sacanche',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210204: {
    code: '210204',
    district: 'Tingo de Saposoa',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210205: {
    code: '210205',
    district: 'Alto Saposoa',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210206: {
    code: '210206',
    district: 'El Eslabon',
    province: 'Huallaga',
    department: 'San Martin',
  },
  210301: {
    code: '210301',
    district: 'Lamas',
    province: 'Lamas',
    department: 'San Martin',
  },
  210303: {
    code: '210303',
    district: 'Barranquita',
    province: 'Lamas',
    department: 'San Martin',
  },
  210304: {
    code: '210304',
    district: 'Caynarachi',
    province: 'Lamas',
    department: 'San Martin',
  },
  210305: {
    code: '210305',
    district: 'Cuñumbuqui',
    province: 'Lamas',
    department: 'San Martin',
  },
  210306: {
    code: '210306',
    district: 'Pinto Recodo',
    province: 'Lamas',
    department: 'San Martin',
  },
  210307: {
    code: '210307',
    district: 'Rumisapa',
    province: 'Lamas',
    department: 'San Martin',
  },
  210311: {
    code: '210311',
    district: 'Shanao',
    province: 'Lamas',
    department: 'San Martin',
  },
  210313: {
    code: '210313',
    district: 'Tabalosos',
    province: 'Lamas',
    department: 'San Martin',
  },
  210314: {
    code: '210314',
    district: 'Zapatero',
    province: 'Lamas',
    department: 'San Martin',
  },
  210315: {
    code: '210315',
    district: 'Alonso de Alvarado',
    province: 'Lamas',
    department: 'San Martin',
  },
  210316: {
    code: '210316',
    district: 'San Roque de Cumbaza',
    province: 'Lamas',
    department: 'San Martin',
  },
  210401: {
    code: '210401',
    district: 'Juanjui',
    province: 'Mariscal Caceres',
    department: 'San Martin',
  },
  210402: {
    code: '210402',
    district: 'Campanilla',
    province: 'Mariscal Caceres',
    department: 'San Martin',
  },
  210403: {
    code: '210403',
    district: 'Huicungo',
    province: 'Mariscal Caceres',
    department: 'San Martin',
  },
  210404: {
    code: '210404',
    district: 'Pachiza',
    province: 'Mariscal Caceres',
    department: 'San Martin',
  },
  210405: {
    code: '210405',
    district: 'Pajarillo',
    province: 'Mariscal Caceres',
    department: 'San Martin',
  },
  210501: {
    code: '210501',
    district: 'Rioja',
    province: 'Rioja',
    department: 'San Martin',
  },
  210502: {
    code: '210502',
    district: 'Posic',
    province: 'Rioja',
    department: 'San Martin',
  },
  210503: {
    code: '210503',
    district: 'Yorongos',
    province: 'Rioja',
    department: 'San Martin',
  },
  210504: {
    code: '210504',
    district: 'Yuracyacu',
    province: 'Rioja',
    department: 'San Martin',
  },
  210505: {
    code: '210505',
    district: 'Nueva Cajamarca',
    province: 'Rioja',
    department: 'San Martin',
  },
  210506: {
    code: '210506',
    district: 'Elias Soplin Vargas',
    province: 'Rioja',
    department: 'San Martin',
  },
  210507: {
    code: '210507',
    district: 'San Fernando',
    province: 'Rioja',
    department: 'San Martin',
  },
  210508: {
    code: '210508',
    district: 'Pardo Miguel',
    province: 'Rioja',
    department: 'San Martin',
  },
  210509: {
    code: '210509',
    district: 'Awajun',
    province: 'Rioja',
    department: 'San Martin',
  },
  210601: {
    code: '210601',
    district: 'Tarapoto',
    province: 'San Martin',
    department: 'San Martin',
  },
  210602: {
    code: '210602',
    district: 'Alberto Leveau',
    province: 'San Martin',
    department: 'San Martin',
  },
  210604: {
    code: '210604',
    district: 'Cacatachi',
    province: 'San Martin',
    department: 'San Martin',
  },
  210606: {
    code: '210606',
    district: 'Chazuta',
    province: 'San Martin',
    department: 'San Martin',
  },
  210607: {
    code: '210607',
    district: 'Chipurana',
    province: 'San Martin',
    department: 'San Martin',
  },
  210608: {
    code: '210608',
    district: 'El Porvenir',
    province: 'San Martin',
    department: 'San Martin',
  },
  210609: {
    code: '210609',
    district: 'Huimbayoc',
    province: 'San Martin',
    department: 'San Martin',
  },
  210610: {
    code: '210610',
    district: 'Juan Guerra',
    province: 'San Martin',
    department: 'San Martin',
  },
  210611: {
    code: '210611',
    district: 'Morales',
    province: 'San Martin',
    department: 'San Martin',
  },
  210612: {
    code: '210612',
    district: 'Papaplaya',
    province: 'San Martin',
    department: 'San Martin',
  },
  210616: {
    code: '210616',
    district: 'San Antonio',
    province: 'San Martin',
    department: 'San Martin',
  },
  210619: {
    code: '210619',
    district: 'Sauce',
    province: 'San Martin',
    department: 'San Martin',
  },
  210620: {
    code: '210620',
    district: 'Shapaja',
    province: 'San Martin',
    department: 'San Martin',
  },
  210621: {
    code: '210621',
    district: 'La Banda de Shilcayo',
    province: 'San Martin',
    department: 'San Martin',
  },
  210701: {
    code: '210701',
    district: 'Bellavista',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210702: {
    code: '210702',
    district: 'San Rafael',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210703: {
    code: '210703',
    district: 'San Pablo',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210704: {
    code: '210704',
    district: 'Alto Biavo',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210705: {
    code: '210705',
    district: 'Huallaga',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210706: {
    code: '210706',
    district: 'Bajo Biavo',
    province: 'Bellavista',
    department: 'San Martin',
  },
  210801: {
    code: '210801',
    district: 'Tocache',
    province: 'Tocache',
    department: 'San Martin',
  },
  210802: {
    code: '210802',
    district: 'Nuevo Progreso',
    province: 'Tocache',
    department: 'San Martin',
  },
  210803: {
    code: '210803',
    district: 'Polvora',
    province: 'Tocache',
    department: 'San Martin',
  },
  210804: {
    code: '210804',
    district: 'Shunte',
    province: 'Tocache',
    department: 'San Martin',
  },
  210805: {
    code: '210805',
    district: 'Uchiza',
    province: 'Tocache',
    department: 'San Martin',
  },
  210901: {
    code: '210901',
    district: 'Picota',
    province: 'Picota',
    department: 'San Martin',
  },
  210902: {
    code: '210902',
    district: 'Buenos Aires',
    province: 'Picota',
    department: 'San Martin',
  },
  210903: {
    code: '210903',
    district: 'Caspisapa',
    province: 'Picota',
    department: 'San Martin',
  },
  210904: {
    code: '210904',
    district: 'Pilluana',
    province: 'Picota',
    department: 'San Martin',
  },
  210905: {
    code: '210905',
    district: 'Pucacaca',
    province: 'Picota',
    department: 'San Martin',
  },
  210906: {
    code: '210906',
    district: 'San Cristobal',
    province: 'Picota',
    department: 'San Martin',
  },
  210907: {
    code: '210907',
    district: 'San Hilarion',
    province: 'Picota',
    department: 'San Martin',
  },
  210908: {
    code: '210908',
    district: 'Tingo de Ponasa',
    province: 'Picota',
    department: 'San Martin',
  },
  210909: {
    code: '210909',
    district: 'Tres Unidos',
    province: 'Picota',
    department: 'San Martin',
  },
  210910: {
    code: '210910',
    district: 'Shamboyacu',
    province: 'Picota',
    department: 'San Martin',
  },
  211001: {
    code: '211001',
    district: 'San Jose de Sisa',
    province: 'El Dorado',
    department: 'San Martin',
  },
  211002: {
    code: '211002',
    district: 'Agua Blanca',
    province: 'El Dorado',
    department: 'San Martin',
  },
  211003: {
    code: '211003',
    district: 'Shatoja',
    province: 'El Dorado',
    department: 'San Martin',
  },
  211004: {
    code: '211004',
    district: 'San Martin',
    province: 'El Dorado',
    department: 'San Martin',
  },
  211005: {
    code: '211005',
    district: 'Santa Rosa',
    province: 'El Dorado',
    department: 'San Martin',
  },
  220101: {
    code: '220101',
    district: 'Tacna',
    province: 'Tacna',
    department: 'Tacna',
  },
  220102: {
    code: '220102',
    district: 'Calana',
    province: 'Tacna',
    department: 'Tacna',
  },
  220104: {
    code: '220104',
    district: 'Inclan',
    province: 'Tacna',
    department: 'Tacna',
  },
  220107: {
    code: '220107',
    district: 'Pachia',
    province: 'Tacna',
    department: 'Tacna',
  },
  220108: {
    code: '220108',
    district: 'Palca',
    province: 'Tacna',
    department: 'Tacna',
  },
  220109: {
    code: '220109',
    district: 'Pocollay',
    province: 'Tacna',
    department: 'Tacna',
  },
  220110: {
    code: '220110',
    district: 'Sama',
    province: 'Tacna',
    department: 'Tacna',
  },
  220111: {
    code: '220111',
    district: 'Alto de La Alianza',
    province: 'Tacna',
    department: 'Tacna',
  },
  220112: {
    code: '220112',
    district: 'Ciudad Nueva',
    province: 'Tacna',
    department: 'Tacna',
  },
  220113: {
    code: '220113',
    district: 'Coronel Gregorio Albarracin Lanchipa',
    province: 'Tacna',
    department: 'Tacna',
  },
  220201: {
    code: '220201',
    district: 'Tarata',
    province: 'Tarata',
    department: 'Tacna',
  },
  220205: {
    code: '220205',
    district: 'Heroes Albarracin',
    province: 'Tarata',
    department: 'Tacna',
  },
  220206: {
    code: '220206',
    district: 'Estique',
    province: 'Tarata',
    department: 'Tacna',
  },
  220207: {
    code: '220207',
    district: 'Estique-Pampa',
    province: 'Tarata',
    department: 'Tacna',
  },
  220210: {
    code: '220210',
    district: 'Sitajara',
    province: 'Tarata',
    department: 'Tacna',
  },
  220211: {
    code: '220211',
    district: 'Susapaya',
    province: 'Tarata',
    department: 'Tacna',
  },
  220212: {
    code: '220212',
    district: 'Tarucachi',
    province: 'Tarata',
    department: 'Tacna',
  },
  220213: {
    code: '220213',
    district: 'Ticaco',
    province: 'Tarata',
    department: 'Tacna',
  },
  220301: {
    code: '220301',
    district: 'Locumba',
    province: 'Jorge Basadre',
    department: 'Tacna',
  },
  220302: {
    code: '220302',
    district: 'Ite',
    province: 'Jorge Basadre',
    department: 'Tacna',
  },
  220303: {
    code: '220303',
    district: 'Ilabaya',
    province: 'Jorge Basadre',
    department: 'Tacna',
  },
  220401: {
    code: '220401',
    district: 'Candarave',
    province: 'Candarave',
    department: 'Tacna',
  },
  220402: {
    code: '220402',
    district: 'Cairani',
    province: 'Candarave',
    department: 'Tacna',
  },
  220403: {
    code: '220403',
    district: 'Curibaya',
    province: 'Candarave',
    department: 'Tacna',
  },
  220404: {
    code: '220404',
    district: 'Huanuara',
    province: 'Candarave',
    department: 'Tacna',
  },
  220405: {
    code: '220405',
    district: 'Quilahuani',
    province: 'Candarave',
    department: 'Tacna',
  },
  220406: {
    code: '220406',
    district: 'Camilaca',
    province: 'Candarave',
    department: 'Tacna',
  },
  230101: {
    code: '230101',
    district: 'Tumbes',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230102: {
    code: '230102',
    district: 'Corrales',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230103: {
    code: '230103',
    district: 'La Cruz',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230104: {
    code: '230104',
    district: 'Pampas de Hospital',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230105: {
    code: '230105',
    district: 'San Jacinto',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230106: {
    code: '230106',
    district: 'San Juan de La Virgen',
    province: 'Tumbes',
    department: 'Tumbes',
  },
  230201: {
    code: '230201',
    district: 'Zorritos',
    province: 'Contralmirante Villar',
    department: 'Tumbes',
  },
  230202: {
    code: '230202',
    district: 'Casitas',
    province: 'Contralmirante Villar',
    department: 'Tumbes',
  },
  230203: {
    code: '230203',
    district: 'Canoas de Punta Sal',
    province: 'Contralmirante Villar',
    department: 'Tumbes',
  },
  230301: {
    code: '230301',
    district: 'Zarumilla',
    province: 'Zarumilla',
    department: 'Tumbes',
  },
  230302: {
    code: '230302',
    district: 'Matapalo',
    province: 'Zarumilla',
    department: 'Tumbes',
  },
  230303: {
    code: '230303',
    district: 'Papayal',
    province: 'Zarumilla',
    department: 'Tumbes',
  },
  230304: {
    code: '230304',
    district: 'Aguas Verdes',
    province: 'Zarumilla',
    department: 'Tumbes',
  },
  240101: {
    code: '240101',
    district: 'Callao',
    province: 'Callao',
    department: 'Callao',
  },
  240102: {
    code: '240102',
    district: 'Bellavista',
    province: 'Callao',
    department: 'Callao',
  },
  240103: {
    code: '240103',
    district: 'La Punta',
    province: 'Callao',
    department: 'Callao',
  },
  240104: {
    code: '240104',
    district: 'Carmen de La Legua',
    province: 'Callao',
    department: 'Callao',
  },
  240105: {
    code: '240105',
    district: 'La Perla',
    province: 'Callao',
    department: 'Callao',
  },
  240106: {
    code: '240106',
    district: 'Ventanilla',
    province: 'Callao',
    department: 'Callao',
  },
  250101: {
    code: '250101',
    district: 'Calleria',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250102: {
    code: '250102',
    district: 'Yarinacocha',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250103: {
    code: '250103',
    district: 'Masisea',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250104: {
    code: '250104',
    district: 'Campoverde',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250105: {
    code: '250105',
    district: 'Iparia',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250106: {
    code: '250106',
    district: 'Nueva Requena',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250107: {
    code: '250107',
    district: 'Manantay',
    province: 'Coronel Portillo',
    department: 'Ucayali',
  },
  250201: {
    code: '250201',
    district: 'Padre Abad',
    province: 'Padre Abad',
    department: 'Ucayali',
  },
  250202: {
    code: '250202',
    district: 'Irazola',
    province: 'Padre Abad',
    department: 'Ucayali',
  },
  250203: {
    code: '250203',
    district: 'Curimana',
    province: 'Padre Abad',
    department: 'Ucayali',
  },
  250301: {
    code: '250301',
    district: 'Raymondi',
    province: 'Atalaya',
    department: 'Ucayali',
  },
  250302: {
    code: '250302',
    district: 'Tahuania',
    province: 'Atalaya',
    department: 'Ucayali',
  },
  250303: {
    code: '250303',
    district: 'Yurua',
    province: 'Atalaya',
    department: 'Ucayali',
  },
  250304: {
    code: '250304',
    district: 'Sepahua',
    province: 'Atalaya',
    department: 'Ucayali',
  },
  250401: {
    code: '250401',
    district: 'Purus',
    province: 'Purus',
    department: 'Ucayali',
  },
  '010101': {
    code: '010101',
    district: 'Chachapoyas',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010102': {
    code: '010102',
    district: 'Asuncion',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010103': {
    code: '010103',
    district: 'Balsas',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010104': {
    code: '010104',
    district: 'Cheto',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010105': {
    code: '010105',
    district: 'Chiliquin',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010106': {
    code: '010106',
    district: 'Chuquibamba',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010107': {
    code: '010107',
    district: 'Granada',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010108': {
    code: '010108',
    district: 'Huancas',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010109': {
    code: '010109',
    district: 'La Jalca',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010110': {
    code: '010110',
    district: 'Leimebamba',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010111': {
    code: '010111',
    district: 'Levanto',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010112': {
    code: '010112',
    district: 'Magdalena',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010113': {
    code: '010113',
    district: 'Mariscal Castilla',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010114': {
    code: '010114',
    district: 'Molinopampa',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010115': {
    code: '010115',
    district: 'Montevideo',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010116': {
    code: '010116',
    district: 'Olleros',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010117': {
    code: '010117',
    district: 'Quinjalca',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010118': {
    code: '010118',
    district: 'San Francisco de Daguas',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010119': {
    code: '010119',
    district: 'San Isidro de Maino',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010120': {
    code: '010120',
    district: 'Soloco',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010121': {
    code: '010121',
    district: 'Sonche',
    province: 'Chachapoyas',
    department: 'Amazonas',
  },
  '010205': {
    code: '010205',
    district: 'Bagua',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010202': {
    code: '010202',
    district: 'Aramango',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010203': {
    code: '010203',
    district: 'Copallin',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010204': {
    code: '010204',
    district: 'El Parco',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010206': {
    code: '010206',
    district: 'Imaza',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010201': {
    code: '010201',
    district: 'La Peca',
    province: 'Bagua',
    department: 'Amazonas',
  },
  '010301': {
    code: '010301',
    district: 'Jumbilla',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010304': {
    code: '010304',
    district: 'Chisquilla',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010305': {
    code: '010305',
    district: 'Churuja',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010302': {
    code: '010302',
    district: 'Corosha',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010303': {
    code: '010303',
    district: 'Cuispes',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010306': {
    code: '010306',
    district: 'Florida',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010312': {
    code: '010312',
    district: 'Jazan',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010307': {
    code: '010307',
    district: 'Recta',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010308': {
    code: '010308',
    district: 'San Carlos',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010309': {
    code: '010309',
    district: 'Shipasbamba',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010310': {
    code: '010310',
    district: 'Valera',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010311': {
    code: '010311',
    district: 'Yambrasbamba',
    province: 'Bongara',
    department: 'Amazonas',
  },
  '010601': {
    code: '010601',
    district: 'Nieva',
    province: 'Condorcanqui',
    department: 'Amazonas',
  },
  '010603': {
    code: '010603',
    district: 'El Cenepa',
    province: 'Condorcanqui',
    department: 'Amazonas',
  },
  '010602': {
    code: '010602',
    district: 'Rio Santiago',
    province: 'Condorcanqui',
    department: 'Amazonas',
  },
  '010401': {
    code: '010401',
    district: 'Lamud',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010402': {
    code: '010402',
    district: 'Camporredondo',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010403': {
    code: '010403',
    district: 'Cocabamba',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010404': {
    code: '010404',
    district: 'Colcamar',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010405': {
    code: '010405',
    district: 'Conila',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010406': {
    code: '010406',
    district: 'Inguilpata',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010407': {
    code: '010407',
    district: 'Longuita',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010408': {
    code: '010408',
    district: 'Lonya Chico',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010409': {
    code: '010409',
    district: 'Luya',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010410': {
    code: '010410',
    district: 'Luya Viejo',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010411': {
    code: '010411',
    district: 'Maria',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010412': {
    code: '010412',
    district: 'Ocalli',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010413': {
    code: '010413',
    district: 'Ocumal',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010414': {
    code: '010414',
    district: 'Pisuquia',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010423': {
    code: '010423',
    district: 'Providencia',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010415': {
    code: '010415',
    district: 'San Cristobal',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010416': {
    code: '010416',
    district: 'San Francisco del Yeso',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010417': {
    code: '010417',
    district: 'San Jeronimo',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010418': {
    code: '010418',
    district: 'San Juan de Lopecancha',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010419': {
    code: '010419',
    district: 'Santa Catalina',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010420': {
    code: '010420',
    district: 'Santo Tomas',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010421': {
    code: '010421',
    district: 'Tingo',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010422': {
    code: '010422',
    district: 'Trita',
    province: 'Luya',
    department: 'Amazonas',
  },
  '010501': {
    code: '010501',
    district: 'San Nicolas',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010503': {
    code: '010503',
    district: 'Chirimoto',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010502': {
    code: '010502',
    district: 'Cochamal',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010504': {
    code: '010504',
    district: 'Huambo',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010505': {
    code: '010505',
    district: 'Limabamba',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010506': {
    code: '010506',
    district: 'Longar',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010508': {
    code: '010508',
    district: 'Mariscal Benavides',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010507': {
    code: '010507',
    district: 'Milpuc',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010509': {
    code: '010509',
    district: 'Omia',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010510': {
    code: '010510',
    district: 'Santa Rosa',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010511': {
    code: '010511',
    district: 'Totora',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010512': {
    code: '010512',
    district: 'Vista Alegre',
    province: 'Rodriguez de Mendoza',
    department: 'Amazonas',
  },
  '010701': {
    code: '010701',
    district: 'Bagua Grande',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010702': {
    code: '010702',
    district: 'Cajaruro',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010703': {
    code: '010703',
    district: 'Cumba',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010704': {
    code: '010704',
    district: 'El Milagro',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010705': {
    code: '010705',
    district: 'Jamalca',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010706': {
    code: '010706',
    district: 'Lonya Grande',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '010707': {
    code: '010707',
    district: 'Yamon',
    province: 'Utcubamba',
    department: 'Amazonas',
  },
  '020101': {
    code: '020101',
    district: 'Huaraz',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020103': {
    code: '020103',
    district: 'Cochabamba',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020104': {
    code: '020104',
    district: 'Colcabamba',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020105': {
    code: '020105',
    district: 'Huanchay',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020102': {
    code: '020102',
    district: 'Independencia',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020106': {
    code: '020106',
    district: 'Jangas',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020107': {
    code: '020107',
    district: 'La Libertad',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020108': {
    code: '020108',
    district: 'Olleros',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020109': {
    code: '020109',
    district: 'Pampas',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020110': {
    code: '020110',
    district: 'Pariacoto',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020111': {
    code: '020111',
    district: 'Pira',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020112': {
    code: '020112',
    district: 'Tarica',
    province: 'Huaraz',
    department: 'Ancash',
  },
  '020201': {
    code: '020201',
    district: 'Aija',
    province: 'Aija',
    department: 'Ancash',
  },
  '020203': {
    code: '020203',
    district: 'Coris',
    province: 'Aija',
    department: 'Ancash',
  },
  '020205': {
    code: '020205',
    district: 'Huacllan',
    province: 'Aija',
    department: 'Ancash',
  },
  '020206': {
    code: '020206',
    district: 'La Merced',
    province: 'Aija',
    department: 'Ancash',
  },
  '020208': {
    code: '020208',
    district: 'Succha',
    province: 'Aija',
    department: 'Ancash',
  },
  '021601': {
    code: '021601',
    district: 'Llamellin',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021602': {
    code: '021602',
    district: 'Aczo',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021603': {
    code: '021603',
    district: 'Chaccho',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021604': {
    code: '021604',
    district: 'Chingas',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021605': {
    code: '021605',
    district: 'Mirgas',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021606': {
    code: '021606',
    district: 'San Juan de Rontoy',
    province: 'Antonio Raymondi',
    department: 'Ancash',
  },
  '021801': {
    code: '021801',
    district: 'Chacas',
    province: 'Asuncion',
    department: 'Ancash',
  },
  '021802': {
    code: '021802',
    district: 'Acochaca',
    province: 'Asuncion',
    department: 'Ancash',
  },
  '020301': {
    code: '020301',
    district: 'Chiquian',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020302': {
    code: '020302',
    district: 'Abelardo Pardo Lezameta',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020321': {
    code: '020321',
    district: 'Antonio Raymondi',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020304': {
    code: '020304',
    district: 'Aquia',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020305': {
    code: '020305',
    district: 'Cajacay',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020322': {
    code: '020322',
    district: 'Canis',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020323': {
    code: '020323',
    district: 'Colquioc',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020325': {
    code: '020325',
    district: 'Huallanca',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020311': {
    code: '020311',
    district: 'Huasta',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020310': {
    code: '020310',
    district: 'Huayllacayan',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020324': {
    code: '020324',
    district: 'La Primavera',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020313': {
    code: '020313',
    district: 'Mangas',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020315': {
    code: '020315',
    district: 'Pacllon',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020317': {
    code: '020317',
    district: 'San Miguel de Corpanqui',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020320': {
    code: '020320',
    district: 'Ticllos',
    province: 'Bolognesi',
    department: 'Ancash',
  },
  '020401': {
    code: '020401',
    district: 'Carhuaz',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020402': {
    code: '020402',
    district: 'Acopampa',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020403': {
    code: '020403',
    district: 'Amashca',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020404': {
    code: '020404',
    district: 'Anta',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020405': {
    code: '020405',
    district: 'Ataquero',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020406': {
    code: '020406',
    district: 'Marcara',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020407': {
    code: '020407',
    district: 'Pariahuanca',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020408': {
    code: '020408',
    district: 'San Miguel de Aco',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020409': {
    code: '020409',
    district: 'Shilla',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020410': {
    code: '020410',
    district: 'Tinco',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '020411': {
    code: '020411',
    district: 'Yungar',
    province: 'Carhuaz',
    department: 'Ancash',
  },
  '021701': {
    code: '021701',
    district: 'San Luis',
    province: 'Carlos Fermin Fitzca',
    department: 'Ancash',
  },
  '021703': {
    code: '021703',
    district: 'San Nicolas',
    province: 'Carlos Fermin Fitzca',
    department: 'Ancash',
  },
  '021702': {
    code: '021702',
    district: 'Yauya',
    province: 'Carlos Fermin Fitzca',
    department: 'Ancash',
  },
  '020501': {
    code: '020501',
    district: 'Casma',
    province: 'Casma',
    department: 'Ancash',
  },
  '020502': {
    code: '020502',
    district: 'Buena Vista Alta',
    province: 'Casma',
    department: 'Ancash',
  },
  '020503': {
    code: '020503',
    district: 'Comandante Noel',
    province: 'Casma',
    department: 'Ancash',
  },
  '020505': {
    code: '020505',
    district: 'Yautan',
    province: 'Casma',
    department: 'Ancash',
  },
  '020601': {
    code: '020601',
    district: 'Corongo',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020602': {
    code: '020602',
    district: 'Aco',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020603': {
    code: '020603',
    district: 'Bambas',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020604': {
    code: '020604',
    district: 'Cusca',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020605': {
    code: '020605',
    district: 'La Pampa',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020606': {
    code: '020606',
    district: 'Yanac',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020607': {
    code: '020607',
    district: 'Yupan',
    province: 'Corongo',
    department: 'Ancash',
  },
  '020801': {
    code: '020801',
    district: 'Huari',
    province: 'Huari',
    department: 'Ancash',
  },
  '020816': {
    code: '020816',
    district: 'Anra',
    province: 'Huari',
    department: 'Ancash',
  },
  '020802': {
    code: '020802',
    district: 'Cajay',
    province: 'Huari',
    department: 'Ancash',
  },
  '020803': {
    code: '020803',
    district: 'Chavin de Huantar',
    province: 'Huari',
    department: 'Ancash',
  },
  '020804': {
    code: '020804',
    district: 'Huacachi',
    province: 'Huari',
    department: 'Ancash',
  },
  '020806': {
    code: '020806',
    district: 'Huacchis',
    province: 'Huari',
    department: 'Ancash',
  },
  '020805': {
    code: '020805',
    district: 'Huachis',
    province: 'Huari',
    department: 'Ancash',
  },
  '020807': {
    code: '020807',
    district: 'Huantar',
    province: 'Huari',
    department: 'Ancash',
  },
  '020808': {
    code: '020808',
    district: 'Masin',
    province: 'Huari',
    department: 'Ancash',
  },
  '020809': {
    code: '020809',
    district: 'Paucas',
    province: 'Huari',
    department: 'Ancash',
  },
  '020810': {
    code: '020810',
    district: 'Ponto',
    province: 'Huari',
    department: 'Ancash',
  },
  '020811': {
    code: '020811',
    district: 'Rahuapampa',
    province: 'Huari',
    department: 'Ancash',
  },
  '020812': {
    code: '020812',
    district: 'Rapayan',
    province: 'Huari',
    department: 'Ancash',
  },
  '020813': {
    code: '020813',
    district: 'San Marcos',
    province: 'Huari',
    department: 'Ancash',
  },
  '020814': {
    code: '020814',
    district: 'San Pedro de Chana',
    province: 'Huari',
    department: 'Ancash',
  },
  '020815': {
    code: '020815',
    district: 'Uco',
    province: 'Huari',
    department: 'Ancash',
  },
  '021901': {
    code: '021901',
    district: 'Huarmey',
    province: 'Huarmey',
    department: 'Ancash',
  },
  '021902': {
    code: '021902',
    district: 'Cochapeti',
    province: 'Huarmey',
    department: 'Ancash',
  },
  '021905': {
    code: '021905',
    district: 'Culebras',
    province: 'Huarmey',
    department: 'Ancash',
  },
  '021903': {
    code: '021903',
    district: 'Huayan',
    province: 'Huarmey',
    department: 'Ancash',
  },
  '021904': {
    code: '021904',
    district: 'Malvas',
    province: 'Huarmey',
    department: 'Ancash',
  },
  '020701': {
    code: '020701',
    district: 'Caraz',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020702': {
    code: '020702',
    district: 'Huallanca',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020703': {
    code: '020703',
    district: 'Huata',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020704': {
    code: '020704',
    district: 'Huaylas',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020705': {
    code: '020705',
    district: 'Mato',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020706': {
    code: '020706',
    district: 'Pamparomas',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020707': {
    code: '020707',
    district: 'Pueblo Libre',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020708': {
    code: '020708',
    district: 'Santa Cruz',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020710': {
    code: '020710',
    district: 'Santo Toribio',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020709': {
    code: '020709',
    district: 'Yuracmarca',
    province: 'Huaylas',
    department: 'Ancash',
  },
  '020901': {
    code: '020901',
    district: 'Piscobamba',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020902': {
    code: '020902',
    district: 'Casca',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020908': {
    code: '020908',
    district: 'Eleazar Guzman Barron',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020904': {
    code: '020904',
    district: 'Fidel Olivas Escudero',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020905': {
    code: '020905',
    district: 'Llama',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020906': {
    code: '020906',
    district: 'Llumpa',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020903': {
    code: '020903',
    district: 'Lucma',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '020907': {
    code: '020907',
    district: 'Musga',
    province: 'Mariscal Luzuriaga',
    department: 'Ancash',
  },
  '022007': {
    code: '022007',
    district: 'Ocros',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022001': {
    code: '022001',
    district: 'Acas',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022002': {
    code: '022002',
    district: 'Cajamarquilla',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022003': {
    code: '022003',
    district: 'Carhuapampa',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022004': {
    code: '022004',
    district: 'Cochas',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022005': {
    code: '022005',
    district: 'Congas',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022006': {
    code: '022006',
    district: 'Llipa',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022008': {
    code: '022008',
    district: 'San Cristobal de Rajan',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022009': {
    code: '022009',
    district: 'San Pedro',
    province: 'Ocros',
    department: 'Ancash',
  },
  '022010': {
    code: '022010',
    district: 'Santiago de Chilcas',
    province: 'Ocros',
    department: 'Ancash',
  },
  '021001': {
    code: '021001',
    district: 'Cabana',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021002': {
    code: '021002',
    district: 'Bolognesi',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021003': {
    code: '021003',
    district: 'Conchucos',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021004': {
    code: '021004',
    district: 'Huacaschuque',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021005': {
    code: '021005',
    district: 'Huandoval',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021006': {
    code: '021006',
    district: 'Lacabamba',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021007': {
    code: '021007',
    district: 'Llapo',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021008': {
    code: '021008',
    district: 'Pallasca',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021009': {
    code: '021009',
    district: 'Pampas',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021010': {
    code: '021010',
    district: 'Santa Rosa',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021011': {
    code: '021011',
    district: 'Tauca',
    province: 'Pallasca',
    department: 'Ancash',
  },
  '021101': {
    code: '021101',
    district: 'Pomabamba',
    province: 'Pomabamba',
    department: 'Ancash',
  },
  '021102': {
    code: '021102',
    district: 'Huayllan',
    province: 'Pomabamba',
    department: 'Ancash',
  },
  '021103': {
    code: '021103',
    district: 'Parobamba',
    province: 'Pomabamba',
    department: 'Ancash',
  },
  '021104': {
    code: '021104',
    district: 'Quinuabamba',
    province: 'Pomabamba',
    department: 'Ancash',
  },
  '021201': {
    code: '021201',
    district: 'Recuay',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021210': {
    code: '021210',
    district: 'Catac',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021202': {
    code: '021202',
    district: 'Cotaparaco',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021203': {
    code: '021203',
    district: 'Huayllapampa',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021209': {
    code: '021209',
    district: 'Llacllin',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021204': {
    code: '021204',
    district: 'Marca',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021205': {
    code: '021205',
    district: 'Pampas Chico',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021206': {
    code: '021206',
    district: 'Pararin',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021207': {
    code: '021207',
    district: 'Tapacocha',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021208': {
    code: '021208',
    district: 'Ticapampa',
    province: 'Recuay',
    department: 'Ancash',
  },
  '021301': {
    code: '021301',
    district: 'Chimbote',
    province: 'Santa',
    department: 'Ancash',
  },
  '021302': {
    code: '021302',
    district: 'Caceres del Peru',
    province: 'Santa',
    department: 'Ancash',
  },
  '021308': {
    code: '021308',
    district: 'Coishco',
    province: 'Santa',
    department: 'Ancash',
  },
  '021303': {
    code: '021303',
    district: 'Macate',
    province: 'Santa',
    department: 'Ancash',
  },
  '021304': {
    code: '021304',
    district: 'Moro',
    province: 'Santa',
    department: 'Ancash',
  },
  '021305': {
    code: '021305',
    district: 'Nepeña',
    province: 'Santa',
    department: 'Ancash',
  },
  '021306': {
    code: '021306',
    district: 'Samanco',
    province: 'Santa',
    department: 'Ancash',
  },
  '021307': {
    code: '021307',
    district: 'Santa',
    province: 'Santa',
    department: 'Ancash',
  },
  '021309': {
    code: '021309',
    district: 'Nuevo Chimbote',
    province: 'Santa',
    department: 'Ancash',
  },
  '021401': {
    code: '021401',
    district: 'Sihuas',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021407': {
    code: '021407',
    district: 'Acobamba',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021402': {
    code: '021402',
    district: 'Alfonso Ugarte',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021408': {
    code: '021408',
    district: 'Cashapampa',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021403': {
    code: '021403',
    district: 'Chingalpo',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021404': {
    code: '021404',
    district: 'Huayllabamba',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021405': {
    code: '021405',
    district: 'Quiches',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021409': {
    code: '021409',
    district: 'Ragash',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021410': {
    code: '021410',
    district: 'San Juan',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021406': {
    code: '021406',
    district: 'Sicsibamba',
    province: 'Sihuas',
    department: 'Ancash',
  },
  '021501': {
    code: '021501',
    district: 'Yungay',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021502': {
    code: '021502',
    district: 'Cascapara',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021503': {
    code: '021503',
    district: 'Mancos',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021504': {
    code: '021504',
    district: 'Matacoto',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021505': {
    code: '021505',
    district: 'Quillo',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021506': {
    code: '021506',
    district: 'Ranrahirca',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021507': {
    code: '021507',
    district: 'Shupluy',
    province: 'Yungay',
    department: 'Ancash',
  },
  '021508': {
    code: '021508',
    district: 'Yanama',
    province: 'Yungay',
    department: 'Ancash',
  },
  '030101': {
    code: '030101',
    district: 'Abancay',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030104': {
    code: '030104',
    district: 'Chacoche',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030102': {
    code: '030102',
    district: 'Circa',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030103': {
    code: '030103',
    district: 'Curahuasi',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030105': {
    code: '030105',
    district: 'Huanipaca',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030106': {
    code: '030106',
    district: 'Lambrama',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030107': {
    code: '030107',
    district: 'Pichirhua',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030108': {
    code: '030108',
    district: 'San Pedro de Cachora',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030109': {
    code: '030109',
    district: 'Tamburco',
    province: 'Abancay',
    department: 'Apurimac',
  },
  '030301': {
    code: '030301',
    district: 'Andahuaylas',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030302': {
    code: '030302',
    district: 'Andarapa',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030303': {
    code: '030303',
    district: 'Chiara',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030304': {
    code: '030304',
    district: 'Huancarama',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030305': {
    code: '030305',
    district: 'Huancaray',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030317': {
    code: '030317',
    district: 'Huayana',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030306': {
    code: '030306',
    district: 'Kishuara',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030307': {
    code: '030307',
    district: 'Pacobamba',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030313': {
    code: '030313',
    district: 'Pacucha',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030308': {
    code: '030308',
    district: 'Pampachiri',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030314': {
    code: '030314',
    district: 'Pomacocha',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030309': {
    code: '030309',
    district: 'San Antonio de Cachi',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030310': {
    code: '030310',
    district: 'San Jeronimo',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030318': {
    code: '030318',
    district: 'San Miguel de Chaccrampa',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030315': {
    code: '030315',
    district: 'Santa Maria de Chicmo',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030311': {
    code: '030311',
    district: 'Talavera',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030316': {
    code: '030316',
    district: 'Tumay Huaraca',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030312': {
    code: '030312',
    district: 'Turpo',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030319': {
    code: '030319',
    district: 'Kaquiabamba',
    province: 'Andahuaylas',
    department: 'Apurimac',
  },
  '030401': {
    code: '030401',
    district: 'Antabamba',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030402': {
    code: '030402',
    district: 'El Oro',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030403': {
    code: '030403',
    district: 'Huaquirca',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030404': {
    code: '030404',
    district: 'Juan Espinoza Medrano',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030405': {
    code: '030405',
    district: 'Oropesa',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030406': {
    code: '030406',
    district: 'Pachaconas',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030407': {
    code: '030407',
    district: 'Sabaino',
    province: 'Antabamba',
    department: 'Apurimac',
  },
  '030201': {
    code: '030201',
    district: 'Chalhuanca',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030202': {
    code: '030202',
    district: 'Capaya',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030203': {
    code: '030203',
    district: 'Caraybamba',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030206': {
    code: '030206',
    district: 'Chapimarca',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030204': {
    code: '030204',
    district: 'Colcabamba',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030205': {
    code: '030205',
    district: 'Cotaruse',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030207': {
    code: '030207',
    district: 'Huayllo',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030217': {
    code: '030217',
    district: 'Justo Apu Sahuaraura',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030208': {
    code: '030208',
    district: 'Lucre',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030209': {
    code: '030209',
    district: 'Pocohuanca',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030216': {
    code: '030216',
    district: 'San Juan de Chacña',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030210': {
    code: '030210',
    district: 'Sañayca',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030211': {
    code: '030211',
    district: 'Soraya',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030212': {
    code: '030212',
    district: 'Tapairihua',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030213': {
    code: '030213',
    district: 'Tintay',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030214': {
    code: '030214',
    district: 'Toraya',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030215': {
    code: '030215',
    district: 'Yanaca',
    province: 'Aymaraes',
    department: 'Apurimac',
  },
  '030501': {
    code: '030501',
    district: 'Tambobamba',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030503': {
    code: '030503',
    district: 'Cotabambas',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030502': {
    code: '030502',
    district: 'Coyllurqui',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030504': {
    code: '030504',
    district: 'Haquira',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030505': {
    code: '030505',
    district: 'Mara',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030506': {
    code: '030506',
    district: 'Challhuahuacho',
    province: 'Cotabambas',
    department: 'Apurimac',
  },
  '030701': {
    code: '030701',
    district: 'Chincheros',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030705': {
    code: '030705',
    district: 'Anco_Huallo',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030704': {
    code: '030704',
    district: 'Cocharcas',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030706': {
    code: '030706',
    district: 'Huaccana',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030703': {
    code: '030703',
    district: 'Ocobamba',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030702': {
    code: '030702',
    district: 'Ongoy',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030707': {
    code: '030707',
    district: 'Uranmarca',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030708': {
    code: '030708',
    district: 'Ranracancha',
    province: 'Chincheros',
    department: 'Apurimac',
  },
  '030601': {
    code: '030601',
    district: 'Chuquibambilla',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030602': {
    code: '030602',
    district: 'Curpahuasi',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030605': {
    code: '030605',
    district: 'Gamarra',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030603': {
    code: '030603',
    district: 'Huayllati',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030604': {
    code: '030604',
    district: 'Mamara',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030606': {
    code: '030606',
    district: 'Micaela Bastidas',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030608': {
    code: '030608',
    district: 'Pataypampa',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030607': {
    code: '030607',
    district: 'Progreso',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030609': {
    code: '030609',
    district: 'San Antonio',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030613': {
    code: '030613',
    district: 'Santa Rosa',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030610': {
    code: '030610',
    district: 'Turpay',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030611': {
    code: '030611',
    district: 'Vilcabamba',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030612': {
    code: '030612',
    district: 'Virundo',
    province: 'Grau',
    department: 'Apurimac',
  },
  '030614': {
    code: '030614',
    district: 'Curasco',
    province: 'Grau',
    department: 'Apurimac',
  },
  '040101': {
    code: '040101',
    district: 'Arequipa',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040128': {
    code: '040128',
    district: 'Alto Selva Alegre',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040102': {
    code: '040102',
    district: 'Cayma',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040103': {
    code: '040103',
    district: 'Cerro Colorado',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040104': {
    code: '040104',
    district: 'Characato',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040105': {
    code: '040105',
    district: 'Chiguata',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040127': {
    code: '040127',
    district: 'Jacobo Hunter',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040106': {
    code: '040106',
    district: 'La Joya',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040126': {
    code: '040126',
    district: 'Mariano Melgar',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040107': {
    code: '040107',
    district: 'Miraflores',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040108': {
    code: '040108',
    district: 'Mollebaya',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040109': {
    code: '040109',
    district: 'Paucarpata',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040110': {
    code: '040110',
    district: 'Pocsi',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040111': {
    code: '040111',
    district: 'Polobaya',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040112': {
    code: '040112',
    district: 'Quequeña',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040113': {
    code: '040113',
    district: 'Sabandia',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040114': {
    code: '040114',
    district: 'Sachaca',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040115': {
    code: '040115',
    district: 'San Juan de Siguas',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040116': {
    code: '040116',
    district: 'San Juan de Tarucani',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040117': {
    code: '040117',
    district: 'Santa Isabel de Siguas',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040118': {
    code: '040118',
    district: 'Santa Rita de Siguas',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040119': {
    code: '040119',
    district: 'Socabaya',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040120': {
    code: '040120',
    district: 'Tiabaya',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040121': {
    code: '040121',
    district: 'Uchumayo',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040122': {
    code: '040122',
    district: 'Vitor',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040123': {
    code: '040123',
    district: 'Yanahuara',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040124': {
    code: '040124',
    district: 'Yarabamba',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040125': {
    code: '040125',
    district: 'Yura',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040129': {
    code: '040129',
    district: 'Jose Luis Bustamante y Rivero',
    province: 'Arequipa',
    department: 'Arequipa',
  },
  '040301': {
    code: '040301',
    district: 'Camana',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040302': {
    code: '040302',
    district: 'Jose Maria Quimper',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040303': {
    code: '040303',
    district: 'Mariano Nicolas Valcarcel',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040304': {
    code: '040304',
    district: 'Mariscal Caceres',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040305': {
    code: '040305',
    district: 'Nicolas de Pierola',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040306': {
    code: '040306',
    district: 'Ocoña',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040307': {
    code: '040307',
    district: 'Quilca',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040308': {
    code: '040308',
    district: 'Samuel Pastor',
    province: 'Camana',
    department: 'Arequipa',
  },
  '040401': {
    code: '040401',
    district: 'Caraveli',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040402': {
    code: '040402',
    district: 'Acari',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040403': {
    code: '040403',
    district: 'Atico',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040404': {
    code: '040404',
    district: 'Atiquipa',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040405': {
    code: '040405',
    district: 'Bella Union',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040406': {
    code: '040406',
    district: 'Cahuacho',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040407': {
    code: '040407',
    district: 'Chala',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040408': {
    code: '040408',
    district: 'Chaparra',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040409': {
    code: '040409',
    district: 'Huanuhuanu',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040410': {
    code: '040410',
    district: 'Jaqui',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040411': {
    code: '040411',
    district: 'Lomas',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040412': {
    code: '040412',
    district: 'Quicacha',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040413': {
    code: '040413',
    district: 'Yauca',
    province: 'Caraveli',
    department: 'Arequipa',
  },
  '040501': {
    code: '040501',
    district: 'Aplao',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040502': {
    code: '040502',
    district: 'Andagua',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040503': {
    code: '040503',
    district: 'Ayo',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040504': {
    code: '040504',
    district: 'Chachas',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040505': {
    code: '040505',
    district: 'Chilcaymarca',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040506': {
    code: '040506',
    district: 'Choco',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040507': {
    code: '040507',
    district: 'Huancarqui',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040508': {
    code: '040508',
    district: 'Machaguay',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040509': {
    code: '040509',
    district: 'Orcopampa',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040510': {
    code: '040510',
    district: 'Pampacolca',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040511': {
    code: '040511',
    district: 'Tipan',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040513': {
    code: '040513',
    district: 'Uñon',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040512': {
    code: '040512',
    district: 'Uraca',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040514': {
    code: '040514',
    district: 'Viraco',
    province: 'Castilla',
    department: 'Arequipa',
  },
  '040201': {
    code: '040201',
    district: 'Chivay',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040202': {
    code: '040202',
    district: 'Achoma',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040203': {
    code: '040203',
    district: 'Cabanaconde',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040205': {
    code: '040205',
    district: 'Callalli',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040204': {
    code: '040204',
    district: 'Caylloma',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040206': {
    code: '040206',
    district: 'Coporaque',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040207': {
    code: '040207',
    district: 'Huambo',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040208': {
    code: '040208',
    district: 'Huanca',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040209': {
    code: '040209',
    district: 'Ichupampa',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040210': {
    code: '040210',
    district: 'Lari',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040211': {
    code: '040211',
    district: 'Lluta',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040212': {
    code: '040212',
    district: 'Maca',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040213': {
    code: '040213',
    district: 'Madrigal',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040214': {
    code: '040214',
    district: 'San Antonio de Chuca',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040215': {
    code: '040215',
    district: 'Sibayo',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040216': {
    code: '040216',
    district: 'Tapay',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040217': {
    code: '040217',
    district: 'Tisco',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040218': {
    code: '040218',
    district: 'Tuti',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040219': {
    code: '040219',
    district: 'Yanque',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040220': {
    code: '040220',
    district: 'Majes',
    province: 'Caylloma',
    department: 'Arequipa',
  },
  '040601': {
    code: '040601',
    district: 'Chuquibamba',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040602': {
    code: '040602',
    district: 'Andaray',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040603': {
    code: '040603',
    district: 'Cayarani',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040604': {
    code: '040604',
    district: 'Chichas',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040605': {
    code: '040605',
    district: 'Iray',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040608': {
    code: '040608',
    district: 'Rio Grande',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040606': {
    code: '040606',
    district: 'Salamanca',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040607': {
    code: '040607',
    district: 'Yanaquihua',
    province: 'Condesuyos',
    department: 'Arequipa',
  },
  '040701': {
    code: '040701',
    district: 'Mollendo',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040702': {
    code: '040702',
    district: 'Cocachacra',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040703': {
    code: '040703',
    district: 'Dean Valdivia',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040704': {
    code: '040704',
    district: 'Islay',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040705': {
    code: '040705',
    district: 'Mejia',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040706': {
    code: '040706',
    district: 'Punta de Bombon',
    province: 'Islay',
    department: 'Arequipa',
  },
  '040801': {
    code: '040801',
    district: 'Cotahuasi',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040802': {
    code: '040802',
    district: 'Alca',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040803': {
    code: '040803',
    district: 'Charcana',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040804': {
    code: '040804',
    district: 'Huaynacotas',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040805': {
    code: '040805',
    district: 'Pampamarca',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040806': {
    code: '040806',
    district: 'Puyca',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040807': {
    code: '040807',
    district: 'Quechualla',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040808': {
    code: '040808',
    district: 'Sayla',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040809': {
    code: '040809',
    district: 'Tauria',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040810': {
    code: '040810',
    district: 'Tomepampa',
    province: 'La Union',
    department: 'Arequipa',
  },
  '040811': {
    code: '040811',
    district: 'Toro',
    province: 'La Union',
    department: 'Arequipa',
  },
  '050101': {
    code: '050101',
    district: 'Ayacucho',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050111': {
    code: '050111',
    district: 'Acocro',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050102': {
    code: '050102',
    district: 'Acos Vinchos',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050103': {
    code: '050103',
    district: 'Carmen Alto',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050104': {
    code: '050104',
    district: 'Chiara',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050113': {
    code: '050113',
    district: 'Ocros',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050114': {
    code: '050114',
    district: 'Pacaycasa',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050105': {
    code: '050105',
    district: 'Quinua',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050106': {
    code: '050106',
    district: 'San Jose de Ticllas',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050107': {
    code: '050107',
    district: 'San Juan Bautista',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050108': {
    code: '050108',
    district: 'Santiago de Pischa',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050112': {
    code: '050112',
    district: 'Socos',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050110': {
    code: '050110',
    district: 'Tambillo',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050109': {
    code: '050109',
    district: 'Vinchos',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050115': {
    code: '050115',
    district: 'Jesus Nazareno',
    province: 'Huamanga',
    department: 'Ayacucho',
  },
  '050201': {
    code: '050201',
    district: 'Cangallo',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050204': {
    code: '050204',
    district: 'Chuschi',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050206': {
    code: '050206',
    district: 'Los Morochucos',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050211': {
    code: '050211',
    district: 'Maria Parado de Bellido',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050207': {
    code: '050207',
    district: 'Paras',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050208': {
    code: '050208',
    district: 'Totos',
    province: 'Cangallo',
    department: 'Ayacucho',
  },
  '050801': {
    code: '050801',
    district: 'Sancos',
    province: 'Huanca Sancos',
    department: 'Ayacucho',
  },
  '050804': {
    code: '050804',
    district: 'Carapo',
    province: 'Huanca Sancos',
    department: 'Ayacucho',
  },
  '050802': {
    code: '050802',
    district: 'Sacsamarca',
    province: 'Huanca Sancos',
    department: 'Ayacucho',
  },
  '050803': {
    code: '050803',
    district: 'Santiago de Lucanamarca',
    province: 'Huanca Sancos',
    department: 'Ayacucho',
  },
  '050301': {
    code: '050301',
    district: 'Huanta',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050302': {
    code: '050302',
    district: 'Ayahuanco',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050303': {
    code: '050303',
    district: 'Huamanguilla',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050304': {
    code: '050304',
    district: 'Iguain',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050305': {
    code: '050305',
    district: 'Luricocha',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050307': {
    code: '050307',
    district: 'Santillana',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050308': {
    code: '050308',
    district: 'Sivia',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050309': {
    code: '050309',
    district: 'Llochegua',
    province: 'Huanta',
    department: 'Ayacucho',
  },
  '050401': {
    code: '050401',
    district: 'San Miguel',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050402': {
    code: '050402',
    district: 'Anco',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050403': {
    code: '050403',
    district: 'Ayna',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050404': {
    code: '050404',
    district: 'Chilcas',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050405': {
    code: '050405',
    district: 'Chungui',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050407': {
    code: '050407',
    district: 'Luis Carranza',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050408': {
    code: '050408',
    district: 'Santa Rosa',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050406': {
    code: '050406',
    district: 'Tambo',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050409': {
    code: '050409',
    district: 'Samugari',
    province: 'La Mar',
    department: 'Ayacucho',
  },
  '050501': {
    code: '050501',
    district: 'Puquio',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050502': {
    code: '050502',
    district: 'Aucara',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050503': {
    code: '050503',
    district: 'Cabana',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050504': {
    code: '050504',
    district: 'Carmen Salcedo',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050506': {
    code: '050506',
    district: 'Chaviña',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050508': {
    code: '050508',
    district: 'Chipao',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050510': {
    code: '050510',
    district: 'Huac-Huas',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050511': {
    code: '050511',
    district: 'Laramate',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050512': {
    code: '050512',
    district: 'Leoncio Prado',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050514': {
    code: '050514',
    district: 'Llauta',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050513': {
    code: '050513',
    district: 'Lucanas',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050516': {
    code: '050516',
    district: 'Ocaña',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050517': {
    code: '050517',
    district: 'Otoca',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050529': {
    code: '050529',
    district: 'Saisa',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050532': {
    code: '050532',
    district: 'San Cristobal',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050521': {
    code: '050521',
    district: 'San Juan',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050522': {
    code: '050522',
    district: 'San Pedro',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050531': {
    code: '050531',
    district: 'San Pedro de Palco',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050520': {
    code: '050520',
    district: 'Sancos',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050524': {
    code: '050524',
    district: 'Santa Ana de Huaycahuacho',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050525': {
    code: '050525',
    district: 'Santa Lucia',
    province: 'Lucanas',
    department: 'Ayacucho',
  },
  '050601': {
    code: '050601',
    district: 'Coracora',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050605': {
    code: '050605',
    district: 'Chumpi',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050604': {
    code: '050604',
    district: 'Coronel Castañeda',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050608': {
    code: '050608',
    district: 'Pacapausa',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050611': {
    code: '050611',
    district: 'Pullo',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050612': {
    code: '050612',
    district: 'Puyusca',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050615': {
    code: '050615',
    district: 'San Francisco de Ravacayco',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '050616': {
    code: '050616',
    district: 'Upahuacho',
    province: 'Parinacochas',
    department: 'Ayacucho',
  },
  '051001': {
    code: '051001',
    district: 'Pausa',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051002': {
    code: '051002',
    district: 'Colta',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051003': {
    code: '051003',
    district: 'Corculla',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051004': {
    code: '051004',
    district: 'Lampa',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051005': {
    code: '051005',
    district: 'Marcabamba',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051006': {
    code: '051006',
    district: 'Oyolo',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051007': {
    code: '051007',
    district: 'Pararca',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051008': {
    code: '051008',
    district: 'San Javier de Alpabamba',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051009': {
    code: '051009',
    district: 'San Jose de Ushua',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051010': {
    code: '051010',
    district: 'Sara Sara',
    province: 'Paucar del Sara Sara',
    department: 'Ayacucho',
  },
  '051101': {
    code: '051101',
    district: 'Querobamba',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051102': {
    code: '051102',
    district: 'Belen',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051103': {
    code: '051103',
    district: 'Chalcos',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051110': {
    code: '051110',
    district: 'Chilcayoc',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051109': {
    code: '051109',
    district: 'Huacaña',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051111': {
    code: '051111',
    district: 'Morcolla',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051105': {
    code: '051105',
    district: 'Paico',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051107': {
    code: '051107',
    district: 'San Pedro de Larcay',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051104': {
    code: '051104',
    district: 'San Salvador de Quije',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051106': {
    code: '051106',
    district: 'Santiago de Paucaray',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '051108': {
    code: '051108',
    district: 'Soras',
    province: 'Sucre',
    department: 'Ayacucho',
  },
  '050701': {
    code: '050701',
    district: 'Huancapi',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050702': {
    code: '050702',
    district: 'Alcamenca',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050703': {
    code: '050703',
    district: 'Apongo',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050715': {
    code: '050715',
    district: 'Asquipata',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050704': {
    code: '050704',
    district: 'Canaria',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050706': {
    code: '050706',
    district: 'Cayara',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050707': {
    code: '050707',
    district: 'Colca',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050709': {
    code: '050709',
    district: 'Huamanquiquia',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050710': {
    code: '050710',
    district: 'Huancaraylla',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050708': {
    code: '050708',
    district: 'Huaya',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050713': {
    code: '050713',
    district: 'Sarhua',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050714': {
    code: '050714',
    district: 'Vilcanchos',
    province: 'Victor Fajardo',
    department: 'Ayacucho',
  },
  '050901': {
    code: '050901',
    district: 'Vilcas Huaman',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050903': {
    code: '050903',
    district: 'Accomarca',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050904': {
    code: '050904',
    district: 'Carhuanca',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050905': {
    code: '050905',
    district: 'Concepcion',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050906': {
    code: '050906',
    district: 'Huambalpa',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050908': {
    code: '050908',
    district: 'Independencia',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050907': {
    code: '050907',
    district: 'Saurama',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '050902': {
    code: '050902',
    district: 'Vischongo',
    province: 'Vilcas Huaman',
    department: 'Ayacucho',
  },
  '060101': {
    code: '060101',
    district: 'Cajamarca',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060102': {
    code: '060102',
    district: 'Asuncion',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060104': {
    code: '060104',
    district: 'Chetilla',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060103': {
    code: '060103',
    district: 'Cospan',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060105': {
    code: '060105',
    district: 'Encañada',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060106': {
    code: '060106',
    district: 'Jesus',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060108': {
    code: '060108',
    district: 'Llacanora',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060107': {
    code: '060107',
    district: 'Los Baños del Inca',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060109': {
    code: '060109',
    district: 'Magdalena',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060110': {
    code: '060110',
    district: 'Matara',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060111': {
    code: '060111',
    district: 'Namora',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060112': {
    code: '060112',
    district: 'San Juan',
    province: 'Cajamarca',
    department: 'Cajamarca',
  },
  '060201': {
    code: '060201',
    district: 'Cajabamba',
    province: 'Cajabamba',
    department: 'Cajamarca',
  },
  '060202': {
    code: '060202',
    district: 'Cachachi',
    province: 'Cajabamba',
    department: 'Cajamarca',
  },
  '060203': {
    code: '060203',
    district: 'Condebamba',
    province: 'Cajabamba',
    department: 'Cajamarca',
  },
  '060205': {
    code: '060205',
    district: 'Sitacocha',
    province: 'Cajabamba',
    department: 'Cajamarca',
  },
  '060301': {
    code: '060301',
    district: 'Celendin',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060303': {
    code: '060303',
    district: 'Chumuch',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060302': {
    code: '060302',
    district: 'Cortegana',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060304': {
    code: '060304',
    district: 'Huasmin',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060305': {
    code: '060305',
    district: 'Jorge Chavez',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060306': {
    code: '060306',
    district: 'Jose Galvez',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060307': {
    code: '060307',
    district: 'Miguel Iglesias',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060308': {
    code: '060308',
    district: 'Oxamarca',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060309': {
    code: '060309',
    district: 'Sorochuco',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060310': {
    code: '060310',
    district: 'Sucre',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060311': {
    code: '060311',
    district: 'Utco',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060312': {
    code: '060312',
    district: 'La Libertad de Pallan',
    province: 'Celendin',
    department: 'Cajamarca',
  },
  '060601': {
    code: '060601',
    district: 'Chota',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060602': {
    code: '060602',
    district: 'Anguia',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060605': {
    code: '060605',
    district: 'Chadin',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060606': {
    code: '060606',
    district: 'Chiguirip',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060607': {
    code: '060607',
    district: 'Chimban',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060618': {
    code: '060618',
    district: 'Choropampa',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060603': {
    code: '060603',
    district: 'Cochabamba',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060604': {
    code: '060604',
    district: 'Conchan',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060608': {
    code: '060608',
    district: 'Huambos',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060609': {
    code: '060609',
    district: 'Lajas',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060610': {
    code: '060610',
    district: 'Llama',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060611': {
    code: '060611',
    district: 'Miracosta',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060612': {
    code: '060612',
    district: 'Paccha',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060613': {
    code: '060613',
    district: 'Pion',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060614': {
    code: '060614',
    district: 'Querocoto',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060617': {
    code: '060617',
    district: 'San Juan de Licupis',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060615': {
    code: '060615',
    district: 'Tacabamba',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060616': {
    code: '060616',
    district: 'Tocmoche',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060619': {
    code: '060619',
    district: 'Chalamarca',
    province: 'Chota',
    department: 'Cajamarca',
  },
  '060401': {
    code: '060401',
    district: 'Contumaza',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060403': {
    code: '060403',
    district: 'Chilete',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060406': {
    code: '060406',
    district: 'Cupisnique',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060404': {
    code: '060404',
    district: 'Guzmango',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060405': {
    code: '060405',
    district: 'San Benito',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060409': {
    code: '060409',
    district: 'Santa Cruz de Toled',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060407': {
    code: '060407',
    district: 'Tantarica',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060408': {
    code: '060408',
    district: 'Yonan',
    province: 'Contumaza',
    department: 'Cajamarca',
  },
  '060501': {
    code: '060501',
    district: 'Cutervo',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060502': {
    code: '060502',
    district: 'Callayuc',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060504': {
    code: '060504',
    district: 'Choros',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060503': {
    code: '060503',
    district: 'Cujillo',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060505': {
    code: '060505',
    district: 'La Ramada',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060506': {
    code: '060506',
    district: 'Pimpingos',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060507': {
    code: '060507',
    district: 'Querocotillo',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060508': {
    code: '060508',
    district: 'San Andres de Cutervo',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060509': {
    code: '060509',
    district: 'San Juan de Cutervo',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060510': {
    code: '060510',
    district: 'San Luis de Lucma',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060511': {
    code: '060511',
    district: 'Santa Cruz',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060512': {
    code: '060512',
    district: 'Santo Domingo de La Capilla',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060513': {
    code: '060513',
    district: 'Santo Tomas',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060514': {
    code: '060514',
    district: 'Socota',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060515': {
    code: '060515',
    district: 'Toribio Casanova',
    province: 'Cutervo',
    department: 'Cajamarca',
  },
  '060701': {
    code: '060701',
    district: 'Bambamarca',
    province: 'Hualgayoc',
    department: 'Cajamarca',
  },
  '060702': {
    code: '060702',
    district: 'Chugur',
    province: 'Hualgayoc',
    department: 'Cajamarca',
  },
  '060703': {
    code: '060703',
    district: 'Hualgayoc',
    province: 'Hualgayoc',
    department: 'Cajamarca',
  },
  '060801': {
    code: '060801',
    district: 'Jaen',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060802': {
    code: '060802',
    district: 'Bellavista',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060804': {
    code: '060804',
    district: 'Chontali',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060803': {
    code: '060803',
    district: 'Colasay',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060812': {
    code: '060812',
    district: 'Huabal',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060811': {
    code: '060811',
    district: 'Las Pirias',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060805': {
    code: '060805',
    district: 'Pomahuaca',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060806': {
    code: '060806',
    district: 'Pucara',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060807': {
    code: '060807',
    district: 'Sallique',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060808': {
    code: '060808',
    district: 'San Felipe',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060809': {
    code: '060809',
    district: 'San Jose del Alto',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '060810': {
    code: '060810',
    district: 'Santa Rosa',
    province: 'Jaen',
    department: 'Cajamarca',
  },
  '061101': {
    code: '061101',
    district: 'San Ignacio',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061102': {
    code: '061102',
    district: 'Chirinos',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061103': {
    code: '061103',
    district: 'Huarango',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061105': {
    code: '061105',
    district: 'La Coipa',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061104': {
    code: '061104',
    district: 'Namballe',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061106': {
    code: '061106',
    district: 'San Jose de Lourdes',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061107': {
    code: '061107',
    district: 'Tabaconas',
    province: 'San Ignacio',
    department: 'Cajamarca',
  },
  '061201': {
    code: '061201',
    district: 'Pedro Galvez',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061207': {
    code: '061207',
    district: 'Chancay',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061205': {
    code: '061205',
    district: 'Eduardo Villanueva',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061203': {
    code: '061203',
    district: 'Gregorio Pita',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061202': {
    code: '061202',
    district: 'Ichocan',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061204': {
    code: '061204',
    district: 'Jose Manuel Quiroz',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061206': {
    code: '061206',
    district: 'Jose Sabogal',
    province: 'San Marcos',
    department: 'Cajamarca',
  },
  '061001': {
    code: '061001',
    district: 'San Miguel',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061013': {
    code: '061013',
    district: 'Bolivar',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061002': {
    code: '061002',
    district: 'Calquis',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061012': {
    code: '061012',
    district: 'Catilluc',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061009': {
    code: '061009',
    district: 'El Prado',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061003': {
    code: '061003',
    district: 'La Florida',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061004': {
    code: '061004',
    district: 'Llapa',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061005': {
    code: '061005',
    district: 'Nanchoc',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061006': {
    code: '061006',
    district: 'Niepos',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061007': {
    code: '061007',
    district: 'San Gregorio',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061008': {
    code: '061008',
    district: 'San Silvestre de Cochan',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061011': {
    code: '061011',
    district: 'Tongod',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061010': {
    code: '061010',
    district: 'Union Agua Blanca',
    province: 'San Miguel',
    department: 'Cajamarca',
  },
  '061301': {
    code: '061301',
    district: 'San Pablo',
    province: 'San Pablo',
    department: 'Cajamarca',
  },
  '061302': {
    code: '061302',
    district: 'San Bernardino',
    province: 'San Pablo',
    department: 'Cajamarca',
  },
  '061303': {
    code: '061303',
    district: 'San Luis',
    province: 'San Pablo',
    department: 'Cajamarca',
  },
  '061304': {
    code: '061304',
    district: 'Tumbaden',
    province: 'San Pablo',
    department: 'Cajamarca',
  },
  '060901': {
    code: '060901',
    district: 'Santa Cruz',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060910': {
    code: '060910',
    district: 'Andabamba',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060902': {
    code: '060902',
    district: 'Catache',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060903': {
    code: '060903',
    district: 'Chancaybaños',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060904': {
    code: '060904',
    district: 'La Esperanza',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060905': {
    code: '060905',
    district: 'Ninabamba',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060906': {
    code: '060906',
    district: 'Pulan',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060911': {
    code: '060911',
    district: 'Saucepampa',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060907': {
    code: '060907',
    district: 'Sexi',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060908': {
    code: '060908',
    district: 'Uticyacu',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '060909': {
    code: '060909',
    district: 'Yauyucan',
    province: 'Santa Cruz',
    department: 'Cajamarca',
  },
  '070101': {
    code: '070101',
    district: 'Cusco',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070102': {
    code: '070102',
    district: 'Ccorca',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070103': {
    code: '070103',
    district: 'Poroy',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070104': {
    code: '070104',
    district: 'San Jeronimo',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070105': {
    code: '070105',
    district: 'San Sebastian',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070106': {
    code: '070106',
    district: 'Santiago',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070107': {
    code: '070107',
    district: 'Saylla',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070108': {
    code: '070108',
    district: 'Wanchaq',
    province: 'Cusco',
    department: 'Cusco',
  },
  '070201': {
    code: '070201',
    district: 'Acomayo',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070202': {
    code: '070202',
    district: 'Acopia',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070203': {
    code: '070203',
    district: 'Acos',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070207': {
    code: '070207',
    district: 'Mosoc Llacta',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070204': {
    code: '070204',
    district: 'Pomacanchi',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070205': {
    code: '070205',
    district: 'Rondocan',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070206': {
    code: '070206',
    district: 'Sangarara',
    province: 'Acomayo',
    department: 'Cusco',
  },
  '070301': {
    code: '070301',
    district: 'Anta',
    province: 'Anta',
    department: 'Cusco',
  },
  '070309': {
    code: '070309',
    district: 'Ancahuasi',
    province: 'Anta',
    department: 'Cusco',
  },
  '070308': {
    code: '070308',
    district: 'Cachimayo',
    province: 'Anta',
    department: 'Cusco',
  },
  '070302': {
    code: '070302',
    district: 'Chinchaypujio',
    province: 'Anta',
    department: 'Cusco',
  },
  '070303': {
    code: '070303',
    district: 'Huarocondo',
    province: 'Anta',
    department: 'Cusco',
  },
  '070304': {
    code: '070304',
    district: 'Limatambo',
    province: 'Anta',
    department: 'Cusco',
  },
  '070305': {
    code: '070305',
    district: 'Mollepata',
    province: 'Anta',
    department: 'Cusco',
  },
  '070306': {
    code: '070306',
    district: 'Pucyura',
    province: 'Anta',
    department: 'Cusco',
  },
  '070307': {
    code: '070307',
    district: 'Zurite',
    province: 'Anta',
    department: 'Cusco',
  },
  '070401': {
    code: '070401',
    district: 'Calca',
    province: 'Calca',
    department: 'Cusco',
  },
  '070402': {
    code: '070402',
    district: 'Coya',
    province: 'Calca',
    department: 'Cusco',
  },
  '070403': {
    code: '070403',
    district: 'Lamay',
    province: 'Calca',
    department: 'Cusco',
  },
  '070404': {
    code: '070404',
    district: 'Lares',
    province: 'Calca',
    department: 'Cusco',
  },
  '070405': {
    code: '070405',
    district: 'Pisac',
    province: 'Calca',
    department: 'Cusco',
  },
  '070406': {
    code: '070406',
    district: 'San Salvador',
    province: 'Calca',
    department: 'Cusco',
  },
  '070407': {
    code: '070407',
    district: 'Taray',
    province: 'Calca',
    department: 'Cusco',
  },
  '070408': {
    code: '070408',
    district: 'Yanatile',
    province: 'Calca',
    department: 'Cusco',
  },
  '070501': {
    code: '070501',
    district: 'Yanaoca',
    province: 'Canas',
    department: 'Cusco',
  },
  '070502': {
    code: '070502',
    district: 'Checca',
    province: 'Canas',
    department: 'Cusco',
  },
  '070503': {
    code: '070503',
    district: 'Kunturkanki',
    province: 'Canas',
    department: 'Cusco',
  },
  '070504': {
    code: '070504',
    district: 'Langui',
    province: 'Canas',
    department: 'Cusco',
  },
  '070505': {
    code: '070505',
    district: 'Layo',
    province: 'Canas',
    department: 'Cusco',
  },
  '070506': {
    code: '070506',
    district: 'Pampamarca',
    province: 'Canas',
    department: 'Cusco',
  },
  '070507': {
    code: '070507',
    district: 'Quehue',
    province: 'Canas',
    department: 'Cusco',
  },
  '070508': {
    code: '070508',
    district: 'Tupac Amaru',
    province: 'Canas',
    department: 'Cusco',
  },
  '070601': {
    code: '070601',
    district: 'Sicuani',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070603': {
    code: '070603',
    district: 'Checacupe',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070602': {
    code: '070602',
    district: 'Combapata',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070604': {
    code: '070604',
    district: 'Marangani',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070605': {
    code: '070605',
    district: 'Pitumarca',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070606': {
    code: '070606',
    district: 'San Pablo',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070607': {
    code: '070607',
    district: 'San Pedro',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070608': {
    code: '070608',
    district: 'Tinta',
    province: 'Canchis',
    department: 'Cusco',
  },
  '070701': {
    code: '070701',
    district: 'Santo Tomas',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070702': {
    code: '070702',
    district: 'Capacmarca',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070704': {
    code: '070704',
    district: 'Chamaca',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070703': {
    code: '070703',
    district: 'Colquemarca',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070705': {
    code: '070705',
    district: 'Livitaca',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070706': {
    code: '070706',
    district: 'Llusco',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070707': {
    code: '070707',
    district: 'Quiñota',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070708': {
    code: '070708',
    district: 'Velille',
    province: 'Chumbivilcas',
    department: 'Cusco',
  },
  '070801': {
    code: '070801',
    district: 'Espinar',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070802': {
    code: '070802',
    district: 'Condoroma',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070803': {
    code: '070803',
    district: 'Coporaque',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070804': {
    code: '070804',
    district: 'Ocoruro',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070805': {
    code: '070805',
    district: 'Pallpata',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070806': {
    code: '070806',
    district: 'Pichigua',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070807': {
    code: '070807',
    district: 'Suyckutambo',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070808': {
    code: '070808',
    district: 'Alto Pichigua',
    province: 'Espinar',
    department: 'Cusco',
  },
  '070901': {
    code: '070901',
    district: 'Santa Ana',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070902': {
    code: '070902',
    district: 'Echarate',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070903': {
    code: '070903',
    district: 'Huayopata',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070904': {
    code: '070904',
    district: 'Maranura',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070905': {
    code: '070905',
    district: 'Ocobamba',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070908': {
    code: '070908',
    district: 'Quellouno',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070909': {
    code: '070909',
    district: 'Kimbiri',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070906': {
    code: '070906',
    district: 'Santa Teresa',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070907': {
    code: '070907',
    district: 'Vilcabamba',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '070910': {
    code: '070910',
    district: 'Pichari',
    province: 'La Convencion',
    department: 'Cusco',
  },
  '071001': {
    code: '071001',
    district: 'Paruro',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071002': {
    code: '071002',
    district: 'Accha',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071003': {
    code: '071003',
    district: 'Ccapi',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071004': {
    code: '071004',
    district: 'Colcha',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071005': {
    code: '071005',
    district: 'Huanoquite',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071006': {
    code: '071006',
    district: 'Omacha',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071008': {
    code: '071008',
    district: 'Paccaritambo',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071009': {
    code: '071009',
    district: 'Pillpinto',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071007': {
    code: '071007',
    district: 'Yaurisque',
    province: 'Paruro',
    department: 'Cusco',
  },
  '071101': {
    code: '071101',
    district: 'Paucartambo',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071102': {
    code: '071102',
    district: 'Caicay',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071104': {
    code: '071104',
    district: 'Challabamba',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071103': {
    code: '071103',
    district: 'Colquepata',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071106': {
    code: '071106',
    district: 'Huancarani',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071105': {
    code: '071105',
    district: 'Kosñipata',
    province: 'Paucartambo',
    department: 'Cusco',
  },
  '071201': {
    code: '071201',
    district: 'Urcos',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071202': {
    code: '071202',
    district: 'Andahuaylillas',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071203': {
    code: '071203',
    district: 'Camanti',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071204': {
    code: '071204',
    district: 'Ccarhuayo',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071205': {
    code: '071205',
    district: 'Ccatca',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071206': {
    code: '071206',
    district: 'Cusipata',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071207': {
    code: '071207',
    district: 'Huaro',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071208': {
    code: '071208',
    district: 'Lucre',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071209': {
    code: '071209',
    district: 'Marcapata',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071210': {
    code: '071210',
    district: 'Ocongate',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071211': {
    code: '071211',
    district: 'Oropesa',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071212': {
    code: '071212',
    district: 'Quiquijana',
    province: 'Quispicanchi',
    department: 'Cusco',
  },
  '071301': {
    code: '071301',
    district: 'Urubamba',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071302': {
    code: '071302',
    district: 'Chinchero',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071303': {
    code: '071303',
    district: 'Huayllabamba',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071304': {
    code: '071304',
    district: 'Machupicchu',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071305': {
    code: '071305',
    district: 'Maras',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071306': {
    code: '071306',
    district: 'Ollantaytambo',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '071307': {
    code: '071307',
    district: 'Yucay',
    province: 'Urubamba',
    department: 'Cusco',
  },
  '080101': {
    code: '080101',
    district: 'Huancavelica',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080102': {
    code: '080102',
    district: 'Acobambilla',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080103': {
    code: '080103',
    district: 'Acoria',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080104': {
    code: '080104',
    district: 'Conayca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080105': {
    code: '080105',
    district: 'Cuenca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080106': {
    code: '080106',
    district: 'Huachocolpa',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080108': {
    code: '080108',
    district: 'Huayllahuara',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080109': {
    code: '080109',
    district: 'Izcuchaca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080110': {
    code: '080110',
    district: 'Laria',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080111': {
    code: '080111',
    district: 'Manta',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080112': {
    code: '080112',
    district: 'Mariscal Caceres',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080113': {
    code: '080113',
    district: 'Moya',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080114': {
    code: '080114',
    district: 'Nuevo Occoro',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080115': {
    code: '080115',
    district: 'Palca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080116': {
    code: '080116',
    district: 'Pilchaca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080117': {
    code: '080117',
    district: 'Vilca',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080118': {
    code: '080118',
    district: 'Yauli',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080119': {
    code: '080119',
    district: 'Ascension',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080120': {
    code: '080120',
    district: 'Huando',
    province: 'Huancavelica',
    department: 'Huancavelica',
  },
  '080201': {
    code: '080201',
    district: 'Acobamba',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080203': {
    code: '080203',
    district: 'Andabamba',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080202': {
    code: '080202',
    district: 'Anta',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080204': {
    code: '080204',
    district: 'Caja',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080205': {
    code: '080205',
    district: 'Marcas',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080206': {
    code: '080206',
    district: 'Paucara',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080207': {
    code: '080207',
    district: 'Pomacocha',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080208': {
    code: '080208',
    district: 'Rosario',
    province: 'Acobamba',
    department: 'Huancavelica',
  },
  '080301': {
    code: '080301',
    district: 'Lircay',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080302': {
    code: '080302',
    district: 'Anchonga',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080303': {
    code: '080303',
    district: 'Callanmarca',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080312': {
    code: '080312',
    district: 'Ccochaccasa',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080305': {
    code: '080305',
    district: 'Chincho',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080304': {
    code: '080304',
    district: 'Congalla',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080307': {
    code: '080307',
    district: 'Huanca-Huanca',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080306': {
    code: '080306',
    district: 'Huayllay Grande',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080308': {
    code: '080308',
    district: 'Julcamarca',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080309': {
    code: '080309',
    district: 'San Antonio de Antaparco',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080310': {
    code: '080310',
    district: 'Santo Tomas de Pata',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080311': {
    code: '080311',
    district: 'Secclla',
    province: 'Angaraes',
    department: 'Huancavelica',
  },
  '080401': {
    code: '080401',
    district: 'Castrovirreyna',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080402': {
    code: '080402',
    district: 'Arma',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080403': {
    code: '080403',
    district: 'Aurahua',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080405': {
    code: '080405',
    district: 'Capillas',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080408': {
    code: '080408',
    district: 'Chupamarca',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080406': {
    code: '080406',
    district: 'Cocas',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080409': {
    code: '080409',
    district: 'Huachos',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080410': {
    code: '080410',
    district: 'Huamatambo',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080414': {
    code: '080414',
    district: 'Mollepampa',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080422': {
    code: '080422',
    district: 'San Juan',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080429': {
    code: '080429',
    district: 'Santa Ana',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080427': {
    code: '080427',
    district: 'Tantara',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080428': {
    code: '080428',
    district: 'Ticrapo',
    province: 'Castrovirreyna',
    department: 'Huancavelica',
  },
  '080701': {
    code: '080701',
    district: 'Churcampa',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080702': {
    code: '080702',
    district: 'Anco',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080703': {
    code: '080703',
    district: 'Chinchihuasi',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080704': {
    code: '080704',
    district: 'El Carmen',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080705': {
    code: '080705',
    district: 'La Merced',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080706': {
    code: '080706',
    district: 'Locroja',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080707': {
    code: '080707',
    district: 'Paucarbamba',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080708': {
    code: '080708',
    district: 'San Miguel de Mayocc',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080709': {
    code: '080709',
    district: 'San Pedro de Coris',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080710': {
    code: '080710',
    district: 'Pachamarca',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080711': {
    code: '080711',
    district: 'Cosme',
    province: 'Churcampa',
    department: 'Huancavelica',
  },
  '080604': {
    code: '080604',
    district: 'Huaytara',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080601': {
    code: '080601',
    district: 'Ayavi',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080602': {
    code: '080602',
    district: 'Cordova',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080603': {
    code: '080603',
    district: 'Huayacundo Arma',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080605': {
    code: '080605',
    district: 'Laramarca',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080606': {
    code: '080606',
    district: 'Ocoyo',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080607': {
    code: '080607',
    district: 'Pilpichaca',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080608': {
    code: '080608',
    district: 'Querco',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080609': {
    code: '080609',
    district: 'Quito-Arma',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080610': {
    code: '080610',
    district: 'San Antonio de Cusicancha',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080611': {
    code: '080611',
    district: 'San Francisco de Sangayaico',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080612': {
    code: '080612',
    district: 'San Isidro',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080613': {
    code: '080613',
    district: 'Santiago de Chocorvos',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080614': {
    code: '080614',
    district: 'Santiago de Quirahuara',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080615': {
    code: '080615',
    district: 'Santo Domingo de Capillas',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080616': {
    code: '080616',
    district: 'Tambo',
    province: 'Huaytara',
    department: 'Huancavelica',
  },
  '080501': {
    code: '080501',
    district: 'Pampas',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080502': {
    code: '080502',
    district: 'Acostambo',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080503': {
    code: '080503',
    district: 'Acraquia',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080504': {
    code: '080504',
    district: 'Ahuaycha',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080506': {
    code: '080506',
    district: 'Colcabamba',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080509': {
    code: '080509',
    district: 'Daniel Hernandez',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080511': {
    code: '080511',
    district: 'Huachocolpa',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080512': {
    code: '080512',
    district: 'Huaribamba',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080515': {
    code: '080515',
    district: 'Ñahuimpuquio',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080517': {
    code: '080517',
    district: 'Pazos',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080518': {
    code: '080518',
    district: 'Quishuar',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080519': {
    code: '080519',
    district: 'Salcabamba',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080526': {
    code: '080526',
    district: 'Salcahuasi',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080520': {
    code: '080520',
    district: 'San Marcos de Rocchac',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080523': {
    code: '080523',
    district: 'Surcubamba',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '080525': {
    code: '080525',
    district: 'Tintay Puncu',
    province: 'Tayacaja',
    department: 'Huancavelica',
  },
  '090101': {
    code: '090101',
    district: 'Huanuco',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090110': {
    code: '090110',
    district: 'Amarilis',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090102': {
    code: '090102',
    district: 'Chinchao',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090103': {
    code: '090103',
    district: 'Churubamba',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090104': {
    code: '090104',
    district: 'Margos',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090105': {
    code: '090105',
    district: 'Quisqui',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090106': {
    code: '090106',
    district: 'San Francisco de Cayran',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090107': {
    code: '090107',
    district: 'San Pedro de Chaulan',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090108': {
    code: '090108',
    district: 'Santa Maria del Valle',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090109': {
    code: '090109',
    district: 'Yarumayo',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090111': {
    code: '090111',
    district: 'Pillco Marca',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090112': {
    code: '090112',
    district: 'Yacus',
    province: 'Huanuco',
    department: 'Huanuco',
  },
  '090201': {
    code: '090201',
    district: 'Ambo',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090202': {
    code: '090202',
    district: 'Cayna',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090203': {
    code: '090203',
    district: 'Colpas',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090204': {
    code: '090204',
    district: 'Conchamarca',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090205': {
    code: '090205',
    district: 'Huacar',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090206': {
    code: '090206',
    district: 'San Francisco',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090207': {
    code: '090207',
    district: 'San Rafael',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090208': {
    code: '090208',
    district: 'Tomay Kichwa',
    province: 'Ambo',
    department: 'Huanuco',
  },
  '090301': {
    code: '090301',
    district: 'La Union',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090307': {
    code: '090307',
    district: 'Chuquis',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090312': {
    code: '090312',
    district: 'Marias',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090314': {
    code: '090314',
    district: 'Pachas',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090316': {
    code: '090316',
    district: 'Quivilla',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090317': {
    code: '090317',
    district: 'Ripan',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090321': {
    code: '090321',
    district: 'Shunqui',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090322': {
    code: '090322',
    district: 'Sillapata',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090323': {
    code: '090323',
    district: 'Yanas',
    province: 'Dos de Mayo',
    department: 'Huanuco',
  },
  '090901': {
    code: '090901',
    district: 'Huacaybamba',
    province: 'Huacaybamba',
    department: 'Huanuco',
  },
  '090903': {
    code: '090903',
    district: 'Canchabamba',
    province: 'Huacaybamba',
    department: 'Huanuco',
  },
  '090904': {
    code: '090904',
    district: 'Cochabamba',
    province: 'Huacaybamba',
    department: 'Huanuco',
  },
  '090902': {
    code: '090902',
    district: 'Pinra',
    province: 'Huacaybamba',
    department: 'Huanuco',
  },
  '090401': {
    code: '090401',
    district: 'Llata',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090402': {
    code: '090402',
    district: 'Arancay',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090403': {
    code: '090403',
    district: 'Chavin de Pariarca',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090404': {
    code: '090404',
    district: 'Jacas Grande',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090405': {
    code: '090405',
    district: 'Jircan',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090406': {
    code: '090406',
    district: 'Miraflores',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090407': {
    code: '090407',
    district: 'Monzon',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090408': {
    code: '090408',
    district: 'Punchao',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090409': {
    code: '090409',
    district: 'Puños',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090410': {
    code: '090410',
    district: 'Singa',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090411': {
    code: '090411',
    district: 'Tantamayo',
    province: 'Huamalies',
    department: 'Huanuco',
  },
  '090601': {
    code: '090601',
    district: 'Rupa-Rupa',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090602': {
    code: '090602',
    district: 'Daniel Alomias Robles',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090603': {
    code: '090603',
    district: 'Hermilio Valdizan',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090606': {
    code: '090606',
    district: 'Jose Crespo y Castillo',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090604': {
    code: '090604',
    district: 'Luyando',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090605': {
    code: '090605',
    district: 'Mariano Damaso Beraun',
    province: 'Leoncio Prado',
    department: 'Huanuco',
  },
  '090501': {
    code: '090501',
    district: 'Huacrachuco',
    province: 'Marañon',
    department: 'Huanuco',
  },
  '090502': {
    code: '090502',
    district: 'Cholon',
    province: 'Marañon',
    department: 'Huanuco',
  },
  '090505': {
    code: '090505',
    district: 'San Buenaventura',
    province: 'Marañon',
    department: 'Huanuco',
  },
  '090701': {
    code: '090701',
    district: 'Panao',
    province: 'Pachitea',
    department: 'Huanuco',
  },
  '090702': {
    code: '090702',
    district: 'Chaglla',
    province: 'Pachitea',
    department: 'Huanuco',
  },
  '090704': {
    code: '090704',
    district: 'Molino',
    province: 'Pachitea',
    department: 'Huanuco',
  },
  '090706': {
    code: '090706',
    district: 'Umari',
    province: 'Pachitea',
    department: 'Huanuco',
  },
  '090802': {
    code: '090802',
    district: 'Puerto Inca',
    province: 'Puerto Inca',
    department: 'Huanuco',
  },
  '090803': {
    code: '090803',
    district: 'Codo del Pozuzo',
    province: 'Puerto Inca',
    department: 'Huanuco',
  },
  '090801': {
    code: '090801',
    district: 'Honoria',
    province: 'Puerto Inca',
    department: 'Huanuco',
  },
  '090804': {
    code: '090804',
    district: 'Tournavista',
    province: 'Puerto Inca',
    department: 'Huanuco',
  },
  '090805': {
    code: '090805',
    district: 'Yuyapichis',
    province: 'Puerto Inca',
    department: 'Huanuco',
  },
  '091001': {
    code: '091001',
    district: 'Jesus',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091002': {
    code: '091002',
    district: 'Baños',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091007': {
    code: '091007',
    district: 'Jivia',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091004': {
    code: '091004',
    district: 'Queropalca',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091006': {
    code: '091006',
    district: 'Rondos',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091003': {
    code: '091003',
    district: 'San Francisco de Asis',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091005': {
    code: '091005',
    district: 'San Miguel de Cauri',
    province: 'Lauricocha',
    department: 'Huanuco',
  },
  '091101': {
    code: '091101',
    district: 'Chavinillo',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091103': {
    code: '091103',
    district: 'Cahuac',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091104': {
    code: '091104',
    district: 'Chacabamba',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091102': {
    code: '091102',
    district: 'Aparicio Pomares',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091105': {
    code: '091105',
    district: 'Jacas Chico',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091106': {
    code: '091106',
    district: 'Obas',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091107': {
    code: '091107',
    district: 'Pampamarca',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
  '091108': {
    code: '091108',
    district: 'Choras',
    province: 'Yarowilca',
    department: 'Huanuco',
  },
};

export const findLocationByUbigeoCode = code => {
  return ubigeoDetails[code] || null;
};
