import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
import { useStyles } from '../styles';
import { rowData, verifierRow } from '../utils';
import { formatCurrency, formatDatetime } from '../../../../utils/formatter';
import bankNameByAccountNumber from '../../../../utils/bankNameByAccountNumber';
import copyToClipboard from '../../../../utils/copyToClipboard';

const ApplicationInfo = ({ record }) => {
  const classes = useStyles();
  const notify = useNotify();
  const rows = [
    rowData('ID', record.id),
    rowData('Uuid', record.uuid),
    rowData('Contract number', record.contract_number),
    rowData('Product', record.product_code),
    rowData('Requested principal', formatCurrency(record.requested_principal)),
    rowData('Requested tenor', record.requested_tenor),
    rowData(
      'SLS approved principal',
      record.sls_auto_approved_principal && formatCurrency(record.sls_auto_approved_principal),
    ),
    rowData('SLS approved tenor', record.sls_auto_approved_tenor),
    record.min_approved_principal
      ? rowData(
          'Manually approved principal',
          record.min_approved_principal && formatCurrency(record.min_approved_principal),
        )
      : null,
    record.min_approved_tenor ? rowData('Manually approved tenor', record.min_approved_tenor) : null,
    rowData('State', record.state),
    rowData('Referrer', record.referrer),
    rowData('Verifier', verifierRow(record.decision_engine_id, record.state, record.verifier_username)),
    record.state === 'verification_control'
      ? rowData(
          'Checking verifier',
          verifierRow(record.decision_engine_id, record.state, record.checking_verifier_username),
        )
      : null,
    rowData('Created at', formatDatetime(record.created_at)),
    rowData('Resolved at', record.resolved_at && formatDatetime(record.resolved_at)),
    rowData('Resolved by', record.resolved_by_id),
    rowData('Confirmation code', record.confirmation_code),
    rowData('Confirmed at', record.confirmed_at && formatDatetime(record.confirmed_at)),
    rowData(
      'Bank name',
      record.bank_name || (record.account_number && bankNameByAccountNumber(record.account_number, 'Unknown')),
    ),
    rowData('Bank account', record.account_number),
    rowData('Request type', record.is_repeat ? 'Repeat' : 'First'),
    rowData('Affiliate', record.affiliate),
    rowData('Postponed until', record.postponed_until && formatDatetime(record.postponed_until)),
  ];

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };

  return (
    <Paper>
      <Typography variant="h6" gutterBottom={false} className={classes.paper}>
        Application
      </Typography>
      <Divider />
      <Table className={classes.table} size="small">
        <TableBody>
          {rows.map(row => {
            if (row) {
              return (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              );
            } else {
              return null;
            }
          })}
        </TableBody>
      </Table>
      <Box className={classes.flexColumn}>
        <Typography variant="body2" gutterBottom={false}>
          Application target URL
        </Typography>
        <Box className={classes.flexRow}>
          {record.target_url ? (
            <>
              <Typography className={classes.breakable} variant="body2" gutterBottom={false}>
                {record.target_url}
              </Typography>
              <IconButton onClick={() => copyText(record.target_url)}>
                <FileCopy />
              </IconButton>
            </>
          ) : null}
        </Box>
      </Box>
    </Paper>
  );
};

ApplicationInfo.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    contract_number: PropTypes.string,
    product_code: PropTypes.string,
    promo_code: PropTypes.string,
    requested_principal: PropTypes.number,
    requested_tenor: PropTypes.number,
    sls_auto_approved_principal: PropTypes.number,
    sls_auto_approved_tenor: PropTypes.number,
    state: PropTypes.string,
    referrer: PropTypes.string,
    decision_engine_id: PropTypes.string,
    verifier_username: PropTypes.string,
    created_at: PropTypes.string,
    resolved_at: PropTypes.string,
    resolved_by_id: PropTypes.number,
    confirmation_code: PropTypes.string,
    confirmed_at: PropTypes.string,
    bank_name: PropTypes.string,
    branch_name: PropTypes.string,
    account_number: PropTypes.string,
    is_repeat: PropTypes.bool,
    affiliate: PropTypes.string,
    postponed_until: PropTypes.string,
    checking_verifier_username: PropTypes.string,
    target_url: PropTypes.string,
    min_approved_tenor: PropTypes.string,
    min_approved_principal: PropTypes.string,
  }),
};

export default ApplicationInfo;
