import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGetList, useNotify } from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from '../styles';
import { DraggableDialog } from '../../../dialog/DraggableDialog';

const ManualVerificationDialog = ({ applicationId, isOpened, onClose, onSubmit }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [results, setResults] = useState({});

  const { data, loading, error } = useGetList(
    'manual_verification_steps',
    {},
    {},
    { 'application->id': applicationId },
  );

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  const onResultChange = (id, result) => {
    setResults({ ...results, ...{ [id]: result } });
  };

  const dictionary = {
    client: {
      title: 'Client verification.',
      description: 'Verify client.',
      disabled: false,
    },
    contact_person: {
      title: 'Contact person verification.',
      description: 'Verify contact person.',
      disabled: true,
    },
    employer: {
      title: 'Employer verification.',
      description: 'Verify employer.',
      disabled: true,
    },
  };

  const getTitle = key => (dictionary[key] && dictionary[key].title ? dictionary[key].title : 'Unknown');
  const getDescription = key =>
    dictionary[key] && dictionary[key].description ? dictionary[key].description : 'Unknown';

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        Manual verification.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Verification steps.</DialogContentText>
        {loading ? (
          <CircularProgress />
        ) : (
          <List dense={false}>
            {Object.keys(data).map(idx => (
              <ListItem key={idx} disableGutters className={classes.pr20}>
                <ListItemText primary={getTitle(data[idx].key)} secondary={getDescription(data[idx].key)} />
                <ListItemSecondaryAction className={classes.manualVerificationResultSelect}>
                  <Select
                    value={results[data[idx].id] || data[idx].result || ''}
                    onChange={e => onResultChange(data[idx].id, e.target.value)}
                    className={classes.fullWidth}>
                    <MenuItem value="success">Confirmed</MenuItem>
                    <MenuItem value="failure">Declined</MenuItem>
                    <MenuItem value="error">{"Doesn't respond"}</MenuItem>
                  </Select>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(results)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

ManualVerificationDialog.propTypes = {
  applicationId: PropTypes.number,
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ManualVerificationDialog;
