import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { inputDecimalHelper, navigateByArrows } from './utils';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';

const useStyles = makeStyles(() => ({
  tableHeader: {
    backgroundColor: '#d8e3f0',
    border: '1px solid #cfcfcf',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
}));

const AccrualTable = ({ rows, cols, readonly = true, value, onChange }) => {
  const [state, setState] = useState(value);
  const [isPasteInProcess, setIsPasteInProcess] = useState(false);

  const tableMapRef = useRef(new Map());

  const getElementRef = (row, col) => {
    if (tableMapRef.current.size) {
      return tableMapRef.current.get(`${row}-${col}`);
    }
  };

  const classes = useStyles();
  const notify = useNotify();

  useEffect(() => {
    onChange?.(state);
  }, [state, onChange]);

  const handleClick = async () => {
    setIsPasteInProcess(true);
    try {
      const text = await navigator.clipboard.readText();
      let parsedRows = text.split('\n').map(row => row.trim().replace(/\r/g, ''));
      const parsedTable = parsedRows.map(row => row.split('\t'));

      if (parsedRows.at(-1) === '') {
        parsedRows = parsedRows.slice(0, -1);
      }

      if (
        cols.length === parsedTable[0].length &&
        cols.every((col, id) => col === parsedTable[0][id]) &&
        rows.length === parsedRows.slice(1).length &&
        rows.every((row, id) => row === parsedTable.slice(1)[id][0])
      ) {
        const formattedTable = parsedTable[0].reduce((acc, key, i) => {
          acc[key] = parsedTable.slice(1).reduce((innerAcc, subArray) => {
            innerAcc[subArray[0]] = Number(subArray[i + 1]);
            return innerAcc;
          }, {});
          return acc;
        }, {});

        setState(formattedTable);
        setIsPasteInProcess(false);

        notify('Table inserted', 'success');
      } else {
        setIsPasteInProcess(false);

        notify('Error: header rows should be the same', 'error');
      }
    } catch (error) {
      setIsPasteInProcess(false);
      notify(`Error:${error.message}`, 'error');
    }
  };

  const handleKeyDown = useCallback(
    (e, rowId, colId) => {
      navigateByArrows(e.key, rowId, rows.length - 1, colId, cols.length - 1, getElementRef);
    },
    [rows.length, cols.length],
  );

  return (
    <>
      {!readonly && (
        <Button color="primary" onClick={handleClick}>
          <span style={{ opacity: isPasteInProcess ? '0.5' : 1 }}>Paste table from the clipboard</span>
          {isPasteInProcess && <CircularProgress style={{ position: 'absolute' }} size={30} />}
        </Button>
      )}
      <Box sx={{ position: 'relative' }}>
        <Table size="small" style={{ border: '1px solid #e3e3e3', opacity: isPasteInProcess ? '0.3' : 1 }}>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell></TableCell>
              {cols.map(item => (
                <TableCell key={item}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowId) => (
              <TableRow key={row}>
                <TableCell className={classes.tableHeader} style={{ width: '15%' }}>
                  {row}
                </TableCell>
                {cols.map((col, colId) => (
                  <React.Fragment key={`edit-cell-wrapper-${colId}`}>
                    {readonly ? (
                      <TableCell key={value[col][row]}>
                        {Number(value[col][row]).toFixed(inputDecimalHelper(value[col][row]))}
                      </TableCell>
                    ) : (
                      <TableCell style={{ padding: '6px' }}>
                        {state && (
                          <TextField
                            key={`edit-${value[col][row]}`}
                            inputRef={element => {
                              tableMapRef.current.set(`${rowId}-${colId}`, element);
                            }}
                            name={row}
                            variant="filled"
                            autoComplete="off"
                            value={state?.[col][row]}
                            size="small"
                            inputProps={{ style: { padding: '6px' } }}
                            onChange={e => {
                              e.target.value = e.target.value.replace(/[^0-9., ]/g, '');

                              if (!Number.isNaN(e.target.value)) {
                                setState(prevState => ({
                                  ...prevState,
                                  [col]: {
                                    ...prevState[col],
                                    [row]: e.target.value,
                                  },
                                }));
                              }
                            }}
                            onBlur={e =>
                              setState(prevState => ({
                                ...prevState,
                                [col]: {
                                  ...prevState[col],
                                  [row]: Number((+e.target.value).toFixed(inputDecimalHelper(e.target.value))),
                                },
                              }))
                            }
                            onKeyDown={e => handleKeyDown(e, rowId, colId)}
                          />
                        )}
                      </TableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

AccrualTable.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  readonly: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default AccrualTable;
