import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, useNotify, useRecordContext, useRefresh } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import EditProductDialog from './EditProductDialog';
import CloneProductDialog from './CloneProductDialog';
import { renderValue } from './utils';
import { EditButton, RowTitleCell, TableRowTitleSpanned } from './components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  table: {
    '& .MuiTableBody-root > .MuiTableRow-root:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  title: {
    marginBottom: '20px',
  },
}));

const Layout = () => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [pickedField, setPickedField] = useState(null);

  const classes = useStyles();
  const recordCtx = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSave = () => {
    handleCloseModal();
    refresh();
  };

  const handleCopyModal = () => {
    handleCloseCopyModal();
  };

  const handleOpenModal = useCallback((key, field, suffix = 'config', group) => {
    setOpenEditModal(true);
    setPickedField({ key, field, suffix, group });
  }, []);

  const handleCloseModal = () => {
    setOpenEditModal(false);
    setPickedField(null);
  };

  const handleCloseCopyModal = () => {
    setOpenCopyModal(false);
  };

  const handleCopyProduct = () => {
    setOpenCopyModal(true);
  };

  const handleCopyTable = async table => {
    try {
      await navigator.clipboard.writeText(table);
      notify(`Table is copied to clipboard`, 'success');
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    }
  };

  return (
    <>
      <Box>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '30px',
                  }}>
                  <Typography variant="h5">View & update product</Typography>
                  <Button variant="contained" color="primary" onClick={handleCopyProduct}>
                    Clone
                  </Button>
                </Grid>
              </Grid>

              <Typography variant="h6" className={classes.title}>
                Product data
              </Typography>
              <Table className={classes.table} style={{ marginBottom: '60px' }}>
                <TableBody>
                  <TableRow>
                    <RowTitleCell title="Strategy" />
                    <TableCell>{renderValue(recordCtx.strategy_name)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <RowTitleCell title="Name" />
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '4px' }}>
                        {renderValue(recordCtx.product_name)}
                        <EditButton onClick={() => handleOpenModal('product_name', recordCtx.product_name, 'data')} />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <RowTitleCell title="Description" />
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: recordCtx?.product_description ? 'space-between' : 'flex-end',
                          gap: '4px',
                        }}>
                        {renderValue(recordCtx.product_description)}
                        <EditButton
                          onClick={() => handleOpenModal('product_description', recordCtx.product_description, 'data')}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <RowTitleCell title="Product status" />
                    <TableCell>{renderValue(recordCtx.product_state)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <RowTitleCell title="Product code" />
                    <TableCell>{renderValue(recordCtx.product_code)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              {recordCtx.product_config ? (
                <>
                  <Typography variant="h6" className={classes.title}>
                    Product config
                  </Typography>
                  <Table className={classes.table}>
                    <TableBody>
                      {Object.entries(recordCtx.product_config).map(([groupKey, value]) => {
                        return value.type === 'group' ? (
                          <React.Fragment key={`group-${groupKey}`}>
                            <TableRowTitleSpanned name={value.title} description={value.description} />
                            {Object.entries(value.properties).map(([key, value]) => {
                              return value.type === 'accrual_matrix' ? (
                                <React.Fragment key={`group-${key}`}>
                                  <TableRowTitleSpanned
                                    name={value.title}
                                    noTopOffset
                                    border="unset"
                                    isNestedRow
                                    hasCopyButton
                                    onEditClick={() => handleOpenModal(key, value, 'config', groupKey)}
                                    onCopyClick={() =>
                                      handleCopyTable(renderValue(value.value, value.type).tableCopyText)
                                    }>
                                    {renderValue(value.value, value.type).table}
                                  </TableRowTitleSpanned>
                                </React.Fragment>
                              ) : (
                                <TableRow key={value.title}>
                                  <RowTitleCell title={value.title} description={value.description} />
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: '4px',
                                      }}>
                                      {renderValue(value.value, value.type, value.title)}
                                      <EditButton onClick={() => handleOpenModal(key, value, 'config', groupKey)} />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </React.Fragment>
                        ) : (
                          <TableRow key={`standalone-${value.title}`}>
                            <RowTitleCell title={value.title} description={value.description} />
                            <TableCell>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: '4px',
                                }}>
                                {renderValue(value.value, value.type, value.title)}
                                <EditButton onClick={() => handleOpenModal(groupKey, value)} />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              ) : (
                <CircularProgress />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {openCopyModal && (
        <CloneProductDialog
          open={openCopyModal}
          record={recordCtx}
          onClose={handleCloseCopyModal}
          onSave={handleCopyModal}
        />
      )}
      {openEditModal && (
        <EditProductDialog
          open={openEditModal}
          pickedField={pickedField}
          productId={recordCtx.id}
          record={recordCtx}
          onClose={handleCloseModal}
          onSave={handleSave}
        />
      )}
    </>
  );
};
const ProductEdit = props => (
  <Edit component="div" {...props}>
    <Layout />
  </Edit>
);

TableRowTitleSpanned.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  noTopOffset: PropTypes.bool,
  border: PropTypes.string,
  isNestedRow: PropTypes.bool,
  onEditClick: PropTypes.func,
};

export default ProductEdit;
