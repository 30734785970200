import React, { useEffect, useState } from 'react';
import { TitleCloseDialog } from '../../dialog/Dialog';
import { Box, Button, CircularProgress, DialogActions, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { isNotNullish } from '../../../utils/validator';

const CloneProductDialog = ({ record, open, onClose, onSave }) => {
  const [state, setState] = useState({ new_product_code: '', new_product_name: '', new_product_description: '' });
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  useEffect(() => {
    const checkAllStateFields = Object.values(state).every(value => isNotNullish(value));
    setValid(checkAllStateFields);
  }, [state]);

  const handleChangeValue = e => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    const endpoint = `product-config/${record.id}/clone`;

    const formData = new FormData(e.currentTarget);
    const formDataDetails = Object.fromEntries(formData);

    dataProvider
      .query(endpoint, { method: 'POST', body: JSON.stringify(formDataDetails) })
      .then(({ data }) => {
        notify('Product copy was created!', 'success');
        setLoading(false);
        redirect(`/product-config/${data.id}`);
        onSave();
      })
      .catch(e => {
        setLoading(false);
        notify(`Error: ${e.message}`, 'error');
      });
  };

  return (
    <TitleCloseDialog open={open} title={`Clone ${record.product_name} `} fullWidth close={onClose}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            variant="filled"
            label="New product code"
            value={state.new_product_code}
            name="new_product_code"
            onChange={handleChangeValue}
            fullWidth
          />
          <TextField
            variant="filled"
            label="New product name"
            value={state.new_product_name}
            name="new_product_name"
            onChange={handleChangeValue}
            fullWidth
          />
          <TextField
            variant="filled"
            label="New product description"
            value={state.new_product_description}
            name="new_product_description"
            onChange={handleChangeValue}
            fullWidth
          />
          <DialogActions>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!valid || loading}>
              {loading ? <CircularProgress size={20} /> : 'Clone product'}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </TitleCloseDialog>
  );
};

CloneProductDialog.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    product_name: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default CloneProductDialog;
