import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  DateInput,
  Edit,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  usePermissions,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Box from '@material-ui/core/Box';
import { Link } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { FileCopy } from '@material-ui/icons';
import { BANK_ACCOUNT_TYPES, formatChoices, formatChoicesToSnakeCase, GENDER } from '../../../utils/dictionary';
import { phoneNumber } from '../../../utils/adapter';
import {
  nameTransform,
  calculateAge,
  formatPhoneNumber,
  formatAddressToUrl,
  formatBankAccountNumber,
} from '../../../utils/formatter';
import ChangePhoneNumberDialog from '../../dialog/ChangePhoneNumberDialog';
import OldPhonesDialog from '../../old_phones/OldPhonesDialog';
import Call from '../../call/Call';
import { useHandbook } from '../../../hooks/useHandbook';
import UserLoanApplicationDialog from '../../dialog/UserLoanApplicationDialog';
import BlackList from '../../black_list/BlackList';
import BlackListMatch from '../../black_list/BlackListMatch';
import copyToClipboard from '../../../utils/copyToClipboard';
import WebitelButton from '../../webitel_button/WebitelButton';
import ImageZoom, { findImage } from './ImageZoom';
import UserEditTransitions from './UserEditTransitions';
import UserEditAdditionalInfo from './UserEditAdditionalInfo';
import { PHONE_COUNTRY_CODE } from '../../../constants';
import UserProfileCard from '../../userProfileCard/UserProfileCard';
import DateFnsAdapter from '@date-io/date-fns';
import UserReadonlyField from './UserReadonlyField';
import { CustomFormControlLabel } from './CustomFormLabelWrapper';
import { hasAnyTruthy, isNotNullish } from '../../../utils/validator';
import CopyUserInfoButton from './CopyUserInfoButton';
import snakeToHuman from '../../../utils/snakeToHuman';
import { findLocationByUbigeoCode } from '../../../utils/ubigeo';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  uploadIcon: {
    marginBottom: '-20px',
    marginTop: '-24px',
  },
  hidden: {
    display: 'none',
  },
  my20: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  flexCenteredSpaced: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  userTypography: {
    display: 'inline-block',
  },
  truncate: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
  editableFieldsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
  },
  cardHeader: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  gridWidth: {
    padding: '10px',
  },
  customMenuItems: {
    width: '100%',
    padding: '6px 16px',
    textDecoration: 'none !important',
    color: 'inherit',
  },
}));

const UserEditForm = props => {
  const personalInfoRef = useRef(null);
  const contactInfoRef = useRef(null);
  const workInfoRef = useRef(null);
  const bankInfoRef = useRef(null);

  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();
  const { permissions = [] } = usePermissions();
  const dataProvider = useDataProvider();

  const [lastUploadTimeSelfie, setLastUploadTimeSelfie] = useState(null);
  const [lastUploadTimeIdCard, setLastUploadTimeIdCard] = useState(null);
  const [lastUploadTimeIdCardBack, setLastUploadTimeIdCardBack] = useState(null);
  const [oldPhonesDialog, setOldPhonesDialogOpen] = useState(false);
  const [isChangePhoneNumberDialogOpened, setIsChangePhoneNumberDialogOpened] = useState(false);

  const [totalSelfies, setTotalSelfies] = useState(0);
  const [totalIdFront, setTotalIdFront] = useState(0);
  const [totalIdBack, setTotalIdBack] = useState(0);

  const [isLoanApplicationDialogOpened, setIsLoanApplicationDialogOpened] = useState(false);

  const isEditable = permissions.includes('CAN_USER_EDIT');
  const canPhoneEdit = permissions.includes('CAN_PHONE_NUMBER_EDIT');
  const canUserProfileDNIEdit = permissions.includes('CAN_USER_PROFILE_DNI_EDIT');
  const canBankAccountEdit = permissions.includes('CAN_USER_PROFILE_BANK_EDIT');
  const canApplicationEdit = permissions.includes('CAN_APPLICATION_EDIT');

  const { choices: educationChoices } = useHandbook('education');
  const { choices: maritalStatusChoices } = useHandbook('marital_statuses');
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const { choices: employmentIndustriesChoices } = useHandbook('employment_industries');
  const { choices: banks } = useHandbook('banks');
  const { choices: departments } = useHandbook('departments');

  const record = useRecordContext();

  const getBirthplaceByCode = () => {
    const { birth_place } = record;

    if (!isNaN(parseInt(birth_place)) && birth_place.length === 6) {
      const location = findLocationByUbigeoCode(birth_place);

      if (location) {
        const { department, province, district } = location;
        return `${department}, ${province}, ${district}`;
      } else {
        return birth_place;
      }
    } else {
      return birth_place;
    }
  };

  const getUserFullName = () => {
    const { first_name, father_surname, mother_surname } = record;
    const nameArray = [first_name, father_surname, mother_surname];

    return hasAnyTruthy(nameArray) ? nameArray.map(value => value ?? '--').join(' ') : null;
  };

  const getBankName = bankCode => {
    const bankItem = banks.find(bank => bank.id === bankCode);
    return bankItem?.name;
  };

  const getFullAddress = () => {
    const { residence_department, residence_province, residence_district, residence_address } = record;
    const departmentFormatted = isNotNullish(residence_department) && snakeToHuman(residence_department);
    const addressArray = [departmentFormatted, residence_province, residence_district, residence_address];
    return hasAnyTruthy(addressArray) ? addressArray.map(value => value || '--').join(', ') : null;
  };

  const validate = values => {
    const errors = {};

    if (values.dni_number && values.dni_number !== 8) {
      errors.dni = 'DNI length should be 8 symbols';
    }

    return errors;
  };

  const dateFns = new DateFnsAdapter();

  const submitPhone = (phone_number, formProps) => {
    const resource = 'users/' + formProps.record.id + '/phone_number';
    dataProvider
      .fetch(resource, {
        body: JSON.stringify(phone_number),
        method: 'PATCH',
      })
      .then(() => {
        notify('Success: Phone number was updated', 'success');
        setIsChangePhoneNumberDialogOpened(false);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const buildHandleUploadFile = (path, refreshHandler) => {
    return e => dataProvider.postImage(path, e.target.files[0]).then(() => refreshHandler(Date.now()));
  };

  const copyText = useCallback(
    async text => {
      try {
        await copyToClipboard(text);
        notify('Text copied to clipboard!', { type: 'success' });
      } catch (error) {
        notify(`Error: ${error}`, { type: 'error' });
      }
    },
    [notify],
  );
  const formatterNumber = () => formatPhoneNumber(PHONE_COUNTRY_CODE + record.phone_number);

  const exitEditMode = () => {
    personalInfoRef.current.resetEditMode();
    contactInfoRef.current.resetEditMode();
    workInfoRef.current.resetEditMode();
    bankInfoRef.current.resetEditMode();
  };

  const handleSuccessSubmit = () => {
    notify('Record updated.');
    exitEditMode();
  };
  return (
    <>
      <FormWithRedirect
        {...props}
        validate={validate}
        render={formProps => {
          return (
            <>
              {isEditable ? (
                <>
                  <UserEditTransitions
                    userId={formProps.record.id}
                    transitions={formProps.record.enabled_transitions}
                    refresh={refresh}
                    moratoriumTill={formProps.record.moratorium_till}
                    {...formProps.record}
                  />
                </>
              ) : null}
              <UserEditAdditionalInfo record={formProps.record} />
              <Divider className={classes.my20} />
              {!formProps.record.is_in_black_list && formProps.record.is_find_in_black_list && (
                <BlackListMatch
                  findInBlackList={formProps.record.find_in_black_list}
                  username={formProps.record.username}
                />
              )}
              {formProps.record.is_in_black_list && (
                <BlackList
                  createdBy={formProps.record.black_list.createdBy}
                  createdAt={formProps.record.black_list.createdAt}
                  comment={formProps.record.black_list.comment}
                  reason={formProps.record.black_list.reason}
                />
              )}
              {formProps.record.state === 'blocked' && (
                <MuiAlert elevation={6} variant="filled" severity="error" className={classes.mb3}>
                  User is blocked!
                </MuiAlert>
              )}
              <form>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={7}>
                    <Paper className={classes.paper}>
                      <Box className={classes.cardHeader}>
                        <Box>
                          {getUserFullName() && (
                            <Typography component="span" variant="h5" style={{ marginRight: '8px' }}>
                              {getUserFullName()},
                            </Typography>
                          )}
                          <Typography component="span" variant="h5">
                            {formatterNumber()}
                          </Typography>
                        </Box>
                        {permissions.indexOf('CAN_IMPERSONATION') !== -1 && (
                          <FunctionField
                            label="Actions"
                            render={({ id }) => (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={e => {
                                  e.stopPropagation();
                                  dataProvider
                                    .query(`users/${id}/token`, { method: 'GET' })
                                    .then(({ data }) => {
                                      window.open(
                                        `${process.env.REACT_APP_FRONT_HOST}/user/registration?impersonate=${encodeURIComponent(JSON.stringify(data))}`,
                                      );
                                    })
                                    .catch(error => {
                                      notify(`Error: ${error.message || 'token not found'}`, 'error');
                                    });
                                }}>
                                Impersonate
                              </Button>
                            )}
                          />
                        )}
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          paddingTop: '12px',
                        }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          <Typography variant="body1">
                            Created on: {dateFns.format(new Date(record.created_at), 'yyyy-MM-dd')}
                          </Typography>
                          <Typography variant="body1">Current registration step: {record.registration_step}</Typography>
                        </Box>
                        <CopyUserInfoButton onCopy={copyText} record={record} />
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <UserProfileCard
                            title="Personal info"
                            ref={personalInfoRef}
                            readOnlyContent={
                              <>
                                <UserReadonlyField
                                  value={getUserFullName()}
                                  noValueMsg="Name"
                                  actions={[{ title: "Copy user's name", onClick: () => copyText(getUserFullName()) }]}
                                  valueText={getUserFullName()}
                                />
                                <UserReadonlyField
                                  value={record.birthday}
                                  noValueMsg="Birthday"
                                  valueText={`${record.birthday} (${calculateAge(record.birthday)} y.o.)`}
                                />
                                <UserReadonlyField
                                  value={record.birth_place}
                                  noValueMsg="Birthplace"
                                  label="born in"
                                  valueText={getBirthplaceByCode()}
                                  actions={[
                                    { title: 'Copy birthplace', onClick: () => copyText(getBirthplaceByCode()) },
                                    <Link
                                      key={'See birthplace on the map'}
                                      href={formatAddressToUrl(getBirthplaceByCode())}
                                      className={classes.customMenuItems}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      See birthplace on the map
                                    </Link>,
                                  ]}
                                />
                                <UserReadonlyField
                                  value={record.gender}
                                  noValueMsg="Gender"
                                  valueText={record.gender}
                                />
                                <UserReadonlyField
                                  value={record.dni}
                                  noValueMsg="DNI number"
                                  actions={[{ title: 'Copy DNI number', onClick: () => copyText(record.dni) }]}
                                  label="DNI number - "
                                  valueText={record.dni}
                                />
                                <UserReadonlyField
                                  value={record.dni_expired_at}
                                  noValueMsg="DNI exp. date"
                                  label="DNI expires on"
                                  valueText={
                                    record.dni_expired_at
                                      ? dateFns.format(new Date(record.dni_expired_at), 'yyyy-MM-dd')
                                      : ''
                                  }
                                />
                                <UserReadonlyField
                                  value={record.marital_status}
                                  noValueMsg="Marital status"
                                  valueText={record.marital_status}
                                  isLast
                                />
                              </>
                            }
                            editContent={
                              <>
                                <Box className={classes.editableFieldsWrapper}>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      format={v => nameTransform(v)}
                                      source="first_name"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      format={v => nameTransform(v)}
                                      source="father_surname"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      format={v => nameTransform(v)}
                                      source="mother_surname"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={
                                        !isEditable || (!canUserProfileDNIEdit && !(!props.record.dni && !isEditable))
                                      }
                                      label="DNI number"
                                      source="dni"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <DateInput
                                      disabled={
                                        !isEditable ||
                                        (!canUserProfileDNIEdit && !(!props.record.expired_at && !isEditable))
                                      }
                                      label="DNI expiration date"
                                      source="dni_expired_at"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <DateInput
                                      disabled={!isEditable}
                                      label="Birthday"
                                      source="birthday"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      label="Birth place"
                                      source="birth_place"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <SelectInput
                                      disabled={!isEditable}
                                      label="Gender"
                                      source="gender"
                                      fullWidth
                                      helperText={false}
                                      choices={formatChoices(GENDER)}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <SelectInput
                                      key={`marital-statuses-input-${maritalStatusChoices.length}`}
                                      disabled={!isEditable}
                                      label="Marital status"
                                      source="marital_status"
                                      fullWidth
                                      helperText={false}
                                      choices={maritalStatusChoices}
                                    />
                                  </Grid>
                                </Box>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UserProfileCard
                            title="Residence & Contacts info"
                            ref={contactInfoRef}
                            readOnlyContent={
                              <>
                                <UserReadonlyField
                                  value={getFullAddress()}
                                  noValueMsg="Full address"
                                  actions={[
                                    { title: 'Copy address', onClick: () => copyText(getFullAddress()) },
                                    <Link
                                      key={'See address on the map'}
                                      href={formatAddressToUrl(getFullAddress())}
                                      className={classes.customMenuItems}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      See address on the map
                                    </Link>,
                                  ]}
                                  valueText={getFullAddress()}
                                />
                                <UserReadonlyField
                                  value={record.phone_number}
                                  noValueMsg="Phone"
                                  actions={[
                                    { title: 'Copy phone number', onClick: () => copyText(record.phone_number) },
                                    <WebitelButton
                                      key={'webitel-menu-item'}
                                      userPhone={formProps.record.username}
                                      withIcon={false}
                                      className={classes.customMenuItems}>
                                      Go to Webitel
                                    </WebitelButton>,
                                    <Call
                                      inactive={formProps.record.has_sold_loans}
                                      userId={formProps.record.id}
                                      userPhone={formProps.record.username}
                                      key={'call-menu-item'}
                                      withIcon={false}
                                      className={classes.customMenuItems}>
                                      Call user
                                    </Call>,
                                    <Link
                                      key={'wammchat'}
                                      href={formProps.record.wamm_chat_uri}
                                      target="_blank"
                                      className={classes.customMenuItems}>
                                      Go to WammChat
                                    </Link>,
                                  ]}
                                  valueText={formatterNumber()}
                                />
                                <UserReadonlyField
                                  value={record.email}
                                  noValueMsg="Email"
                                  actions={[{ title: "Copy user's email", onClick: () => copyText(record.email) }]}
                                  valueText={record.email}
                                  isLast
                                />
                              </>
                            }
                            editContent={
                              <>
                                <Box className={classes.editableFieldsWrapper}>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <TextInput
                                      disabled
                                      source="phone_number"
                                      fullWidth
                                      helperText={false}
                                      placeholder={phoneNumber.placeholder}
                                      parse={phoneNumber.parser}
                                      format={value => phoneNumber.formatter(value)}
                                    />
                                    <ButtonGroup>
                                      <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                          setOldPhonesDialogOpen(true);
                                        }}>
                                        Old phone numbers
                                      </Button>
                                      <Button
                                        variant="text"
                                        color="secondary"
                                        size="small"
                                        onClick={() => {
                                          setIsChangePhoneNumberDialogOpened(true);
                                        }}
                                        disabled={!canPhoneEdit}>
                                        Change
                                      </Button>
                                    </ButtonGroup>
                                    <OldPhonesDialog
                                      userId={formProps.record.id}
                                      isOpen={oldPhonesDialog}
                                      onClose={setOldPhonesDialogOpen}
                                    />
                                  </Grid>
                                  <ChangePhoneNumberDialog
                                    fieldName="phone_number"
                                    isOpened={isChangePhoneNumberDialogOpened}
                                    onClose={() => setIsChangePhoneNumberDialogOpened(false)}
                                    onSubmit={phone_number => submitPhone(phone_number, formProps)}
                                  />
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      label="Email"
                                      source="email"
                                      type="email"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      key={`departments-input-${departments.length}`}
                                      disabled={!isEditable}
                                      label="Department"
                                      source="residence_department"
                                      fullWidth
                                      helperText={false}
                                      choices={departments}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      label="Province"
                                      source="residence_province"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      label="District"
                                      source="residence_district"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={5} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={!isEditable}
                                      label="Address"
                                      source="residence_address"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                </Box>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UserProfileCard
                            title="Education & Work info"
                            ref={workInfoRef}
                            readOnlyContent={
                              <>
                                <Box>
                                  <UserReadonlyField
                                    value={record.education}
                                    noValueMsg="Education"
                                    label="Education - "
                                    valueText={record.education}
                                  />
                                  <UserReadonlyField
                                    value={record.employment_type && record.employment_industry}
                                    noValueMsg="Employment"
                                    label="Works as "
                                    valueText={`${record.employment_type} in ${record.employment_industry}`}
                                  />
                                  <UserReadonlyField
                                    value={record.monthly_income && record.monthly_expense}
                                    noValueMsg="Budget"
                                    label="Monthly budget: "
                                    valueText={`Income - ${record.monthly_income}; Expenses - ${record.monthly_expense}`}
                                    isLast
                                  />
                                </Box>
                              </>
                            }
                            editContent={
                              <>
                                <Box className={classes.editableFieldsWrapper}>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      key={`educations-input-${educationChoices.length}`}
                                      disabled={!isEditable}
                                      label="Education"
                                      source="education"
                                      fullWidth
                                      helperText={false}
                                      choices={educationChoices}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      key={`employment-type-input-${employmentTypeChoices.length}`}
                                      disabled={!isEditable}
                                      label="Employment type"
                                      source="employment_type"
                                      fullWidth
                                      helperText={false}
                                      choices={employmentTypeChoices}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      key={`employment-industries-input-${employmentIndustriesChoices.length}`}
                                      disabled={!isEditable}
                                      label="Employment industry"
                                      source="employment_industry"
                                      fullWidth
                                      helperText={false}
                                      choices={employmentIndustriesChoices}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <NumberInput
                                      disabled={!isEditable}
                                      label="Monthly income"
                                      source="monthly_income"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={3} className={classes.gridWidth}>
                                    <NumberInput
                                      disabled={!isEditable}
                                      label="Monthly expenses"
                                      source="monthly_expense"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                </Box>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UserProfileCard
                            title="Bank account info"
                            ref={bankInfoRef}
                            readOnlyContent={
                              <>
                                <Box>
                                  <UserReadonlyField
                                    value={getBankName(record.bank_name)}
                                    noValueMsg="Bank"
                                    isFirst
                                    actions={[
                                      {
                                        title: 'Copy bank code',
                                        onClick: () => copyText(record.bank_name),
                                      },
                                      {
                                        title: 'Copy bank name',
                                        onClick: () => copyText(getBankName(record.bank_name)),
                                      },
                                    ]}
                                    label="Bank: "
                                    valueText={getBankName(record.bank_name)}
                                  />
                                  <UserReadonlyField
                                    value={record.bank_account_type}
                                    noValueMsg="Bank account type"
                                    label="Bank account type: "
                                    valueText={record.bank_account_type}
                                  />
                                  <UserReadonlyField
                                    value={record.bank_account_number}
                                    noValueMsg="Bank account number"
                                    label="Bank account number: "
                                    valueText={formatBankAccountNumber(record.bank_account_number)}
                                    isLast
                                    actions={[
                                      {
                                        title: 'Copy bank account number',
                                        onClick: () => copyText(record.bank_account_number),
                                      },
                                    ]}
                                  />
                                </Box>
                              </>
                            }
                            editContent={
                              <>
                                <Box className={classes.editableFieldsWrapper}>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      disabled={
                                        !isEditable || (!canBankAccountEdit && !(!props.record.bank_name && isEditable))
                                      }
                                      label="Bank name"
                                      source="bank_name"
                                      fullWidth
                                      helperText={false}
                                      choices={banks}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <SelectInput
                                      disabled={!isEditable}
                                      label="Bank account type"
                                      source="bank_account_type"
                                      fullWidth
                                      helperText={false}
                                      choices={formatChoicesToSnakeCase(BANK_ACCOUNT_TYPES)}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} className={classes.gridWidth}>
                                    <TextInput
                                      disabled={
                                        !isEditable ||
                                        (!canBankAccountEdit && !(!props.record.bank_account_number && isEditable))
                                      }
                                      label="Bank account number"
                                      source="bank_account_number"
                                      fullWidth
                                      helperText={false}
                                    />
                                  </Grid>
                                </Box>
                              </>
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <UserProfileCard
                            title="Product info"
                            readOnlyContent={
                              <>
                                <Box>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography
                                      className={(classes.userTypography, classes.truncate)}
                                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                      User target URL - {record.target_url}
                                    </Typography>
                                    <IconButton
                                      disabled={!props.record.target_url}
                                      onClick={() => copyText(props.record.target_url)}>
                                      <FileCopy />
                                    </IconButton>
                                  </Box>
                                  <Typography className={classes.userTypography}>
                                    Product code - {record.preferred_product_code}
                                  </Typography>
                                </Box>
                              </>
                            }
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                          <CustomFormControlLabel
                            control={
                              <BooleanInput
                                disabled={!isEditable}
                                source="has_external_loan"
                                label={''}
                                helperText={false}
                              />
                            }
                            label="Has external loan?"
                            labelPlacement="bottom"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                          <CustomFormControlLabel
                            control={
                              <BooleanInput
                                disabled={false}
                                label={''}
                                source="is_allow_to_receive_marketing_email"
                                helperText={false}
                              />
                            }
                            label="Allow to receive marketing email"
                            labelPlacement="bottom"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ display: 'flex', justifyContent: 'center', pointerEvents: 'none' }}>
                          <CustomFormControlLabel
                            control={
                              <BooleanInput
                                disabled={false}
                                label=""
                                source="is_allow_to_receive_collection_email"
                                helperText={false}
                              />
                            }
                            label="Allow to receive collection email"
                            labelPlacement="bottom"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'center' }}>
                          <CustomFormControlLabel
                            control={
                              <BooleanInput disabled={false} label="" source="do_not_disturb" helperText={false} />
                            }
                            label="Do not disturb this user"
                            labelPlacement="bottom"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Box className={classes.flexCenteredSpaced}>
                            <Typography variant="h6" gutterBottom={false}>
                              <label htmlFor="id_card_upload">
                                <input
                                  disabled={!isEditable}
                                  className={classes.hidden}
                                  type="file"
                                  id="id_card_upload"
                                  onChange={buildHandleUploadFile(
                                    `users/${props.record.id}/id_card`,
                                    setLastUploadTimeIdCard,
                                  )}
                                />

                                <IconButton
                                  disabled={!isEditable}
                                  color="primary"
                                  aria-label="upload picture"
                                  className={classes.uploadIcon}
                                  component="span"
                                  edge="start">
                                  <CloudUploadIcon />
                                </IconButton>
                              </label>
                              Id card front
                            </Typography>
                            {totalIdFront > 0 && (
                              <Typography variant="caption" color="textSecondary">
                                {`${totalIdFront} ${totalIdFront === 1 ? 'file' : 'files'}`}
                              </Typography>
                            )}
                          </Box>
                          <Box pb={1}>
                            <Divider />
                          </Box>
                          <ImageZoom
                            isExist={findImage(props.record.uploaded_files, 'id_card')}
                            userId={props.record.id}
                            label="id_card"
                            lastUploadTime={lastUploadTimeIdCard}
                            handleSetTotal={setTotalIdFront}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper}>
                          <Box className={classes.flexCenteredSpaced}>
                            <Typography variant="h6" gutterBottom={false}>
                              <label htmlFor="id_card_back_side">
                                <input
                                  disabled={!isEditable}
                                  className={classes.hidden}
                                  type="file"
                                  id="id_card_back_side"
                                  onChange={buildHandleUploadFile(
                                    `users/${props.record.id}/id_card_back_side`,
                                    setLastUploadTimeIdCardBack,
                                  )}
                                />

                                <IconButton
                                  disabled={!isEditable}
                                  color="primary"
                                  aria-label="upload picture"
                                  className={classes.uploadIcon}
                                  component="span"
                                  edge="start">
                                  <CloudUploadIcon />
                                </IconButton>
                              </label>
                              Id card back
                            </Typography>
                            {totalIdBack > 0 && (
                              <Typography variant="caption" color="textSecondary">
                                {`${totalIdBack} ${totalIdBack === 1 ? 'file' : 'files'}`}
                              </Typography>
                            )}
                          </Box>
                          <Box pb={1}>
                            <Divider />
                          </Box>
                          <ImageZoom
                            isExist={findImage(props.record.uploaded_files, 'id_card_back_side')}
                            userId={props.record.id}
                            label="id_card_back_side"
                            lastUploadTime={lastUploadTimeIdCardBack}
                            handleSetTotal={setTotalIdBack}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Box className={classes.flexCenteredSpaced}>
                          <Typography variant="h6" gutterBottom={false}>
                            <label htmlFor="selfie_upload">
                              <input
                                disabled={!isEditable}
                                className={classes.hidden}
                                type="file"
                                id="selfie_upload"
                                onChange={buildHandleUploadFile(
                                  `users/${props.record.id}/selfie`,
                                  setLastUploadTimeSelfie,
                                )}
                              />

                              <IconButton
                                disabled={!isEditable}
                                color="primary"
                                aria-label="upload picture"
                                className={classes.uploadIcon}
                                component="span"
                                edge="start">
                                <CloudUploadIcon />
                              </IconButton>
                            </label>
                            Selfie
                          </Typography>
                          {totalSelfies > 0 && (
                            <Typography variant="caption" color="textSecondary">
                              {`${totalSelfies} ${totalSelfies === 1 ? 'file' : 'files'}`}
                            </Typography>
                          )}
                        </Box>
                        {props.record.similarity_card_and_selfie !== null ? (
                          <Typography variant="body1">{`Similarity ${props.record.similarity_card_and_selfie}%`}</Typography>
                        ) : (
                          findImage(props.record.uploaded_files, 'dni_front_side') &&
                          findImage(props.record.uploaded_files, 'selfie') && (
                            <Typography variant="caption" color="error">
                              No face is recognized on the ID card
                            </Typography>
                          )
                        )}
                        <Box pb={1}>
                          <Divider />
                        </Box>
                        <ImageZoom
                          isExist={findImage(props.record.uploaded_files, 'selfie')}
                          userId={props.record.id}
                          label="selfie"
                          lastUploadTime={lastUploadTimeSelfie}
                          handleSetTotal={setTotalSelfies}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                <Divider className={classes.my20} />
                <Grid item xs={12}>
                  <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button startIcon={<ListIcon />} onClick={() => redirect('/users')}>
                      Back to list
                    </Button>
                    {(canApplicationEdit &&
                      !formProps.record.is_in_black_list &&
                      !formProps.record.is_find_in_black_list) ||
                    (canApplicationEdit && !formProps.record.is_in_black_list) ? (
                      <Button startIcon={<AddBoxIcon />} onClick={() => setIsLoanApplicationDialogOpened(true)}>
                        Create application
                      </Button>
                    ) : null}
                    {isEditable ? (
                      <SaveButton
                        saving={formProps.saving}
                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        onSuccess={handleSuccessSubmit}
                        onFailure={e => notify(e.message, 'error')}
                      />
                    ) : null}
                  </ButtonGroup>
                </Grid>
              </form>
            </>
          );
        }}
      />
      {canApplicationEdit && isLoanApplicationDialogOpened ? (
        <UserLoanApplicationDialog onClose={() => setIsLoanApplicationDialogOpened(false)} record={props.record} />
      ) : null}
    </>
  );
};

UserEditForm.propTypes = {
  record: PropTypes.object,
};

const UserEdit = props => (
  <Edit component="div" mutationMode="pessimistic" {...props}>
    <UserEditForm />
  </Edit>
);

export default UserEdit;
