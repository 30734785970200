import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Error, useDataProvider, useNotify, usePermissions } from 'react-admin';
import formatISO from 'date-fns/formatISO';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import RejectTransitionDialog from '../../../dialog/RejectTransitionDialog';
import DebounceButton from '../../../button/DebounceButton';

import ActivateTransitionDialog from './ActivateTransitionDialog';
import CloseTransitionDialog from './CloseTransitionDialog';
import ChangeTermsTransitionDialog from './ChangeTermsTransitionDialog';
import TransferMoneyTransitionDialog from './TransferMoneyTransitionDialog';
import ReadyForDisbursementTransitionDialog from './ReadyForDisbursementTransitionDialog';
import AssignCollectorTransitionDialog from './AssignCollectorTransitionDialog';
import AssignAgencyTransitionDialog from './AssignAgencyTransitionDialog';
import UnassignAgencyTransitionDialog from './UnassignAgencyTransitionDialog';
import SellLoanTransitionDialog from './SellLoanTransitionDialog';
import TransitionConfirmationDialog from './TransitionConfirmationDialog';

const LoanTransitions = ({ record, refreshedAt, refresh }) => {
  const [transitions, setTransitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [isActivateTransitionDialogOpened, setIsActivateTransitionDialogOpened] = useState(false);
  const [isCloseTransitionDialogOpened, setIsCloseTransitionDialogOpened] = useState(false);
  const [isChangeTermsTransitionDialogOpened, setIsChangeTermsTransitionDialogOpened] = useState(false);
  const [isTransferMoneyTransitionDialogOpened, setIsTransferMoneyTransitionDialogOpened] = useState(false);
  const [isReadyForDisbursementTransitionDialogOpened, setIsReadyForDisbursementTransitionDialogOpened] =
    useState(false);
  const [isCancelTransitionDialogOpened, setIsCancelTransitionDialogOpened] = useState(false);
  const [isAssignCollectorTransitionDialogOpened, setIsAssignCollectorTransitionDialogOpened] = useState(false);
  const [isAssignAgencyTransitionDialogOpened, setIsAssignAgencyTransitionDialogOpened] = useState(false);
  const [isUnassignAgencyTransitionDialogOpened, setIsUnassignAgencyTransitionDialogOpened] = useState(false);
  const [isSellLoanTransitionDialogOpened, setIsSellLoanTransitionDialogOpened] = useState(false);
  const [isDefaultLoanTransitionDialogOpened, setIsDefaultLoanTransitionDialogOpened] = useState(false);
  const [isResetLoanTransitionDialogOpened, setIsResetLoanTransitionDialogOpened] = useState(false);
  const [isActivateClosedLoanTransitionDialogOpened, setIsActivateClosedLoanTransitionDialogOpened] = useState(false);
  const [isUnassignLoanTransitionDialogOpened, setIsUnassignLoanTransitionDialogOpened] = useState(false);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const isActiveOrDefaulted = ['active', 'defaulted'].includes(record.state);
  const canSellLoan = ['active', 'defaulted'].includes(record.state);
  const showReset = ['defaulted', 'closed', 'sold', 'inactive', 'pending', 'cep_fail'].includes(record.state)
    ? false
    : ['active', 'cancelled'].includes(record.state) && permissions.includes('CAN_LOAN_TRANSITION_RESET');
  const isUnassignedBoth = record.assigned_collector === null && record.external_agency_id === null;
  const isAgencyAssigned = record.external_agency_id !== null;
  const isCollectorAssigned = record.assigned_collector !== null;

  useEffect(() => {
    if (record.id) {
      dataProvider
        .query(`loans/${record.id}/transitions`, { method: 'GET' })
        .then(({ data }) => setTransitions(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false));
    }
  }, [dataProvider, record.id, refreshedAt]);

  const applyTransition = (name, params = {}) => {
    setDisabled(state => !state);
    dataProvider
      .query(`loans/${record.id}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setDisabled(state => !state));
  };

  const applyCollectionSpecialist = (params = {}) => {
    setDisabled(state => !state);
    dataProvider
      .query('phone_call_loan_assigns', {
        method: 'POST',
        body: JSON.stringify(params),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setDisabled(state => !state));
  };

  const applyExternalAgency = (params = {}) => {
    setDisabled(state => !state);
    dataProvider
      .query(`loans/${record.id}`, {
        method: 'PATCH',
        body: JSON.stringify(params),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setDisabled(state => !state));
  };

  if (loading) return <CircularProgress />;
  if (error) return <Error />;
  if (!transitions) return null;

  return (
    <>
      <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
        {transitions.includes('activate') && (
          <Button
            onClick={() =>
              record.state === 'closed'
                ? setIsActivateClosedLoanTransitionDialogOpened(true)
                : setIsActivateTransitionDialogOpened(true)
            }
            disabled={disabled}>
            Activate
          </Button>
        )}
        {transitions.includes('cancel') && (
          <Button onClick={() => setIsCancelTransitionDialogOpened(true)} disabled={disabled}>
            Cancel
          </Button>
        )}
        {transitions.includes('close') && (
          <Button onClick={() => setIsCloseTransitionDialogOpened(true)} disabled={disabled}>
            Close
          </Button>
        )}
        {transitions.includes('default') && (
          <Button onClick={() => setIsDefaultLoanTransitionDialogOpened(true)} disabled={disabled}>
            Default
          </Button>
        )}
        {transitions.includes('change_terms') && (
          <Button onClick={() => setIsChangeTermsTransitionDialogOpened(true)} disabled={disabled}>
            Change terms
          </Button>
        )}
        {transitions.includes('transfer_money') && (
          <Button onClick={() => setIsTransferMoneyTransitionDialogOpened(true)} disabled={disabled}>
            Transfer money
          </Button>
        )}
        {transitions.includes('ready_for_disbursement') && (
          <Button onClick={() => setIsReadyForDisbursementTransitionDialogOpened(true)} disabled={disabled}>
            {record.is_ready_for_disbursement ? 'Unmark' : 'Mark'} ready for disbursement
          </Button>
        )}
        {showReset && (
          <Button onClick={() => setIsResetLoanTransitionDialogOpened(true)} disabled={disabled}>
            Reset
          </Button>
        )}
        {(transitions.includes('assign_collector') || transitions.includes('assign_defaulted_collector')) &&
          isUnassignedBoth && (
            <Button onClick={() => setIsAssignCollectorTransitionDialogOpened(true)} disabled={disabled}>
              Assign collector
            </Button>
          )}
        {(transitions.includes('unassign_collector') || transitions.includes('unassign_defaulted_collector')) &&
          isCollectorAssigned && (
            <Button onClick={() => setIsUnassignLoanTransitionDialogOpened(true)} disabled={disabled}>
              Unassign collector
            </Button>
          )}
        {isActiveOrDefaulted && isUnassignedBoth && (
          <Button onClick={() => setIsAssignAgencyTransitionDialogOpened(true)} disabled={disabled}>
            Assign agency
          </Button>
        )}
        {isActiveOrDefaulted && isAgencyAssigned && (
          <Button onClick={() => setIsUnassignAgencyTransitionDialogOpened(true)} disabled={disabled}>
            Unassign agency
          </Button>
        )}
        {canSellLoan && (
          <Button onClick={() => setIsSellLoanTransitionDialogOpened(true)} disabled={disabled}>
            Sell loan
          </Button>
        )}
        <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
      </ButtonGroup>
      {isActivateTransitionDialogOpened && (
        <ActivateTransitionDialog
          isOpened={isActivateTransitionDialogOpened}
          onClose={() => setIsActivateTransitionDialogOpened(false)}
          onSubmit={disbursementDate => {
            setIsActivateTransitionDialogOpened(false);
            applyTransition('activate', { disbursement_date: formatISO(disbursementDate) });
          }}
        />
      )}
      {isCloseTransitionDialogOpened && (
        <CloseTransitionDialog
          isOpened={isCloseTransitionDialogOpened}
          onClose={() => setIsCloseTransitionDialogOpened(false)}
          onSubmit={closureDate => {
            setIsCloseTransitionDialogOpened(false);
            applyTransition('close', { closure_date: formatISO(closureDate) });
          }}
        />
      )}
      {isChangeTermsTransitionDialogOpened && (
        <ChangeTermsTransitionDialog
          isOpened={isChangeTermsTransitionDialogOpened}
          onClose={() => setIsChangeTermsTransitionDialogOpened(false)}
          onSubmit={(product_id, principal, tenor, promo_code) => {
            setIsChangeTermsTransitionDialogOpened(false);
            applyTransition('change_terms', { product_id, principal, tenor, promo_code });
          }}
          record={record}
        />
      )}
      {isTransferMoneyTransitionDialogOpened && (
        <TransferMoneyTransitionDialog
          isOpened={isTransferMoneyTransitionDialogOpened}
          onClose={() => setIsTransferMoneyTransitionDialogOpened(false)}
          onSubmit={gateway => {
            setIsTransferMoneyTransitionDialogOpened(false);
            applyTransition('transfer_money', { gateway });
          }}
        />
      )}
      {isCancelTransitionDialogOpened && (
        <RejectTransitionDialog
          title="Cancel loan"
          text="Please select cancellation reason"
          isOpened={isCancelTransitionDialogOpened}
          onClose={() => setIsCancelTransitionDialogOpened(false)}
          onSubmit={cancel_reason_code => {
            setIsCancelTransitionDialogOpened(false);
            applyTransition('cancel', { cancel_reason_code });
          }}
        />
      )}
      {isReadyForDisbursementTransitionDialogOpened && (
        <ReadyForDisbursementTransitionDialog
          isOpened={isReadyForDisbursementTransitionDialogOpened}
          onClose={() => setIsReadyForDisbursementTransitionDialogOpened(false)}
          onSubmit={value => {
            setIsReadyForDisbursementTransitionDialogOpened(false);
            applyTransition('ready_for_disbursement', { value });
          }}
          record={record}
        />
      )}
      {isAssignCollectorTransitionDialogOpened && (
        <AssignCollectorTransitionDialog
          isOpened={isAssignCollectorTransitionDialogOpened}
          onClose={() => setIsAssignCollectorTransitionDialogOpened(false)}
          collectionGroup={record.collection_group}
          onSubmit={id => {
            setIsAssignCollectorTransitionDialogOpened(false);
            applyCollectionSpecialist({ loan_id: record.id, admin_id: id, context: 'loan_collection_loans' });
          }}
        />
      )}
      {isAssignAgencyTransitionDialogOpened && (
        <AssignAgencyTransitionDialog
          isOpened={isAssignAgencyTransitionDialogOpened}
          onClose={() => setIsAssignAgencyTransitionDialogOpened(false)}
          onSubmit={id => {
            setIsAssignAgencyTransitionDialogOpened(false);
            applyExternalAgency({ external_agency_id: id });
          }}
        />
      )}
      {isUnassignAgencyTransitionDialogOpened && (
        <UnassignAgencyTransitionDialog
          isOpened={isUnassignAgencyTransitionDialogOpened}
          onClose={() => setIsUnassignAgencyTransitionDialogOpened(false)}
          onSubmit={id => {
            setIsUnassignAgencyTransitionDialogOpened(false);
            applyExternalAgency({ external_agency_id: id });
          }}
        />
      )}
      {isSellLoanTransitionDialogOpened && (
        <SellLoanTransitionDialog
          isOpened={isSellLoanTransitionDialogOpened}
          onClose={() => setIsSellLoanTransitionDialogOpened(false)}
          onSubmit={({ id, sell_date, sell_price }) => {
            setIsSellLoanTransitionDialogOpened(false);
            applyTransition('sold', { external_agency_id: id, sell_date, sell_price });
          }}
        />
      )}
      {isDefaultLoanTransitionDialogOpened && (
        <TransitionConfirmationDialog
          title="Make loan defaulted"
          message="Are you sure want to make this loan defaulted?"
          onClose={() => setIsDefaultLoanTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsDefaultLoanTransitionDialogOpened(false);
            applyTransition('default');
          }}
        />
      )}
      {isResetLoanTransitionDialogOpened && (
        <TransitionConfirmationDialog
          title="Reset loan"
          message="Are you sure want to reset this loan?"
          onClose={() => setIsResetLoanTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsResetLoanTransitionDialogOpened(false);
            applyTransition('reset');
          }}
        />
      )}
      {isActivateClosedLoanTransitionDialogOpened && (
        <TransitionConfirmationDialog
          title="Activate loan"
          message="Are you sure want to activate this loan?"
          onClose={() => setIsActivateClosedLoanTransitionDialogOpened(false)}
          onConfirm={() => {
            setIsActivateClosedLoanTransitionDialogOpened(false);
            applyTransition('activate');
          }}
        />
      )}
      {isUnassignLoanTransitionDialogOpened && (
        <TransitionConfirmationDialog
          title="Unassign collector"
          message="Are you sure want to unassign collector?"
          onClose={() => setIsUnassignLoanTransitionDialogOpened(false)}
          onConfirm={() => {
            const transition = transitions.includes('unassign_collector')
              ? 'unassign_collector'
              : 'unassign_defaulted_collector';
            setIsUnassignLoanTransitionDialogOpened(false);
            applyTransition(transition);
          }}
        />
      )}
    </>
  );
};

LoanTransitions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    user_id: PropTypes.number,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
    external_agency_id: PropTypes.number,
    collection_group: PropTypes.string,
    assigned_collector: PropTypes.string,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
};

export default LoanTransitions;
