import React, { cloneElement, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  useNotify,
  useDataProvider,
} from 'react-admin';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TableFooter,
  TextField,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Tabs,
  Tab,
  FormHelperText,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatCurrency, formatDatetime, formatMoneyNoCurrency } from '../../../utils/formatter';
import TransactionType from '../../field/TransactionType';
import getAdminTimezone from '../../../utils/getAdminTimezone';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { CURRENCY } from '../../../constants';
import { removeErrors } from '../../../utils/validator';

const useStyles = makeStyles(() => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  cellWidth: {
    width: '90px',
  },
  formControl: {
    width: '100%',
  },
  tableWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflow: 'auto',
  },
  table: { position: 'relative', borderCollapse: 'separate' },
  dialogTitle: {
    marginBottom: '16px',
  },
  bold: {
    fontWeight: 600,
  },
  leftContent: {
    width: '23%',
    flexGrow: 1,
    paddingRight: '20px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    minWidth: '180px',
  },
  stickyFooter: {
    position: 'sticky',
    bottom: '0',
    backgroundColor: '#fff',
  },
  stickyCell: {
    borderTop: '1px solid #e0e0e0',
  },
}));

const validateField = (value, fieldName, isNull = false, isNumber = true) => {
  if (!isNull && (value === undefined || value === null)) {
    return `${fieldName} can't be empty`;
  }

  if (isNumber && value < 0) {
    return `${fieldName} can't be negative`;
  }

  return null;
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ScheduledTransactionsList = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({ is_new_client: false });
  const [loading, setLoading] = useState(false);
  const [isProductsLoading, seIsProductsLoading] = useState(false);
  const [productsCodes, setProductsCodes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    seIsProductsLoading(true);

    dataProvider.query('product-config?page=1&items_per_page=100', { method: 'GET' }).then(({ data }) => {
      const uniqueArray = [...new Set(data.map(item => item.product_code))];
      setProductsCodes(uniqueArray);
      seIsProductsLoading(false);
    });
  }, [dataProvider]);

  const removeError = (...fields) => {
    setErrors(removeErrors(errors, ...fields));
  };

  const validate = () => {
    const { disbursed_at, tenor, principal, not_paid_principal, product_code } = state;
    const formErrors = {};

    const productCodeError = validateField(product_code, 'Product code', false, true);
    if (productCodeError) {
      formErrors.product_code = productCodeError;
    }

    if (!disbursed_at) {
      formErrors.disbursed_at = 'Disbursement date is required';
    }

    const tenorError = validateField(tenor, 'Tenor');
    if (tenorError) {
      formErrors.tenor = tenorError;
    }

    const principalError = validateField(principal, 'Principal');
    if (principalError) {
      formErrors.principal = principalError;
    }

    const notPaidPrincipalError = validateField(not_paid_principal, 'Not paid principal', true);
    if (notPaidPrincipalError) {
      formErrors.not_paid_principal = notPaidPrincipalError;
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);
    setLoading(false);

    return isValid;
  };

  const handleChangeValue = (fieldName, value) => {
    removeError(fieldName);
    setState(prevState => ({ ...prevState, [fieldName]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      const dateTimeTimezones = formatInTimeZone(state.disbursed_at, getAdminTimezone(), "yyyy-MM-dd'T'HH:mm:ssXXX");
      const payload = {
        ...state,
        disbursed_at: dateTimeTimezones,
        tenor: +state.tenor,
        principal: +state.principal,
        not_paid_principal: +state.principal,
      };

      dataProvider
        .post('products-get-schedule', { data: payload, removeTrailingSlash: true })
        .then(({ data }) => {
          setTabs(prevTabs => [...prevTabs, data.data]);
          setTabIndex(tabs.length);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  const sumAmount = useCallback(tabTransations => {
    if (tabTransations) {
      const sum = tabTransations.reduce((acc, curr) => acc + curr.total_amount, 0);
      return formatCurrency(sum);
    }
  }, []);

  const displayDate = state.disbursed_at ? utcToZonedTime(state.disbursed_at, getAdminTimezone()) : null;

  return (
    <div style={{ display: 'flex', gap: '20px', paddingRight: 0, overflow: 'hidden', height: '100%' }}>
      <form className={classes.leftContent}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value={state.is_new_client}
                  onChange={e => handleChangeValue('is_new_client', e.target.checked)}
                />
              }
              label="Is client new?"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel> {isProductsLoading ? <CircularProgress size={15} /> : 'Product code'}</InputLabel>
              <Select
                fullWidth
                value={state.product_code}
                error={!!errors.product_code}
                disabled={isProductsLoading}
                onChange={event => handleChangeValue('product_code', event.target.value)}>
                {productsCodes?.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {errors.product_code && <FormHelperText error>{errors.product_code}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              fullWidth
              label="Disbursement date"
              value={displayDate}
              error={!!errors.disbursed_at}
              helperText={errors.disbursed_at}
              onChange={value => handleChangeValue('disbursed_at', zonedTimeToUtc(value, getAdminTimezone()))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Tenor"
              value={state.tenor ?? 0}
              type="number"
              error={!!errors.tenor}
              helperText={errors.tenor}
              onChange={event => handleChangeValue('tenor', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Principal"
              type="number"
              error={!!errors.principal}
              helperText={errors.principal}
              value={state.principal ?? 0}
              onChange={event => handleChangeValue('principal', event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Not paid principle"
              type="number"
              error={!!errors.not_paid_principal}
              helperText={errors.not_paid_principal}
              value={state.not_paid_principal ?? 0}
              onChange={event => handleChangeValue('not_paid_principal', event.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          style={{ marginTop: '16px' }}
          disabled={isProductsLoading || loading}>
          {loading ? 'Calculating...' : 'Calculate'}
        </Button>
      </form>
      <div className={classes.tableWrapper}>
        {loading ? (
          <CircularProgress size={50} />
        ) : tabs.length ? (
          <div>
            <Tabs value={tabIndex} onChange={(e, newIndex) => setTabIndex(newIndex)} variant="scrollable">
              {tabs.map(tab => (
                <Tab label={`${tab.product_code}: ${tab.principal} ${CURRENCY}/${tab.tenor}`} key={tab} />
              ))}
            </Tabs>
            {tabs.map((tab, index) => (
              <Box
                key={tab.product_code}
                sx={{
                  display: tabIndex !== index ? 'none' : 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  marginTop: '20px',
                }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 16, marginBottom: '24px', flexWrap: 'wrap' }}>
                  <Typography variant="subtitle2">
                    Product - <span className={classes.bold}>{tab.product_code}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Disbursement date - <span className={classes.bold}>{formatDatetime(tab.disbursed_at)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Tenor -{' '}
                    <span className={classes.bold}>
                      {tab.tenor} {tab.tenor > 1 ? 'days' : 'day'}
                    </span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Principal - {CURRENCY} <span className={classes.bold}>{formatMoneyNoCurrency(tab.principal)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Maturity date - <span className={classes.bold}>{formatDatetime(tab.maturity_date)}</span>
                  </Typography>
                  <Typography variant="subtitle2">
                    Maturity date with holidays -{' '}
                    <span className={classes.bold}>{formatDatetime(tab.maturity_date_with_holidays)}</span>
                  </Typography>
                </Box>
                <Typography variant="h6" gutterBottom={true} className={classes.dialogTitle}>
                  Loan simulator
                </Typography>
                <Divider />
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ maxWidth: '30px' }}>Loan day</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Accrued amount, {CURRENCY}</TableCell>
                      <TableCell>IGV, {CURRENCY}</TableCell>
                      <TableCell align="right">Total amount, {CURRENCY} </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tab.transactions.map((row, id) => {
                      return (
                        <TableRow key={id}>
                          <TableCell>{row.day_number}</TableCell>
                          <TableCell>{formatDatetime(row.scheduled_at)}</TableCell>
                          <TableCell>
                            <TransactionType type={row.type} />
                          </TableCell>
                          <TableCell>{formatMoneyNoCurrency(row.repaid_accrued_amount)}</TableCell>
                          <TableCell>{formatMoneyNoCurrency(row.igv_amount)}</TableCell>
                          <TableCell align="right">{formatMoneyNoCurrency(row.total_amount)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter className={classes.stickyFooter}>
                    <TableRow>
                      <TableCell style={{ maxWidth: '30px' }} className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell className={classes.stickyCell}></TableCell>
                      <TableCell align="right" className={classes.stickyCell}>
                        Total: {sumAmount(tab.transactions)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            ))}
          </div>
        ) : (
          <Typography variant="subtitle1">Fill in values to calculate</Typography>
        )}
      </div>
    </div>
  );
};

export default ScheduledTransactionsList;
