import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  BooleanField,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  TextField,
} from 'react-admin';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cellWidth: {
    width: '90px',
  },
  toolbar: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  briefCell: {
    minWidth: '500px',
  },
}));

const ListActions = ({ filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  const classes = useStyles();

  return (
    <TopToolbar className={classes.toolbar} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  filters: PropTypes.element,
};

const ProductList = props => {
  const classes = useStyles();

  const getRowStyle = record => {
    if (!record.product_is_actual) {
      return { backgroundColor: grey[100] };
    }
  };

  return (
    <>
      <List
        pagination={false}
        bulkActionButtons={false}
        sort={{ field: '', order: '' }}
        // filters={<ListFilter />}
        filter={{}}
        actions={<ListActions />}
        {...props}>
        <Datagrid rowClick="show" rowStyle={getRowStyle}>
          <TextField source="id" />
          <TextField source="product_code" />
          <TextField source="product_name" />
          <TextField source="strategy_name" />
          <TextField source="product_state" />
          <BooleanField source="is_product_valid" />
          <TextField source="product_description" />
          <TextField source="product_config_brief_info" cellClassName={classes.briefCell} />
        </Datagrid>
      </List>
    </>
  );
};

export default ProductList;
