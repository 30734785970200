/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DraggableDialog } from '../../../dialog/DraggableDialog';
import { formatCurrency } from '../../../../utils/formatter';
import {
  Chip,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  DialogContentText,
  Divider,
  Box,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

import { useDataProvider } from 'react-admin';
import { CURRENCY } from '../../../../constants';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  chip: {
    minWidth: '70px',
    flexDirection: 'row-reverse',
    '& .MuiChip-icon': {
      marginLeft: 0,
      fontSize: '18px',
    },
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: '6px',
    },
  },
}));

const ApproveTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const [approvedPrincipal, setApprovedPrincipal] = useState(0);
  const [allApprovedTenors, setAllApprovedTenors] = useState([]);
  const [tenors, setTenors] = useState([]);
  const [allowedPrincipals, setAllowedPrincipals] = useState([]);
  const [errors, setErrors] = useState({});

  const { id, sls_auto_approved_principal, sls_auto_approved_tenor, state, requested_principal, requested_tenor } =
    record;

  const classes = useStyles();
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (state === 'waiting_manual_decision') {
      dataProvider.query(`application/${id}/approve-options`, {}).then(({ data }) => {
        const {
          allowed_principals: { min, max, step },
          default_principal,
          allowed_tenors,
        } = data;
        const principalsOptions = [];
        for (let i = min; i <= max; i += step) {
          principalsOptions.push(i);
        }
        setAllowedPrincipals(principalsOptions);
        setApprovedPrincipal(default_principal);
        setAllApprovedTenors(allowed_tenors);
      });
    }
  }, [dataProvider, id]);

  const validate = () => {
    const formErrors = {};

    if (!tenors.length) {
      formErrors.tenors = `Please, choose any tenor`;
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validate() && state === 'waiting_manual_decision') {
      const payload = {
        approved_principal: +approvedPrincipal,
        max_approved_principal: +approvedPrincipal,
        min_approved_tenor: tenors[0],
        max_approved_tenor: tenors[0],
      };

      onSubmit(payload);
    } else if (state !== 'waiting_manual_decision') {
      onSubmit();
    }
  };

  const handleClick = name => {
    if (errors.tenors) {
      removeError('tenors');
    }

    setTenors([name]);
  };

  return (
    <DraggableDialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">Approve</DialogTitle>
      <DialogContent>
        {state === 'waiting_manual_decision' ? (
          <>
            <Typography color="#786e6e">
              User requested {formatCurrency(requested_principal)} for {requested_tenor} days
            </Typography>
            <Divider style={{ marginBlock: '20px' }} />

            <DialogContentText color="#000000de">Approved principal, {CURRENCY}</DialogContentText>
            <Box mb="20px">
              <Box display="flex" justifyContent="flex-start" alignItems="center" gridGap="50px">
                <FormControl className={classes.fullWidth} error={!!errors.principals}>
                  <Select
                    labelId="approved-principal-select-label"
                    value={approvedPrincipal}
                    onChange={e => {
                      if (errors.principals) {
                        removeError('principals');
                      }
                      setApprovedPrincipal(e.target.value);
                    }}>
                    {allowedPrincipals.map(principal => (
                      <MenuItem key={principal} value={principal}>
                        {principal}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {errors.principals && (
                <Typography color="error" style={{ fontSize: '12px' }}>
                  {errors.principals}
                </Typography>
              )}
            </Box>

            <Box display="flex" flexDirection="column">
              <DialogContentText color="#000000de">Tenor</DialogContentText>

              <Box display="flex" alignItems="center" gridGap="15px">
                {allApprovedTenors.map(item => {
                  const isActive = tenors.find(tenor => tenor === item);
                  return (
                    <Chip
                      key={item}
                      label={item}
                      clickable
                      color={isActive ? 'primary' : 'default'}
                      variant="outlined"
                      className={classes.chip}
                      onClick={() => handleClick(item)}
                      icon={isActive && <DoneIcon />}
                    />
                  );
                })}
              </Box>
            </Box>
          </>
        ) : (
          <Typography color="#786e6e">
            Approve application: {formatCurrency(sls_auto_approved_principal)} for {sls_auto_approved_tenor} days ?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Approve
        </Button>
      </DialogActions>
    </DraggableDialog>
  );
};

ApproveTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    id: PropTypes.number,
    sls_auto_approved_principal: PropTypes.number,
    sls_auto_approved_tenor: PropTypes.number,
    product_id: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    product_code: PropTypes.string,
  }),
};

export default ApproveTransitionDialog;
