import React, { useEffect, useState } from 'react';
import ImagesViewer from 'react-images-viewer';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import TablePagination from '@material-ui/core/TablePagination';
import { formatDatetime } from '../../utils/formatter';
import { getFileSrc } from '../../utils/getUserImages';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

const SelfieMatchesTable = ({ userId, refreshedAt, perPageCustom }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageCustom ?? 3);
  const [total, setTotal] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [openSelfie, setOpenSelfie] = useState([]);

  useEffect(() => {
    dataProvider
      .getList('aws_rekognition_matches', {
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
        filter: { user: userId, 'similarity[gte]': 90 },
      })
      .then(({ data, total }) => {
        setMatches(data);
        setLoading(false);
        setTotal(total);
      })
      .catch(error => {
        setLoading(false);
        notify(`Error: ${error.message}`, 'error');
      });
  }, [dataProvider, userId, refreshedAt, page, perPage, notify]);

  const openImageViewer = src => {
    setOpenSelfie([{ src }]);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setOpenSelfie([]);
    setIsViewerOpen(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <div className={classes.fullWidth}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={400}>User id</TableCell>
              <TableCell width={400}>Similar user id</TableCell>
              <TableCell>similarity</TableCell>
              <TableCell>Created at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map(row => {
              const userSelfie = getFileSrc(row.user_selfie.file_name, row.user_selfie.storage_name);
              const similarUserSelfie = getFileSrc(
                row.similar_user_selfie.file_name,
                row.similar_user_selfie.storage_name,
              );
              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <Box className={classes.imageWrapper}>
                      <Link to={`/users/${userId}`} target="_blank">
                        {userId}
                      </Link>
                      <img
                        style={{ cursor: 'pointer', maxWidth: '300px', width: 'fit-content' }}
                        src={userSelfie}
                        onClick={() => openImageViewer(userSelfie)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box className={classes.imageWrapper}>
                      <Link to={`/users/${row.similar_user_id}`} target="_blank">
                        {row.similar_user_id}
                      </Link>
                      <img
                        style={{ cursor: 'pointer', maxWidth: '300px', width: 'fit-content' }}
                        src={similarUserSelfie}
                        onClick={() => openImageViewer(similarUserSelfie)}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={row.similarity} />
                  </TableCell>
                  <TableCell>{formatDatetime(row.created_at)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onPageChange={(e, page) => setPage(page + 1)}
          onRowsPerPageChange={e => {
            setPerPage(parseInt(e.target.value, 10));
            setPage(1);
          }}
        />
      </div>

      {isViewerOpen && <ImagesViewer isOpen={true} onClose={closeImageViewer} imgs={openSelfie} spinnerSize={10} />}
    </>
  );
};

SelfieMatchesTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
  perPageCustom: PropTypes.number,
};

export default SelfieMatchesTable;
