import React, { useState } from 'react';
import { TitleCloseDialog } from '../../dialog/Dialog';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import AccrualTable from './AccrualTable';
import { generatePayload, inputDecimalHelper, transformObject } from './utils';
import PropTypes from 'prop-types';
import { CURRENCY } from '../../../constants';
import { useDataProvider, useNotify } from 'react-admin';
import snakeToHuman from '../../../utils/snakeToHuman';

const EditProductDialog = ({ pickedField, open, productId, record, onClose, onSave }) => {
  const defaultValue = pickedField.field.value ?? pickedField.field;
  const [state, setState] = useState(defaultValue);
  const [tableCellValue, setTableCellValue] = useState(pickedField.field.value);
  const [loading, setLoading] = useState(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleTableCellChange = cellValue => {
    setTableCellValue(cellValue);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const transformedRecord = transformObject(record.product_config);

    const endpoint = `product-config/${productId}/${pickedField.suffix}`;

    const formData = new FormData(e.currentTarget);
    const formDataLength = [...formData.keys()].length;
    const formDataDetails = Object.fromEntries(formData);

    const payload = generatePayload(
      pickedField,
      transformedRecord,
      record,
      formDataLength,
      formDataDetails,
      state,
      tableCellValue,
    );

    dataProvider
      .query(endpoint, { method: 'PATCH', body: JSON.stringify(payload) })
      .then(() => {
        notify(`Product ${pickedField.suffix} was successfully changed`, 'success');
        setLoading(false);
        onSave();
      })
      .catch(e => {
        setLoading(false);
        notify(`Error: ${e.message}`, 'error');
      });
  };

  return (
    <TitleCloseDialog
      open={open}
      title={`Edit ${pickedField.field.title ?? pickedField.key} `}
      fullWidth
      close={onClose}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          {!pickedField.field.type && (
            <TextField
              variant="filled"
              label={snakeToHuman(pickedField.key)}
              value={state}
              name={pickedField.key}
              onChange={e => setState(e.target.value)}
              fullWidth
            />
          )}
          {pickedField.field.type === 'string' && (
            <TextField
              variant="filled"
              label={pickedField.field.title}
              value={state}
              name={pickedField.key}
              onChange={e => setState(e.target.value)}
              fullWidth
            />
          )}
          {pickedField.field.type === 'integer_range' && (
            <>
              {Object.keys(state).map(item => {
                const label = item.charAt(0).toUpperCase() + item.slice(1);
                return (
                  <TextField
                    key={item}
                    variant="filled"
                    label={label}
                    value={state[item]}
                    fullWidth
                    name={item}
                    type="number"
                    onChange={e => setState(prevState => ({ ...prevState, [item]: e.target.value }))}
                  />
                );
              })}
            </>
          )}
          {pickedField.field.type === 'integer_set' && (
            <TextField
              variant="filled"
              label={pickedField.field.title}
              value={state}
              name={pickedField.key}
              helperText="Press 'space' to add comma"
              onChange={e => {
                const value = e.target.value.replace(/[^0-9., ]/g, '');
                const values = value.split(' ');
                setState(values.join(','));
              }}
              fullWidth
            />
          )}
          {pickedField.field.type === 'integer' && (
            <TextField
              variant="filled"
              label={pickedField.field.title}
              value={state}
              name={pickedField.key}
              type="number"
              fullWidth
              onChange={e => {
                setState(e.target.value);
              }}
            />
          )}
          {pickedField.field.type === 'percent' && (
            <TextField
              variant="filled"
              label={pickedField.field.title}
              value={state}
              name={pickedField.key}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              fullWidth
              onChange={e => {
                setState(e.target.value);
              }}
              onBlur={e => setState((+e.target.value).toFixed(inputDecimalHelper(e.target.value)))}
            />
          )}
          {pickedField.field.type === 'amount' && (
            <TextField
              variant="filled"
              label={pickedField.field.title}
              value={state}
              name={pickedField.key}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">{CURRENCY}</InputAdornment>,
              }}
              fullWidth
              onChange={e => {
                setState(e.target.value);
              }}
              onBlur={e => setState((+e.target.value).toFixed(inputDecimalHelper(e.target.value)))}
            />
          )}
          {pickedField.field.type === 'boolean' && (
            <FormControlLabel
              control={<Checkbox color="primary" />}
              name={pickedField.key}
              label={pickedField.field.title}
              checked={state}
              onChange={e => {
                setState(e.target.checked);
              }}
            />
          )}
          {pickedField.field.type === 'accrual_matrix' && (
            <AccrualTable
              cols={Object.keys(state)}
              rows={Object.keys(state[Object.keys(state)[0]])}
              readonly={false}
              value={state}
              onChange={handleTableCellChange}
            />
          )}
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" disabled={loading}>
              {loading ? <CircularProgress size={20} /> : 'Confirm'}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </TitleCloseDialog>
  );
};

EditProductDialog.propTypes = {
  pickedField: PropTypes.shape({
    field: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        title: PropTypes.string,
      }),
    ]),
    suffix: PropTypes.string,
    key: PropTypes.string,
  }),
  productId: PropTypes.number,
  open: PropTypes.bool,
  record: PropTypes.PropTypes.shape({ product_config: PropTypes.any }),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default EditProductDialog;
