import React from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { IconButton, Link } from '@material-ui/core';

// TODO: Replace redirecting link with this component
const RedirectIconLink = ({ link, label }) => {
  return (
    <Link href={link} underline="none" target="_blank" rel="noreferrer">
      {label}
      <IconButton onClick={e => e.stopPropagation()}>
        <OpenInNewIcon fontSize="small" />
      </IconButton>
    </Link>
  );
};

RedirectIconLink.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
};

export default RedirectIconLink;
