import React from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import AccrualTable from './AccrualTable';
import { CURRENCY } from '../../../constants';

export const inputDecimalHelper = value => {
  const stringedValue = value.toString();

  const decimalSeparatorIndex = stringedValue.indexOf('.');

  if (decimalSeparatorIndex === -1) {
    return 1;
  }

  const decimalChars = stringedValue.length - decimalSeparatorIndex - 1;

  return decimalChars;
};

export const transformObject = obj => {
  const result = {};

  for (const key in obj) {
    const item = obj[key];

    if ('value' in item) {
      result[key] = item.value;
    } else if ('properties' in item) {
      result[key] = {};
      for (const subKey in item.properties) {
        result[key][subKey] = item.properties[subKey].value;
      }
    }
  }

  return result;
};

export const convertValuesToInt = data => Object.fromEntries(Object.entries(data).map(([key, value]) => [key, +value]));

const updateGroup = (record, group, updatedValue) => {
  return {
    ...record,
    [group]: { ...record[group], ...updatedValue },
  };
};

const updateField = (record, key, updatedValue) => {
  return {
    ...record,
    [key]: updatedValue,
  };
};

export const renderValue = (value, type, title) => {
  if (type === 'integer_range') {
    const { min, max, step } = value;

    let minStr;
    let maxStr;
    if (title === 'All principals' || title === 'Customer principals') {
      minStr = `${min} ${CURRENCY}`;
      maxStr = `${max} ${CURRENCY}`;
    } else {
      minStr = min === 1 ? `${min} day` : `${min} days`;
      maxStr = `${max} days`;
    }

    return (
      <Typography variant="subtitle2">
        Min - <span style={{ fontWeight: 600 }}>{minStr}</span>; Max - <span style={{ fontWeight: 600 }}>{maxStr}</span>
        ; Step - <span style={{ fontWeight: 600 }}>{step}</span>
      </Typography>
    );
  } else if (type === 'integer_set' || type === 'integer' || type === 'decimal') {
    return (
      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
        {value.toString()}
      </Typography>
    );
  } else if (type === 'accrual_matrix') {
    const cols = Object.keys(value);
    const rows = Object.keys(value[cols[0]]);

    const table = <AccrualTable rows={rows} cols={cols} value={value} />;
    const tableCopyText = [
      [''].concat(cols).join('\t'),
      ...rows.map(row => [row, ...cols.map(col => value[col][row])].join('\t')),
    ].join('\n');

    return { table, tableCopyText };
  } else if (type === 'percent') {
    return (
      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
        {value.toFixed(1)}%
      </Typography>
    );
  } else if (type === 'boolean') {
    return <Checkbox checked={value} disabled style={{ padding: 0 }} />;
  } else if (type === 'amount') {
    return (
      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
        {value} {CURRENCY}
      </Typography>
    );
  } else {
    return <Typography variant="subtitle2">{value}</Typography>;
  }
};

export const generatePayload = (
  modifiedField,
  transformedRecord,
  record,
  formDataLength,
  formDataDetails,
  state,
  tableCellValue,
) => {
  if (modifiedField.group) {
    if (modifiedField.field.type === 'boolean') {
      return updateGroup(transformedRecord, modifiedField.group, { [modifiedField.key]: state });
    } else if (modifiedField.field.type === 'percent') {
      const convertedValues = convertValuesToInt(formDataDetails);
      return updateGroup(transformedRecord, modifiedField.group, convertedValues);
    } else if (modifiedField.field.type === 'integer_range') {
      const convertedValues = convertValuesToInt(formDataDetails);
      return updateGroup(transformedRecord, modifiedField.group, { [modifiedField.key]: convertedValues });
    } else if (modifiedField.field.type === 'accrual_matrix') {
      return updateGroup(transformedRecord, modifiedField.group, { [modifiedField.key]: tableCellValue });
    } else {
      return updateGroup(transformedRecord, modifiedField.group, { [modifiedField.key]: +state });
    }
  } else if (modifiedField.field.type === 'integer_set') {
    const valuesArray = Array.isArray(state) ? state : state.split(',');
    const transformedArray = valuesArray.filter(item => item !== '').map(item => +item);

    return updateField(transformedRecord, modifiedField.key, transformedArray);
  } else if (modifiedField.field.type === 'integer') {
    return updateField(transformedRecord, modifiedField.key, +state);
  } else if (modifiedField.field.type === 'integer_range') {
    const convertedValues = convertValuesToInt(formDataDetails);
    return updateField(transformedRecord, modifiedField.key, convertedValues);
  } else {
    return formDataLength > 1
      ? updateField(transformedRecord, modifiedField.key, formDataDetails)
      : modifiedField.suffix === 'data'
        ? { product_name: record.product_name, product_description: record.product_description, ...formDataDetails }
        : { ...transformedRecord, ...formDataDetails };
  }
};

const highlightElement = element => {
  if (element) {
    element.focus();

    setTimeout(() => {
      element.select();
    }, 0);
  }
};

export const navigateByArrows = (key, rowId, rowsLength, colId, colsLength, getElementCallback) => {
  switch (key) {
    case 'ArrowRight':
      if (colId < colsLength) {
        const element = getElementCallback(rowId, colId + 1);
        highlightElement(element);
      }
      break;
    case 'ArrowLeft':
      if (colId > 0) {
        const element = getElementCallback(rowId, colId - 1);
        highlightElement(element);
      }
      break;
    case 'ArrowUp':
      if (rowId > 0) {
        const element = getElementCallback(rowId - 1, colId);
        highlightElement(element);
      }
      break;
    case 'ArrowDown':
      if (rowId < rowsLength) {
        const element = getElementCallback(rowId + 1, colId);
        highlightElement(element);
      }
      break;
  }
};
