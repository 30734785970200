import { isValid, differenceInYears } from 'date-fns';

import getAdminTimezone from './getAdminTimezone';
import { CURRENCY, PHONE_COUNTRY_CODE } from '../constants';

const numberFormat = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: CURRENCY,
});

const numberFormatNarrow = new Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: CURRENCY,
  currencyDisplay: 'narrowSymbol',
});

const numberFormatWithoutCurrency = new Intl.NumberFormat(navigator.language, {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const dateFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: getAdminTimezone(),
  });

const datetimeFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: getAdminTimezone(),
  });

const timeFormat = () =>
  new Intl.DateTimeFormat(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: getAdminTimezone(),
  });

export const formatCurrency = (value, narrowSymbol = false, defaultValue = '---') => {
  if (typeof value === 'number') {
    return narrowSymbol ? numberFormatNarrow.format(value) : numberFormat.format(value);
  }

  return defaultValue;
};

export const formatMoneyNoCurrency = value => {
  if (isNaN(+value)) return value;

  return numberFormatWithoutCurrency.format(+value);
};

export const formatDate = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return dateFormat().format(new Date(value));
};

export const formatDatetime = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return datetimeFormat().format(new Date(value));
};

export const formatTime = (value, defaultValue = '---') => {
  if (!value) {
    return defaultValue;
  }

  const date = new Date(value);

  if (isValid(date) === false) {
    return defaultValue;
  }

  return timeFormat().format(new Date(value));
};

export const formatPhoneNumber = (value, masked = false, defaultValue = '---') => {
  let number = value;

  if (!number) {
    return defaultValue;
  }

  if (typeof number !== 'string' || number.length === 0) {
    return defaultValue;
  }
  if (number.startsWith(PHONE_COUNTRY_CODE)) {
    number = number.replace(PHONE_COUNTRY_CODE, '');
  }
  if (number.length >= 7) {
    return `${PHONE_COUNTRY_CODE} ${number.slice(0, 3)} ${masked ? '***' : number.slice(3, 6)} ${number.slice(6)}`;
  } else if (number.length >= 4) {
    return `${PHONE_COUNTRY_CODE} ${number.slice(0, 3)} ${number.slice(3)}`;
  } else {
    return `${PHONE_COUNTRY_CODE} ${number}`;
  }
};

export const calculateAge = birthDate => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  return differenceInYears(today, birthDateObj);
};

export const nameTransform = name => {
  if (name) {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  } else {
    return null;
  }
};

export const convertToISOString = (dateString, setEndOfDay) => {
  const [day, month, year] = dateString.split('.').map(Number);
  const date = new Date(year, month - 1, day);
  setEndOfDay && date.setHours(23, 59, 59, 0);
  const pad = num => num.toString().padStart(2, '0');
  const isoString = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}.000Z`;
  return isoString;
};

export const getToday = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = now.getFullYear();
  return `${day}.${month}.${year}`;
};

export const convertToShortString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const formatAddressToUrl = (address, map = true) => {
  const encodedAddress = encodeURIComponent(address);

  return map ? `https://www.google.com/maps?q=${encodedAddress}` : encodedAddress;
};

export const formatBankAccountNumber = accountNumber => {
  return !accountNumber
    ? null
    : `${accountNumber.slice(0, 3)}-${accountNumber.slice(3, 6)}-${accountNumber.slice(6, 18)}-${accountNumber.slice(18)}`;
};

export const camelCaseToHuman = string => {
  if (!string) {
    return '';
  }
  return string
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .replace(/^./, match => match.toUpperCase());
};

export const captitalizeFirstChar = string => {
  if (!string) {
    return;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};
