import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

export function useProductList() {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider
      .query('application/product-list', { method: 'GET' })
      .then(({ data }) => {
        setProducts(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, [dataProvider]);

  return {
    products,
    error,
    isLoading,
  };
}
