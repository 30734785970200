import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import IncomesList from './IncomesList';
import CollectionScoreTable from '../../../collection/CollectionScoreTable';
import DebounceButton from '../../../button/DebounceButton';
import CollectionCallTable from '../../../collection_call/CollectionCallTable';
import CollectionCallFormDialog from '../../../dialog/CollectionCallFormDialog';
import { UserContactsTable } from '../../../tables';
import { AddUserContactsDialog } from '../../../dialog';
import ShortLinkTable from '../../../short_link/ShortLinkTable';
import CollectionAssignTable from '../../../collection/CollectionAssignTable';
import ExternalAgencyAssignTable from '../../../collection/ExternalAgencyAssignTable';
import MatchesTable from '../../../matches/MatchesTable';
import AllCallsTable from '../../../call/AllCallsTable';
import WazzupMessagesTable from '../../../wazzup/WazzupMessagesTable';
import DirectDebitTable from '../../../direct_debit/DirectDebitTable';
import AddNotificationDialog from '../../../notification/AddNotificationDialog';
import NotificationTable from '../../../notification/NotificationTable';
import AddIncomeDialog from '../../../income/AddIncomeDialog';
import AddNoteDialog from '../../../note/AddNoteDialog';
import NoteTable from '../../../note/NoteTable';
import AgreementTable from '../../../agreement/AgreementTable';
import MoneyTransferTable from '../../../money_transfer/MoneyTransferTable';
import ExtensionTable from '../../../extension/ExtensionTable';
import ChangeHistoryTable from '../../../change_history/ChangeHistoryTable';
import TabPanel from '../../../hoc/TabPanel';
import AddExtensionDialog from '../../../extension/AddExtensionDialog';
import AccountMessageTable from '../../../account_message/AccountMessageTable';
import AddAccountMessageDialog from '../../../account_message/AddAccountMessageDialog';
import DiscountOfferTable from '../../discount_offer/DiscountOfferTable';

const LoanAdditionalInfo = ({ permissions, record, refreshedAt, refresh }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddNotificationDialogOpened, setIsAddNotificationDialogOpened] = useState(false);
  const [isAddIncomeDialogOpened, setIsAddIncomeDialogOpened] = useState(false);
  const [isAddNoteDialogOpened, setIsAddNoteDialogOpened] = useState(false);
  const [isAddExtensionDialog, setIsAddExtensionDialog] = useState(false);
  const [isCollectionCallDialogOpened, setIsCollectionCallDialogOpened] = useState(false);
  const [isUserContactsDialogOpened, setIsUserContactsDialogOpened] = useState(false);
  const [matches, setMatches] = useState(0);
  const [clickToCallPhone, setClickToCallPhone] = useState(null);
  const [isAccountMessageDialogOpened, setIsAccountMessageDialogOpened] = useState(false);
  const [counters, setCounters] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const hasDNDTag = record.user_tags
    ? record.user_tags.length > 0 && record.user_tags.find(tag => tag.name.includes('not disturb'))
    : null;
  const hasCollectionAgencyAssigned = record.external_agency_id !== null && record.state !== 'sold';
  const soldLoan = record.state === 'sold' && record.sold_at !== null && record.sell_price !== null;
  const canNotificationsView = permissions?.includes('CAN_NOTIFICATION_VIEW');
  const canIncomesView = permissions?.includes('CAN_INCOME_VIEW');
  const canIncomesEdit = permissions?.includes('CAN_INCOME_EDIT');
  const canExtensionsView = permissions?.includes('CAN_EXTENSION_VIEW');
  const canCollectionCallsView = permissions?.includes('CAN_COLLECTION_CALL_VIEW');
  const canNoteEdit = permissions?.includes('CAN_NOTE_EDIT');
  const canPhoneBookEdit = permissions?.includes('CAN_PHONE_BOOK_EDIT');
  const canPhoneBookView = permissions?.includes('CAN_PHONE_BOOK_VIEW');
  const canNotificationEdit = permissions?.includes('CAN_NOTIFICATION_EDIT');
  const canLoanView = permissions?.includes('CAN_LOAN_VIEW');
  const canPhoneCallView = permissions?.includes('CAN_PHONE_CALL_VIEW');

  const showWarningBlock = () =>
    hasDNDTag || hasCollectionAgencyAssigned || soldLoan ? (
      <Alert variant="filled" severity="error">
        {hasDNDTag && <Typography>Note that the client has the DO NOT DISTURB tag!</Typography>}
        {soldLoan && <Typography>Note that the loan was sold!</Typography>}
        {hasCollectionAgencyAssigned && (
          <Typography>Note that the loan was transferred to a collection agency!</Typography>
        )}
      </Alert>
    ) : null;

  useEffect(() => {
    if (record.id) {
      dataProvider
        .query(`loans/${record.id}/relation_counters`, { method: 'GET' })
        .then(({ data }) => setCounters(data))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }

    if (permissions?.includes('CAN_MATCHES_VIEW') && record.application_id) {
      dataProvider
        .query(`applications/${record.application_id}/user-field-matches`, {})
        .then(({ data }) => setMatches(data.length))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('opened_action_modal') === '1') {
      setIsCollectionCallDialogOpened(true);
    }
  }, [location]);

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider
      .query(`notifications/${notificationId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider
      .query(`agreements/${agreementId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyMoneyTransferTransition = (moneyTransferId, name, params = {}) => {
    dataProvider
      .query(`money_transfers/${moneyTransferId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyIncomeTransition = (incomeId, name, params = {}) => {
    dataProvider
      .query(`incomes/${incomeId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyExtensionTransition = (extensionId, name, params = {}) => {
    dataProvider
      .query(`extensions/${extensionId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const requestExtension = days => {
    setIsLoading(true);
    dataProvider
      .query(`loans/${record.id}/extensions`, { method: 'POST', body: JSON.stringify({ tenor: days }) })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleCloseCollectionCallDialog = () => {
    setIsCollectionCallDialogOpened(false);
    setClickToCallPhone(null);
  };

  const handleSubmitCollectionCallDialog = () => {
    refresh();
    setIsCollectionCallDialogOpened(false);
    setClickToCallPhone(null);
  };

  const onCallHandler = phone => {
    setIsCollectionCallDialogOpened(true);
    setClickToCallPhone(phone);
  };

  const onAddNotification = (transmitterId, destination, templateId, message, subject) => {
    setIsAddNotificationDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/notifications`, {
        body: JSON.stringify({
          transmitter_id: transmitterId,
          destination,
          template_id: templateId,
          message,
          subject,
        }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onAddNote = (label, message) => {
    setIsAddNoteDialogOpened(false);
    dataProvider
      .create('notes', {
        data: {
          user_id: record.user_id,
          application_id: record.application_id,
          loan_id: record.id,
          label,
          message,
        },
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onAddIncome = (amount, receivedAt, paidVia, paymentType) => {
    setIsAddIncomeDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/incomes`, {
        body: JSON.stringify({
          amount,
          received_at: receivedAt,
          bank_name: paidVia,
          payment_type: paymentType,
        }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onAddUserContact = (phone_number, name, type, note, relation, source, status, validation_state) => {
    setIsUserContactsDialogOpened(false);
    dataProvider
      .create('phone_books', {
        data: {
          user: record.user_id,
          phone_number,
          name,
          type,
          note: note?.trim() || null,
          relation,
          source,
          status: status || 'active',
          validation_state,
        },
      })
      .then(refresh)
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <TabPanel
        labels={[
          '✖',
          `Notifications|${counters?.notifications ?? 0}`,
          `WhatsApp messages|${counters?.whatsapp_messages ?? 0}`,
          `Account messages|${counters?.account_messages ?? 0}`,
          `Actions|${counters?.actions ?? 0}`,
          `Notes|${counters?.notes ?? 0}`,
          `Agreements|${counters?.agreements ?? 0}`,
          `Contacts|${counters?.contacts ?? 0}`,
          `Money transfers|${counters?.money_transfers ?? 0}`,
          `Incomes|${counters?.incomes ?? 0}`,
          `Direct debits|${counters?.direct_debits ?? 0}`,
          `Extensions|${counters?.extensions ?? 0}`,
          `Discounts|${counters?.discount_offers ?? 0}`,
          'Changes',
          `Collection Score|${counters?.collection_scores ?? 0}`,
          `Collection Assign Log|${counters?.collection_assign_logs ?? 0}`,
          `Agency Assign Log|${counters?.agency_assign_logs ?? 0}`,
          `Short links|${counters?.short_links ?? 0}`,
          `Matches|${matches}`,
          `All calls|${counters?.all_calls ?? 0}`,
        ]}
        activeTabIndex={queryParams.get('opened_action_modal') === '1' ? 4 : null}>
        <></>
        {canNotificationsView && (
          <>
            <NotificationTable
              loanId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyNotificationTransition}
              inactive={record.state === 'sold'}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {canNotificationEdit && (
                  <Button onClick={() => setIsAddNotificationDialogOpened(true)} disabled={record.state === 'sold'}>
                    Add
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_WAZZUP_MESSAGES_VIEW') && (
          <>
            <WazzupMessagesTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {canNotificationEdit && (
                  <Button disabled={record.state === 'sold'} onClick={() => setIsAddNotificationDialogOpened(true)}>
                    Add
                  </Button>
                )}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_ACCOUNT_MESSAGES_VIEW') ? (
          <>
            <AccountMessageTable
              userId={record.user_id}
              loanId={record.id}
              refreshedAt={refreshedAt}
              onRefresh={refresh}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {permissions.includes('CAN_ACCOUNT_MESSAGES_EDIT') ? (
                  <Button onClick={() => setIsAccountMessageDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {canCollectionCallsView && (
          <>
            {showWarningBlock()}
            <CollectionCallTable
              userId={record.user_id}
              loanId={record.id}
              refreshedAt={refreshedAt}
              permissions={permissions}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {permissions.includes('CAN_COLLECTION_CALL_EDIT') && canPhoneBookView && (
                  <Button onClick={() => setIsCollectionCallDialogOpened(true)}>Add</Button>
                )}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_NOTE_VIEW') && (
          <>
            <NoteTable loanId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {canNoteEdit && <Button onClick={() => setIsAddNoteDialogOpened(true)}>Add</Button>}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_AGREEMENT_VIEW') && (
          <>
            <AgreementTable loanId={record.id} refreshedAt={refreshedAt} onTransition={applyAgreementTransition} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canPhoneBookView && (
          <>
            {showWarningBlock()}
            <UserContactsTable
              userId={record.user_id}
              refreshedAt={refreshedAt}
              setRefreshedAt={refresh}
              onCall={onCallHandler}
              hasSoldLoans={record.state === 'sold'}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh(Date.now())}>Refresh</DebounceButton>
                {canPhoneBookEdit && <Button onClick={() => setIsUserContactsDialogOpened(true)}>Add</Button>}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_MONEY_TRANSFER_VIEW') && (
          <>
            <MoneyTransferTable
              loanId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyMoneyTransferTransition}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canIncomesView && (
          <>
            <IncomesList
              loanId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyIncomeTransition}
              isTransitionsDisabled={!canIncomesEdit || ['active', 'defaulted'].indexOf(record.state) === -1}
              permissions={permissions}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup
                variant="contained"
                size="small"
                color="primary"
                disabled={['active', 'defaulted', 'sold'].indexOf(record.state) === -1}>
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {permissions.includes('CAN_INCOME_ADD') && (
                  <Button onClick={() => setIsAddIncomeDialogOpened(true)}>Add</Button>
                )}
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_DEBITS_VIEW') && (
          <>
            <DirectDebitTable loanId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canExtensionsView && (
          <>
            <ExtensionTable loanId={record.id} refreshedAt={refreshedAt} onTransition={applyExtensionTransition} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
                {permissions.includes('CAN_EXTENSION_ADD') ? (
                  <Button
                    disabled={isLoading || !record.is_extension_request_allowed}
                    onClick={() => setIsAddExtensionDialog(true)}>
                    Request extension
                  </Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </>
        )}
        {canLoanView && (
          <>
            <DiscountOfferTable loanId={record.id} refreshedAt={refreshedAt} onRefresh={refresh} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canLoanView && (
          <>
            <ChangeHistoryTable
              entityId={record.id}
              entityField="loan"
              refreshedAt={refreshedAt}
              endpoint="loan_change_histories"
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_COLLECTION_SCORE_VIEW') && (
          <>
            <CollectionScoreTable loanId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canPhoneCallView && (
          <>
            <CollectionAssignTable loanId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {canPhoneCallView && (
          <>
            <ExternalAgencyAssignTable loanId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_SHORT_LINK_LOG_VIEW') && (
          <ShortLinkTable userId={record.user_id} refreshedAt={refreshedAt} returnSimilar={false} />
        )}
        {permissions.includes('CAN_MATCHES_VIEW') && (
          <>
            <MatchesTable permissions={permissions} recordId={record.application_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
        {permissions.includes('CAN_WEBITEL_CALLS_VIEW') && (
          <>
            <AllCallsTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={() => refresh()}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        )}
      </TabPanel>
      {canNotificationEdit && isAddNotificationDialogOpened && (
        <AddNotificationDialog
          onClose={() => setIsAddNotificationDialogOpened(false)}
          onSubmit={onAddNotification}
          defaultPhoneNumber={record.user_phone_number}
          defaultEmailAddress={record.user_email_address}
        />
      )}
      {canNoteEdit && (
        <AddNoteDialog
          isOpened={isAddNoteDialogOpened}
          onClose={() => setIsAddNoteDialogOpened(false)}
          onSubmit={onAddNote}
        />
      )}
      {canIncomesEdit && (
        <AddIncomeDialog
          record={record}
          isOpened={isAddIncomeDialogOpened}
          onClose={() => setIsAddIncomeDialogOpened(false)}
          onSubmit={onAddIncome}
        />
      )}
      {canExtensionsView && (
        <AddExtensionDialog
          extensionTenors={record.allowed_extension_tenors}
          isOpened={isAddExtensionDialog}
          onClose={() => setIsAddExtensionDialog(false)}
          onSubmit={days => {
            setIsAddExtensionDialog(false);
            requestExtension(days);
          }}
        />
      )}
      {canCollectionCallsView && canPhoneBookView && isCollectionCallDialogOpened && (
        <CollectionCallFormDialog
          userId={record.user_id}
          loanId={record.id}
          isOpened={isCollectionCallDialogOpened}
          onClose={handleCloseCollectionCallDialog}
          onSubmit={handleSubmitCollectionCallDialog}
          clickToCallPhone={clickToCallPhone}
        />
      )}
      {canPhoneBookEdit && isUserContactsDialogOpened && (
        <AddUserContactsDialog
          isOpened={isUserContactsDialogOpened}
          permissions={permissions}
          onClose={() => setIsUserContactsDialogOpened(false)}
          onSubmit={onAddUserContact}
        />
      )}
      {permissions.includes('CAN_ACCOUNT_MESSAGES_EDIT') && isAccountMessageDialogOpened && (
        <AddAccountMessageDialog
          userId={record.user_id}
          onClose={() => setIsAccountMessageDialogOpened(false)}
          onSubmit={() => {
            setIsAccountMessageDialogOpened(false);
            refresh();
          }}
        />
      )}
    </>
  );
};

LoanAdditionalInfo.propTypes = {
  permissions: PropTypes.array,
  record: PropTypes.shape({
    id: PropTypes.number,
    application_id: PropTypes.number,
    allowed_extension_tenors: PropTypes.array,
    state: PropTypes.string,
    is_extension_request_allowed: PropTypes.bool,
    user_id: PropTypes.number,
    user_phone_number: PropTypes.string,
    user_email_address: PropTypes.string,
    product_id: PropTypes.number,
    principal: PropTypes.number,
    tenor: PropTypes.number,
    promo_code: PropTypes.string,
    is_repeat: PropTypes.bool,
    is_ready_for_disbursement: PropTypes.bool,
    user_tags: PropTypes.array,
    external_agency_id: PropTypes.number,
    sold_at: PropTypes.string,
    sell_price: PropTypes.number,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
};

export default LoanAdditionalInfo;
