import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from '../styles';
import { GLOBAL_SETTINGS_OPTIONS_MAP } from '../../settings/options';

const TransferMoneyTransitionDialog = ({ isOpened, onClose, onSubmit }) => {
  const [gateway, setGateway] = useState('');

  const classes = useStyles();

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Money transfer</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, select payment gateway</DialogContentText>
        <FormControl className={classes.fullWidth}>
          <InputLabel id="gateway-select-label">Gateway</InputLabel>
          <Select labelId="gateway-select-label" value={gateway} onChange={e => setGateway(e.target.value)}>
            {GLOBAL_SETTINGS_OPTIONS_MAP.default_auto_money_transfer.options.map(({ name }) => {
              const value = name.toLowerCase();
              return (
                <MenuItem key={name} value={value}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(gateway)} color="primary" disabled={!gateway}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TransferMoneyTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default TransferMoneyTransitionDialog;
