import React from 'react';
import PropTypes from 'prop-types';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Chip from '@material-ui/core/Chip';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import RedirectIconLink from '../../../redirect_icon_link/RedirectIconLink';
import { FunctionField } from 'react-admin';

const useStyles = makeStyles(() => ({
  lastRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  table: {
    marginBottom: '20px',
    tableLayout: 'fixed',
    '& .MuiTableCell-body:has(.expanded-table)': {
      backgroundColor: '#fbfbfb',
    },
  },
  cellWidth: {
    width: '25%',
  },
}));

const ExpandableRow = ({ record }) => {
  const classes = useStyles();

  const getStatus = status => {
    switch (status) {
      case 'failure':
        return <Chip size="small" label={status} icon={<ErrorOutlineIcon />} />;
      case 'success':
        return <Chip size="small" label={status} icon={<DoneAllIcon />} />;
      case 'unknown':
        return <Chip size="small" color="secondary" label={status} icon={<HelpOutlineIcon />} />;
      default:
        return <Chip size="small" label={status} />;
    }
  };

  return (
    <Table size="small" className={[classes.table, 'expanded-table'].join(' ')}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cellWidth}>Money transfer ID</TableCell>
          <TableCell className={classes.cellWidth}>Status</TableCell>
          <TableCell>Loan id</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.money_transfers.map((transfer, id) => {
          const moneyTransferData = {
            id: transfer.money_transfer_id,
          };
          return (
            <TableRow
              key={transfer.money_transfer_id}
              className={record.money_transfers.length - 1 === id ? classes.lastRow : ''}>
              <TableCell>
                <RedirectIconLink
                  link={`#money_transfers?filter=${encodeURIComponent(JSON.stringify(moneyTransferData))}`}
                  label={transfer.money_transfer_id}
                />
              </TableCell>
              <TableCell>
                <FunctionField label="status" render={() => getStatus(transfer.status)} />
              </TableCell>
              <TableCell>
                <RedirectIconLink link={`#loans/${transfer.loan_id}/show`} label={transfer.loan_id} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

ExpandableRow.propTypes = {
  record: PropTypes.shape({
    money_transfers: PropTypes.array,
  }),
};

export default ExpandableRow;
