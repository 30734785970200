import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CircularProgress from '@material-ui/core/CircularProgress';
import PastDueInterestDiscountDialog from '../../../pdi_discount/PastDueInterestDiscountDialog';
import AddExecutedTransactionDialog from '../../../executed_transaction/AddExecutedTransactionDialog';
import { useStyles } from '../styles';
import ColapsibleRow from './ColapsibleRow';
import { CURRENCY } from '../../../../constants';

const cellStyles = {
  borderBottom: '1px solid #cfcfcf',
};

const ExecutedTransactions = ({ record, refreshedAt, refresh, isActionsDisabled }) => {
  const [transactions, setTransactions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddExecutedTransactionDialogOpened, setIsAddExecutedTransactionDialogOpened] = useState(false);
  const [isPDIDiscountDialogOpened, setIsPDIDiscountDialogOpened] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const toggle = id =>
    setSelected(selected.indexOf(id) === -1 ? selected.concat(id) : selected.filter(item => item !== id));

  useEffect(() => {
    setSelected([]);
    dataProvider
      .query(`loans/${record.id}/accruals`, { method: 'GET' })
      .then(({ data }) => {
        setTransactions(data.data);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, record, refreshedAt]);

  const onPdiDiscount = (amount, executedAt) => {
    setIsPDIDiscountDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, {
        body: JSON.stringify({
          type: 'past_due_interest_discount',
          direction: 'in',
          amount,
          executed_at: executedAt,
        }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const onTransactionAdd = (type, direction, amount, executedAt) => {
    setIsAddExecutedTransactionDialogOpened(false);
    dataProvider
      .query(`loans/${record.id}/executed_transactions`, {
        body: JSON.stringify({ type, direction, amount, executed_at: executedAt }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <div style={{ minWidth: '620px' }}>
      <Paper>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" gutterBottom={false} className={classes.paper}>
            Executed accruals
          </Typography>
        </Grid>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
              <TableCell style={cellStyles}>Loan day</TableCell>
              <TableCell style={cellStyles}>DPD</TableCell>
              <TableCell style={cellStyles}>Executed at</TableCell>
              <TableCell style={cellStyles}>Type</TableCell>
              <TableCell style={cellStyles}>Accrued, {CURRENCY}</TableCell>
              <TableCell style={cellStyles}>Paid, {CURRENCY}</TableCell>
              <TableCell style={cellStyles}>Not paid, {CURRENCY}</TableCell>
              <TableCell style={cellStyles}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map(row => (
              <ColapsibleRow
                key={row.id}
                row={row}
                toggle={toggle}
                selected={selected}
                isActionsDisabled={isActionsDisabled}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
      <AddExecutedTransactionDialog
        record={record}
        isOpened={isAddExecutedTransactionDialogOpened}
        onClose={() => setIsAddExecutedTransactionDialogOpened(false)}
        onSubmit={onTransactionAdd}
      />
      {permissions.includes('CAN_DISCOUNT_PDI') && isPDIDiscountDialogOpened && (
        <PastDueInterestDiscountDialog
          onClose={() => setIsPDIDiscountDialogOpened(false)}
          onSubmit={onPdiDiscount}
          selected={selected}
          transactions={transactions}
        />
      )}
    </div>
  );
};

ExecutedTransactions.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.number,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
  isActionsDisabled: PropTypes.bool,
};

export default ExecutedTransactions;
