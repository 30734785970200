import React, { useState } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Pagination from '../../Pagination';
import { formatDatetime } from '../../../utils/formatter';
import { Box, Button, CircularProgress, IconButton } from '@material-ui/core';
import openIntoNewTab from '../../../utils/openIntoNewTab';
import ExpandableRow from './components/ExpandableRow';

const useStyles = makeStyles(() => ({
  table: {
    marginBottom: '20px',
    tableLayout: 'fixed',
    '& .MuiTableCell-body:has(.expanded-table)': {
      backgroundColor: '#fbfbfb',
    },
  },
  toolbar: {
    gap: '8px',
    paddingTop: '12px',
  },
}));

const ListActions = () => {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleDownloadPayouts = async () => {
    await dataProvider
      .downloadFile('money-transfer/bbva/generate-payout-list')
      .then(({ data, file_name }) => {
        openIntoNewTab(data, file_name);
        refresh();
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const handleUploadPayouts = async e => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);

    try {
      const { data } = await dataProvider.query('money-transfer/bbva/upload-report', {
        method: 'POST',
        body: formData,
      });

      if (data.status_code === 'success') {
        const { failed, success, skipped } = data.data;
        notify(
          `Failed transactions - ${failed}; Success transactions - ${success}; Skipped transactions - ${skipped};`,
          'success',
        );
      }

      setLoading(false);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
      setLoading(false);
    }
  };

  return (
    <TopToolbar className={classes.toolbar}>
      <label htmlFor="upload_file">
        <input
          style={{ display: 'none' }}
          type="file"
          id="upload_file"
          accept=".xlsx"
          onChange={handleUploadPayouts}
          onClick={e => {
            e.target.value = null;
          }}
        />
        <Button variant="contained" color="primary" aria-label="upload file" component="span">
          {loading ? <CircularProgress color="white" size={24} /> : 'Attach report'}
        </Button>
      </label>
      <Button variant="contained" color="primary" onClick={handleDownloadPayouts}>
        Create BBVA report
      </Button>
    </TopToolbar>
  );
};

const MoneyTransferDocsList = props => {
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const downloadHandler = (e, fileId) => {
    e.stopPropagation();
    dataProvider
      .downloadFile(`files/${fileId}/get_file`)
      .then(({ data, file_name }) => openIntoNewTab(data, file_name))
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
      });
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
      empty={<ListActions />}
      {...props}>
      <Datagrid
        isRowExpandable={({ money_transfers }) => !!money_transfers.length}
        rowClick={(id, basePath, record) => (record.money_transfers.length ? 'expand' : null)}
        expand={<ExpandableRow />}
        className={classes.table}>
        <TextField source="id" />
        <FunctionField
          label="File ID"
          render={({ file_id, money_transfers }) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography>{file_id}</Typography>
              {!!money_transfers.length && (
                <IconButton color="primary" size="small" onClick={e => downloadHandler(e, file_id)}>
                  <CloudDownloadIcon />
                </IconButton>
              )}
            </Box>
          )}
        />
        <FunctionField label="Money transfers count" render={({ money_transfers }) => money_transfers.length} />
        <FunctionField
          label="Created at"
          source="created_at"
          sortable={false}
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
      </Datagrid>
    </List>
  );
};

export default MoneyTransferDocsList;
