import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Show, useRefresh, usePermissions } from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TabbedInfoCard from '../../user/TabbedInfoCard';

import { useStyles } from './styles';
import ApplicationInfo from './components/ApplicationInfo';
import ApplicationTransitions from './components/ApplicationTransitions';
import Actions from './components/Actions';
import AdditionalInfo from './components/AdditionalInfo';

const Layout = ({ record }) => {
  const [refreshedAt, setRefreshedAt] = useState();
  const [appCanBeRestarted, setAppCanBeRestarted] = useState(false);

  const classes = useStyles();
  const onRefresh = useRefresh();
  const { permissions = [] } = usePermissions();

  const refresh = () => {
    setRefreshedAt(Date.now());
    onRefresh();
  };

  useEffect(() => {
    ['pending', 'processing', 'error'].includes(record.state) &&
      [null, 'sls-de'].includes(record.decision_engine_id) &&
      record.aventus_decision_engine_state !== 'processed' &&
      setAppCanBeRestarted(true);
  }, [record]);

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} justifyContent="space-between">
        {permissions.includes('CAN_APPLICATION_EDIT') ? (
          <ApplicationTransitions
            record={record}
            refreshedAt={refreshedAt}
            refresh={refresh}
            appCanBeRestarted={appCanBeRestarted}
          />
        ) : (
          <>&nbsp;</>
        )}
        <Actions record={record} />
      </Grid>
      <Grid item xs={12}>
        <AdditionalInfo record={record} refreshedAt={refreshedAt} refresh={refresh} />
      </Grid>
      <Grid item xs={12} sm={6}>
        {permissions.includes('CAN_USER_VIEW') ? (
          <TabbedInfoCard user_id={record.user_id} />
        ) : (
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom={true}>
              User info
            </Typography>
            <Typography variant="body1">-- You do not have sufficient rights to view customer details --</Typography>
          </Paper>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <ApplicationInfo record={record} />
      </Grid>
    </Grid>
  );
};

Layout.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.number,
    state: PropTypes.string,
    decision_engine_id: PropTypes.string || null,
    aventus_decision_engine_state: PropTypes.string,
  }),
};

const ApplicationShow = props => (
  <Show component="div" {...props}>
    <Layout />
  </Show>
);

export default ApplicationShow;
