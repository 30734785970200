import React, { useEffect, useState } from 'react';
import { Create, required, SelectInput, SimpleForm, TextInput, useDataProvider } from 'react-admin';
import snakeToHuman from '../../../utils/snakeToHuman';
import { CircularProgress, Container } from '@material-ui/core';
import PropTypes from 'prop-types';

// NOTE: to avoid basePath warning in console
// eslint-disable-next-line unused-imports/no-unused-vars
const SanitizedContainer = ({ basePath, ...props }) => {
  return <Container {...props} />;
};

SanitizedContainer.propTypes = {
  basePath: PropTypes.string,
};

const ProductCreate = props => {
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dataProvider = useDataProvider();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .fetch('product-strategy/enabled')
      .then(({ data }) => {
        const strategyObj = data.names.map(item => ({ value: item, name: snakeToHuman(item) }));
        setState(strategyObj);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="product_name" validate={[required()]} />
        <TextInput source="product_code" validate={[required()]} />
        <SanitizedContainer style={{ padding: 0 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SelectInput
              source="strategy_name"
              label="Strategy"
              choices={state}
              optionText="name"
              optionValue="value"
              validate={[required()]}
              disabled={error}
              style={{ width: '256px' }}
            />
          )}
        </SanitizedContainer>
        <TextInput multiline minRows={3} source="product_description" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate;
