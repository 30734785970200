import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { BooleanInput, Create, SelectInput, SaveButton, TextInput, FormWithRedirect } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { formatChoices, TEMPLATE_TYPES } from '../../../utils/dictionary.js';

import { SmsCount } from './SmsCount';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const editor = useRef(null);
  const classes = useStyles();

  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');

  const isEmailCategory = category === 'Email';
  const isPDFCategory = category === 'PDF';

  const config = {
    height: 500,
    language: 'en',
    speechRecognize: false,
    showPlaceholder: false,
    spellcheck: false,
    enableDragAndDropFileToEditor: true,
    hidePoweredByJodit: true,
    useSearch: false,
    showCharsCounter: false,
    showWordsCounter: false,
    memorizeChoiceWhenPasteFragment: false,
    iframe: false,
    editHTMLDocumentMode: false,
  };

  if (isPDFCategory) {
    config.iframe = true;
    config.editHTMLDocumentMode = true;
  }

  const handleSave = (data, ...rest) => {
    isEmailCategory || isPDFCategory
      ? save(...[{ ...data, template: content }, ...rest])
      : save(...[{ ...data }, ...rest]);
  };

  return (
    <FormWithRedirect
      save={handleSave}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container fullWidth spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                      Create template
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SelectInput
                      fullWidth
                      source="category"
                      choices={[
                        { id: 'SMS', name: 'SMS' },
                        { id: 'Email', name: 'Email' },
                        { id: 'PDF', name: 'PDF' },
                        { id: 'Calendar', name: 'Calendar' },
                        { id: 'Email_layout', name: 'Email layout' },
                      ]}
                      onChange={e => {
                        setCategory(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextInput fullWidth source="key" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SelectInput
                      fullWidth
                      source="type"
                      initialValue="System"
                      choices={formatChoices(TEMPLATE_TYPES)}
                    />
                  </Grid>

                  {isEmailCategory || isPDFCategory ? (
                    <>
                      <Grid item xs={12}>
                        <TextInput fullWidth source="subject" />
                      </Grid>
                      <Grid item xs={12}>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1}
                          onBlur={newContent => setContent(newContent)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <TextInput multiline fullWidth source="template" />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <BooleanInput label="Is promo code" source="is_promo_code" />
                  </Grid>
                  {!(isEmailCategory || isPDFCategory) && (
                    <Grid item xs={12}>
                      <SmsCount />
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <SaveButton
                  label="Submit"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const TemplateCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default TemplateCreate;
