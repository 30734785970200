import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import { BooleanInput, Edit, SaveButton, SelectInput, FormWithRedirect, TextInput, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { SmsCount } from './SmsCount';
import { invalidateCache } from '../../../hooks/useHandbook';
import { formatChoices, TEMPLATE_TYPES } from '../../../utils/dictionary.js';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const editor = useRef(null);
  const classes = useStyles();

  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');

  const { permissions = [] } = usePermissions();

  const isEditable = permissions.indexOf('CAN_TEMPLATE_EDIT') !== -1;
  const isEmailCategory = category === 'Email';
  const isPDFCategory = category === 'PDF';

  const config = {
    height: 500,
    language: 'en',
    speechRecognize: false,
    showPlaceholder: false,
    spellcheck: false,
    disabled: !isEditable,
    enableDragAndDropFileToEditor: true,
    hidePoweredByJodit: true,
    useSearch: false,
    showCharsCounter: false,
    showWordsCounter: false,
    memorizeChoiceWhenPasteFragment: false,
    iframe: false,
    editHTMLDocumentMode: false,
  };

  if (isPDFCategory) {
    config.iframe = true;
    config.editHTMLDocumentMode = true;
  }

  const handleSave = (data, ...rest) => {
    isEmailCategory || isPDFCategory
      ? save(...[{ ...data, template: content, _params: { method: 'PATCH' } }, ...rest])
      : save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest]);
    invalidateCache('templates');
  };

  useEffect(() => {
    record.template && setContent(record.template);
    record.category && setCategory(record.category);
  }, [record]);

  return (
    <FormWithRedirect
      save={handleSave}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container fullWidth spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom={false}>
                      Edit template
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SelectInput
                      fullWidth
                      disabled={!isEditable}
                      source="category"
                      onChange={event => {
                        setCategory(event.target.value);
                      }}
                      choices={[
                        { id: 'SMS', name: 'SMS' },
                        { id: 'Email', name: 'Email' },
                        { id: 'PDF', name: 'PDF' },
                        { id: 'Calendar', name: 'Calendar' },
                        { id: 'Email_layout', name: 'Email layout' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextInput fullWidth disabled={!isEditable} source="key" />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <SelectInput
                      fullWidth
                      source="type"
                      initialValue="System"
                      choices={formatChoices(TEMPLATE_TYPES)}
                    />
                  </Grid>
                  {isEmailCategory || isPDFCategory ? (
                    <>
                      <Grid item xs={12}>
                        <TextInput fullWidth source="subject" />
                      </Grid>
                      <Grid item xs={12}>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1}
                          onBlur={newContent => setContent(newContent)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12}>
                      <TextInput disabled={!isEditable} multiline fullWidth source="template" />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <BooleanInput disabled={!isEditable} label="Is promo code" source="is_promo_code" />
                  </Grid>
                  {!(isEmailCategory || isPDFCategory) && (
                    <Grid item xs={12}>
                      <SmsCount />
                    </Grid>
                  )}
                </Grid>
                <Divider className={classes.my2} />
                <SaveButton
                  label="Submit"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  disabled={!isEditable}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    category: PropTypes.string,
    id: PropTypes.number,
    is_promo_code: PropTypes.bool,
    key: PropTypes.string,
    locale: PropTypes.string,
    subject: PropTypes.oneOfType([PropTypes.string]),
    template: PropTypes.string,
    type: PropTypes.string,
  }),
};

const TemplateEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default TemplateEdit;
