import React, { useState } from 'react';
import { Box, TextField, Button, IconButton, Tooltip, Badge, makeStyles } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { getApiEntrypoint } from '../../utils/env';

const useStyles = makeStyles(() => ({
  badge: {
    '& .MuiBadge-badge': { top: '15px', right: '10px' },
  },
}));

const clearStorage = () => {
  localStorage.removeItem('admin_fields');
  localStorage.removeItem('admin_access_token');
  localStorage.removeItem('admin_access_token_expire_at');
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
};

export const BaseUrlHandler = () => {
  const [url, setUrlVal] = useState(getApiEntrypoint);
  const [isDirty, setIsDirty] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isDefault = url === process.env.REACT_APP_API_ENTRYPOINT;

  const classes = useStyles();

  const baseUrlHandler = e => {
    setUrlVal(e.target.value);
    setIsDirty(true);
  };

  const changeUrlHandler = () => {
    localStorage.setItem('app_api_entrypoint', url);
    clearStorage();
    setIsOpen(false);
    window.location.reload();
  };

  const resetURLHandler = () => {
    localStorage.removeItem('app_api_entrypoint');
    clearStorage();
    setIsOpen(false);
    window.location.reload();
  };

  const onToggle = () => {
    if (!url) {
      setUrlVal(getApiEntrypoint());
    }

    setIsOpen(prevState => !prevState);
  };

  const closeDialog = () => {
    setUrlVal(getApiEntrypoint);
    setIsOpen(false);
  };

  return (
    <Box sx={{ mr: 2 }}>
      <Tooltip title="Change base URL" aria-label="change-base-url" arrow>
        <Badge color="error" badgeContent="!" invisible={isDefault} className={classes.badge} overlap="rectangular">
          <IconButton size="medium" onClick={onToggle} color="inherit">
            <StorageIcon />
          </IconButton>
        </Badge>
      </Tooltip>
      <Dialog open={isOpen} maxWidth={'sm'} fullWidth onClose={closeDialog} aria-labelledby="form-dialog-title">
        <IconButton
          color="inherit"
          onClick={closeDialog}
          aria-label="close"
          style={{ position: 'absolute', top: '9px', right: '10px' }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle id="form-dialog-title">Change base URL</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 1 }}>
            <TextField autoFocus margin="dense" id="base_url" fullWidth value={url} onChange={baseUrlHandler} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={!isDirty} variant="contained" color="primary" onClick={changeUrlHandler}>
            Change
          </Button>
          <Button disabled={isDefault} variant="contained" color="primary" onClick={resetURLHandler}>
            Reset to default
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
