/* eslint-disable*/
import { fetchUtils, HttpError } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import { getApiEntrypoint } from './utils/env';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('admin_access_token');
  if (token) {
    options.headers.set('X-AUTH-TOKEN', token);
  }

  return fetchUtils.fetchJson(url, options);
};

const apiEntrypoint = getApiEntrypoint();

const dataProvider = simpleRestProvider(apiEntrypoint, httpClient);

const cache = {};

export default {
  ...dataProvider,
  getList: (resource, params) => {
    const { page, perPage } = params?.pagination;
    const { field, order } = params?.sort;

    let url = `${apiEntrypoint}/${resource}`;
    const queryParts = [];
    if (field && order) {
      queryParts.push(`order[${encodeURIComponent(field)}]=${encodeURIComponent(order)}`);
    }

    if (Object.keys(params.filter).length) {
      Object.keys(params.filter)
        .filter(item => item.indexOf('_') === 0)
        .forEach(item => {
          if (!Object.prototype.hasOwnProperty.call(params.filter, item.substr(1))) {
            params.filter[item.substr(1)] = params.filter[item];
          }

          delete params.filter[item];
        });
      queryParts.push(
        Object.keys(params.filter)
          .map(k => {
            if (params.filter[k] instanceof Array) {
              return params.filter[k]
                .map(
                  item =>
                    `${encodeURIComponent(
                      k
                        .replace(/->/g, '.')
                        .replace(/\|(\w+)$/, '[$1]')
                        .replace(/__/g, ''),
                    )}[]=${encodeURIComponent(item)}`,
                )
                .join('&');
            } else {
              return `${encodeURIComponent(k.replace(/->/g, '.').replace(/\|(\.?[\w.]*\w\.?)$/, '[$1]'))}=${encodeURIComponent(params.filter[k])}`;
            }
          })
          .join('&'),
      );
    }

    if (page && perPage) {
      queryParts.push(`page=${page}`);
      queryParts.push(`items_per_page=${perPage}`);
    }

    if (queryParts.length) {
      url = url.concat('?', queryParts.join('&'));
    }
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: Array.isArray(json) ? json : [],
        total: parseInt(headers.get('content-range')?.split('/').pop(), 10) || json.length,
      };
    });
  },

  create: (resource, params) =>
    new Promise((resolve, reject) =>
      dataProvider
        .create(resource, params)
        .then(data => resolve(data))
        .catch(e => reject(new Error(e.body?.detail || e.message || 'Unknown error'))),
    ),

  update: (resource, params) => {
    const { _params = {}, ...data } = params.data;
    const { method = 'PATCH' } = _params;

    return new Promise((resolve, reject) => {
      httpClient(`${apiEntrypoint}/${resource}/${params.id}`, {
        method,
        body: JSON.stringify(data),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
        .then(({ json }) => resolve({ data: json }))
        .catch(e => reject(new Error(e.body?.detail || e.message || 'Unknown error')));
    });
  },

  post: (resource, params) => {
    const { _params = {}, ...data } = params.data;
    const { method = 'POST' } = _params;
    const endpointEnd = params?.removeTrailingSlash ? '' : '/';

    return new Promise((resolve, reject) => {
      httpClient(`${apiEntrypoint}/${resource}${endpointEnd}`, {
        method,
        body: JSON.stringify(data),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
        .then(({ json }) => {
          if (json.status_code === 'success') {
            resolve({ data: json });
          }

          // NOTE: add processing status_code
          resolve({ data: json });
        })
        .catch(e => reject(new Error(e.body?.detail || e.message || 'Unknown error')));
    });
  },

  fetch: (resource, params) => {
    return new Promise((resolve, reject) => {
      httpClient(`${apiEntrypoint}/${resource}`, {
        headers: new Headers({ 'Content-Type': 'application/json' }),
        ...params,
      })
        .then(({ json }) => resolve({ data: json }))
        .catch(e => reject(new Error(e.body?.detail || e.message || 'Unknown error')));
    });
  },

  delete: (resource, params) =>
    httpClient(`${apiEntrypoint}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json || {} })),

  query: (resource, params) => {
    return new Promise((resolve, reject) => {
      httpClient(`${apiEntrypoint}/${resource}`, {
        method: params.body ? 'POST' : 'GET',
        ...params,
      })
        .then(({ json }) => resolve({ data: json }))
        .catch(e => reject(new Error(e.body?.detail || e.message || 'Unknown error')));
    });
  },

  getImage: (resource, useCache = false) => {
    if (useCache && cache[resource]) {
      return Promise.resolve(cache[resource]);
    }
    const headers = new Headers();
    let responseHeaders = new Headers();
    const token = localStorage.getItem('admin_access_token');
    if (token) {
      headers.set('X-AUTH-TOKEN', token);
    }

    return fetch(`${apiEntrypoint}/${resource}`, { headers })
      .then(response => {
        responseHeaders = response.headers;
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(new HttpError(response.statusText, response.status));
        }

        return response.blob();
      })
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('load', () => resolve(reader.result));
          reader.addEventListener('error', () => reject(reader.error));
          reader.readAsDataURL(blob);
        });
      })
      .then(dataUrl => ({ data: dataUrl, headers: responseHeaders }))
      .then(res => {
        cache[resource] = res;

        return res;
      });
  },

  postImage: (resource, image) => {
    const headers = new Headers({ 'Content-Type': image.type });
    const token = localStorage.getItem('admin_access_token');
    if (token) {
      headers.set('X-AUTH-TOKEN', token);
    }

    return fetch(`${apiEntrypoint}/${resource}`, {
      body: image,
      method: 'POST',
      headers,
    }).then(response => {
      if (response.status < 200 || response.status >= 300) {
        return Promise.reject(new HttpError(response.statusText, response.status));
      }

      return Promise.resolve({ data: response.status });
    });
  },

  downloadFile: resource => {
    const headers = new Headers();
    const token = localStorage.getItem('admin_access_token');
    if (token) {
      headers.set('X-AUTH-TOKEN', token);
    }

    return fetch(`${apiEntrypoint}/${resource}`, { headers })
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(new HttpError(response.statusText, response.status));
        }

        return response.blob().then(blob => ({
          status: response.status,
          url: response.url,
          headers: response.headers,
          blob,
        }));
      })
      .then(response => {
        const disposition = response.headers.get('content-disposition');
        const file_name = disposition && disposition.includes('filename=') ? disposition.split('filename=')[1] : null;

        return {
          ...response,

          file_name,
          data: window.URL.createObjectURL(response.blob),
        };
      });
  },
};
