import { Box, Button, IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { usePermissions } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

export const EditButton = ({ onClick }) => {
  const { permissions = [] } = usePermissions();
  const canEdit = permissions.includes('CAN_PRODUCT_EDIT');

  return canEdit ? (
    <Tooltip title="Edit" arrow>
      <IconButton size="small" onClick={onClick}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  ) : null;
};

EditButton.propTypes = {
  onClick: PropTypes.func,
};

export const EmptyRow = ({ keyValue, showExtraGap = false }) => {
  return [0, 1].map(item => (
    <TableRow key={`${keyValue}-${item}`}>
      {[0, 1].map(item => (
        <TableCell
          key={`cell-${keyValue}-${item}`}
          style={{
            borderBottom: 'unset',
            paddingBlock: showExtraGap ? '12px' : '8px',
            backgroundColor: '#fff',
          }}></TableCell>
      ))}
    </TableRow>
  ));
};

EmptyRow.propTypes = {
  keyValue: PropTypes.string,
  showExtraGap: PropTypes.bool,
};

export const TableRowTitleSpanned = ({
  name,
  description,
  noTopOffset,
  border = '1px solid rgba(224, 224, 224, 1)',
  isNestedRow,
  hasCopyButton,
  onEditClick,
  onCopyClick,
  children,
}) => {
  return (
    <>
      {!noTopOffset && <EmptyRow keyValue={name.replace(' ', '_')} />}
      <TableRow style={{ backgroundColor: !isNestedRow && '#fff' }}>
        <TableCell colSpan="2" style={{ borderBottom: border, paddingBlock: isNestedRow ? '6px' : '16px' }}>
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '4px', width: '100%' }}>
            <Typography component={'span'} variant="subtitle1" style={{ fontSize: isNestedRow ? '14px' : '18px' }}>
              {name}
            </Typography>
            {isNestedRow && <EditButton onClick={onEditClick} />}

            {hasCopyButton && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{ marginLeft: 'auto' }}
                onClick={onCopyClick}>
                Copy
              </Button>
            )}
          </Box>{' '}
          {description && (
            <Typography component={'span'} variant="subtitle2" style={{ color: '#9d9d9d' }}>
              ({description})
            </Typography>
          )}
          {children && <Box style={{ marginTop: '20px' }}>{children}</Box>}
        </TableCell>
      </TableRow>
    </>
  );
};

TableRowTitleSpanned.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  noTopOffset: PropTypes.bool,
  border: PropTypes.string,
  isNestedRow: PropTypes.bool,
  onEditClick: PropTypes.func,
  hasCopyButton: PropTypes.bool,
  onCopyClick: PropTypes.func,
  children: PropTypes.node,
};

export const RowTitleCell = ({ title, description }) => {
  return (
    <TableCell style={{ width: '40%' }}>
      <Typography style={{ fontSize: '14px' }}>{title}</Typography>
      {description && <Typography style={{ color: '#9a9797', fontSize: '14px' }}>({description})</Typography>}
    </TableCell>
  );
};

RowTitleCell.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
