import React from 'react';
import { Route } from 'react-router-dom';

import Settings from './components/pages/settings/Settings';
import ScheduledTransactionsList from './components/pages/product/ScheduledTransactionsList';

export default [
  <Route key={'settingsRoute'} exact path="/settings" component={Settings} />,
  <Route key={'productsGetSchedule'} exact path="/products-get-schedule" component={ScheduledTransactionsList} />,
];
