import React from 'react';
import { CURRENCY } from '../../../../constants';
import { formatDatetime, formatMoneyNoCurrency } from '../../../../utils/formatter';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useRowStyles = makeStyles(() => ({
  cell: {
    boxSizing: 'border-box',
  },
  tableHeader: {
    backgroundColor: '#d8e3f0',
    border: '1px solid #cfcfcf',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  nestedTableRows: {
    backgroundColor: '#fff',
    border: '1px solid #edebeb',
  },
  sideHeaderWidth: {
    width: '145px',
  },
}));

const IncomesDetails = ({ details }) => {
  const classes = useRowStyles();
  return (
    <>
      <Typography variant="subtitle1" gutterBottom={false} style={{ border: 'unset' }}>
        Repayments:
      </Typography>
      <Table style={{ marginBottom: '20px' }} size="small">
        <TableHead>
          <TableRow className={classes.tableHeader}>
            <TableCell className={`${classes.cell}`}>Income</TableCell>
            <TableCell className={`${classes.cell}`}>Executed at</TableCell>
            <TableCell className={`${classes.cell}`}>Loan day</TableCell>
            <TableCell className={`${classes.cell}`}>Amount, {CURRENCY}</TableCell>
            <TableCell className={`${classes.cell}`}>IGV, {CURRENCY}</TableCell>
            <TableCell className={`${classes.cell}`}>Total, {CURRENCY}</TableCell>
            <TableCell className={`${classes.cell}`}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {Array.isArray(details) ? (
              details.map(item => (
                <TableRow key={item.uuid} className={classes.nestedTableRows}>
                  <TableCell>{item.income.id}</TableCell>
                  <TableCell>{formatDatetime(item.executed_at)}</TableCell>
                  <TableCell>{item.loan_day}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(item.repaid_accrued_amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(item.igv_amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(item.total_amount)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={details.uuid} className={classes.nestedTableRows}>
                <TableCell>{details.income.id}</TableCell>
                <TableCell>{formatDatetime(details.executed_at)}</TableCell>
                <TableCell>{details.loan_day}</TableCell>
                <TableCell>{formatMoneyNoCurrency(details.repaid_accrued_amount)}</TableCell>
                <TableCell>{formatMoneyNoCurrency(details.igv_amount)}</TableCell>
                <TableCell>{formatMoneyNoCurrency(details.total_amount)}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>
    </>
  );
};

IncomesDetails.propTypes = {
  details: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default IncomesDetails;
