import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { camelCaseToHuman, captitalizeFirstChar, formatDatetime } from '../../utils/formatter';
import { Box, Chip, Typography } from '@material-ui/core';
import { SLS_DECISION_ENGINE_TYPES } from '../../utils/dictionary';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

const SlsDecisionEngineDataTable = ({ recordId, refreshedAt, perPageCustom }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [slsDecisionEngineData, setSLSDecisionEngineData] = useState();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageCustom ?? 30);

  useEffect(() => {
    dataProvider
      .query(`applications/${recordId}/decision-engine`, {})
      .then(({ data }) => {
        const sort = data.data.slice().sort((a, b) => b.id - a.id);
        setSLSDecisionEngineData(sort);
        setLoading(false);
        setTotal(data.data.length);
      })
      .catch(error => {
        notify(`Error: ${error.message}`, { type: 'error' });
        setError(error);
        setLoading(false);
      });
  }, [recordId, dataProvider, refreshedAt, notify]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box mt={5} display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
        <Typography variant="body2">No data found</Typography>
      </Box>
    );
  }

  if (!Array.isArray(slsDecisionEngineData)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {[
              'Created at',
              'Update At',
              'Type',
              'State',
              'SLS decision',
              'Approved amount',
              'Approved tenor',
              'Score',
              'Stoplist term',
              'Verification reasons',
              'Rejection reasons',
            ].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {slsDecisionEngineData.map((row, id) => (
            <TableRow key={`${id}sls`}>
              <TableCell>{row.created_at && formatDatetime(row.created_at)}</TableCell>
              <TableCell>{row.updated_at && formatDatetime(row.updated_at)}</TableCell>
              <TableCell>
                <Chip size="small" label={SLS_DECISION_ENGINE_TYPES[row.type] ?? '--'} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={captitalizeFirstChar(row.state)} />
              </TableCell>
              <TableCell>
                <Chip size="small" label={camelCaseToHuman(row.decision)} />
              </TableCell>
              <TableCell>{row.approved_principal ?? '--'}</TableCell>
              <TableCell>{row.approved_tenor ?? '--'}</TableCell>
              <TableCell>{row.score ?? '--'}</TableCell>
              <TableCell>{row.moratorium_in_days ?? '--'}</TableCell>
              <TableCell>{row.verification_reasons ?? '--'}</TableCell>
              <TableCell>{row.rejected_reason ?? '--'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};
SlsDecisionEngineDataTable.propTypes = {
  recordId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
  perPageCustom: PropTypes.number,
};
SlsDecisionEngineDataTable.defaultProps = {
  recordId: null,
  refreshedAt: undefined,
  perPageCustom: undefined,
};
export default SlsDecisionEngineDataTable;
