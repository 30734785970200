import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency, formatDate } from '../../utils/formatter';
import { useProductList } from '../../hooks/useProductList';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));

const UserLoanApplicationDialog = ({ onClose, record }) => {
  const [productCode, setProductCode] = useState(null);
  const [principal, setPrincipal] = useState(null);
  const [displayedPrincipal, setDisplayedPrincipal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPrincipals, setNewPrincipals] = useState({ min: 0, max: 0, step: 0 });
  const [newTenors, setNewTenors] = useState([]);
  const [newTenor, setNewTenor] = useState(0);
  const [responseErrors, setResponseErrors] = useState({});
  const [isApplicationSubmitting, setIsApplicationSubmitting] = useState(false);

  const [calculation, setCalculation] = useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const { products: newProducts, isLoading: isProductsLoading } = useProductList();

  const selectedProduct = newProducts.length > 0 ? newProducts.find(product => productCode === product.code) : null;

  useEffect(() => {
    if (!selectedProduct && newProducts.length > 0) {
      setProductCode(newProducts[0].code);
      setNewPrincipals({
        min: newProducts[0].calculator.allowed_principals.min,
        max: newProducts[0].calculator.allowed_principals.max,
        step: newProducts[0].calculator.allowed_principals.step,
      });
      setNewTenors(newProducts[0].calculator.allowed_tenors);
      setDisplayedPrincipal(newProducts[0].calculator.default_principal);
      setPrincipal(newProducts[0].calculator.default_principal);
      setNewTenor(newProducts[0].calculator.default_tenor);
    }

    if (selectedProduct) {
      setNewPrincipals({
        min: selectedProduct.calculator.allowed_principals.min,
        max: selectedProduct.calculator.allowed_principals.max,
        step: selectedProduct.calculator.allowed_principals.step,
      });
      setNewTenors(selectedProduct.calculator.allowed_tenors);
    }
  }, [newProducts, selectedProduct]);

  useEffect(() => {
    if (principal && productCode && newTenor) {
      setLoading(true);
      const payload = {
        user_id: record.id,
        product_code: productCode,
        principal,
        tenor: newTenor,
      };

      dataProvider
        .post('application/calculate', { data: payload, removeTrailingSlash: true })
        .then(({ data }) => {
          setCalculation(data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          setError(error.message);
          notify(`Error: ${error.message}`, 'error');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principal, productCode, newTenor]);

  const handleSubmitApplication = () => {
    setIsApplicationSubmitting(true);
    const payload = {
      user_id: record.id,
      product_code: productCode,
      principal,
      tenor: newTenor,
    };

    dataProvider
      .query('application/create', { body: JSON.stringify(payload) })
      .then(({ data }) => {
        if (data.status_code === 'validation_error') {
          const errors = data.data.reduce((obj, item) => ({ ...obj, [item.property_path]: item.user_message }), {});
          setResponseErrors(errors);
          notify(`Error: ${data[0].user_message}`, 'error');
          return;
        }
        setIsApplicationSubmitting(false);
        redirect(`/applications/${data.id}/show`);
      })
      .catch(error => {
        notify(`Error: ${error.message}`, 'error');
        setIsApplicationSubmitting(false);
      });
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">Create loan application</DialogTitle>
      <DialogContent>
        <DialogContentText>To create loan application, please fill following fields</DialogContentText>
        <Form
          onSubmit={handleSubmitApplication}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="productCode-select-label" shrink={true}>
                  Product
                </InputLabel>
                {isProductsLoading ? (
                  <CircularProgress size={20} style={{ marginTop: '16px' }} />
                ) : (
                  <Select
                    key={`user-edit-products-input-${newProducts.length}`}
                    labelId="productCode-select-label"
                    value={productCode || ''}
                    onChange={e => {
                      setProductCode(e.target.value);
                      const product = newProducts.find(product => e.target.value === product.code);

                      setNewTenor(product.calculator.default_tenor);
                      setPrincipal(product.calculator.default_principal);
                      setDisplayedPrincipal(product.calculator.default_principal);
                    }}>
                    {newProducts.map(product => (
                      <MenuItem key={product.code} value={product.code}>
                        {product.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <Box style={{ marginBottom: '16px' }}>
                <Typography
                  className={classes.mt2}
                  id="principal-slider"
                  gutterBottom
                  style={{ fontSize: '12px', color: '#0000008a' }}>
                  Principal: {formatCurrency(displayedPrincipal)}
                </Typography>
                {isProductsLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Slider
                    defaultValue={displayedPrincipal}
                    value={displayedPrincipal}
                    aria-labelledby="principal-slider"
                    valueLabelDisplay="auto"
                    style={{ padding: 0 }}
                    step={newPrincipals.step ?? 100}
                    min={+newPrincipals.min ?? 100}
                    max={+newPrincipals.max ?? 5000}
                    onChangeCommitted={(ev, value) => setPrincipal(value)}
                    onChange={(ev, value) => {
                      setDisplayedPrincipal(value);
                    }}
                  />
                )}
                {responseErrors.principal && (
                  <Typography color="error" style={{ fontSize: '12px' }}>
                    {responseErrors.principal}
                  </Typography>
                )}
              </Box>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="product-tenor-select-label" shrink={true}>
                  Tenor
                </InputLabel>
                {isProductsLoading ? (
                  <CircularProgress size={20} style={{ marginTop: '16px' }} />
                ) : (
                  <Select
                    labelId="product-tenor-select-label"
                    defaultValue={newTenor}
                    value={newTenor}
                    onChange={e => {
                      setNewTenor(e.target.value);
                    }}>
                    {newTenors.map(tenor => (
                      <MenuItem key={tenor} value={tenor}>
                        {tenor}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
              <Typography className={classes.mt2} variant="h6" component="div">
                Calculation preview
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : calculation ? (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product code</TableCell>
                        <TableCell>Principal</TableCell>
                        <TableCell>Tenor</TableCell>
                        <TableCell>Interest amount</TableCell>
                        <TableCell>IGV amount</TableCell>
                        <TableCell>Settlement amount</TableCell>
                        <TableCell>Maturity date by plan</TableCell>
                        <TableCell>Real maturity date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{calculation.product_code}</TableCell>
                        <TableCell>{formatCurrency(+calculation.principal)}</TableCell>
                        <TableCell>{calculation.tenor} days</TableCell>
                        <TableCell>{formatCurrency(+calculation.interest_amount)}</TableCell>
                        <TableCell>{formatCurrency(+calculation.igv_amount)}</TableCell>
                        <TableCell>{formatCurrency(+calculation.settlement_amount)}</TableCell>
                        <TableCell>{formatDate(calculation.maturity_date_by_plan)}</TableCell>
                        <TableCell>{formatDate(calculation.real_maturity_date)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}

              {error ? (
                <Typography variant="subtitle1" color="error">
                  {error.message}
                </Typography>
              ) : null}
              <DialogActions>
                <Button onClick={onClose} color="primary" disabled={isApplicationSubmitting}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={isApplicationSubmitting}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

UserLoanApplicationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  record: PropTypes.object,
};

export default UserLoanApplicationDialog;
