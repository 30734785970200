import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { TIMEZONE_OFFSET } from '../../../utils/dictionary';
import getAdminTimezone from '../../../utils/getAdminTimezone';
import { timezoneOffsetLabel } from '../../../utils/adapter';
import { GLOBAL_SETTINGS_OPTIONS_MAP } from './options';
import { debounce } from 'lodash';

const useStyles = makeStyles(theme => ({
  mt2: {
    marginTop: theme.spacing(2),
  },
  width220: {
    minWidth: '220px',
  },
}));

const GlobalSettingsListItem = ({
  item: { id, name, value, nested_settings: stored_nested_settings = null },
  onChange,
  onChangeNested,
}) => {
  const [state, setState] = useState(value);
  const { permissions = [] } = usePermissions();
  const classes = useStyles();

  if (!GLOBAL_SETTINGS_OPTIONS_MAP[name]) {
    return (
      <ListItem>
        <Typography variant="caption" color="error">
          Undefined setting name: <code>{name}</code>
        </Typography>
      </ListItem>
    );
  }

  const { title, icon, options, nested_settings } = GLOBAL_SETTINGS_OPTIONS_MAP[name];
  const canEdit = permissions.includes('CAN_GLOBAL_SETTINGS_EDIT');

  return (
    <>
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        <ListItemSecondaryAction>
          {options ? (
            <Select
              value={state ?? ''}
              onChange={event => {
                setState(event.target.value?.toString());
                onChange(id, event.target.value?.toString() || null);
              }}
              disabled={!canEdit}>
              <MenuItem value={''}>-</MenuItem>
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              className={classes.width220}
              disabled={!canEdit}
              value={state ?? ''}
              onChange={event => {
                if (['annual_disbursement_limit', 'client_tracking_disbursement_limit'].includes(name)) {
                  setState(event.target.value.replace(/[^0-9.,]/g, ''));
                  event.target.value = event.target.value.replace(/[^0-9.,]/g, '');
                }

                setState(event.target.value);
                onChange(id, event.target.value || null);
              }}
            />
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {name === 'auto_money_transfer_config' &&
        value === 1 &&
        Object.keys(nested_settings).map(nested_setting_name => {
          const nested_setting = nested_settings[nested_setting_name];

          return (
            <ListItem key={nested_setting_name}>
              <ListItemText primary={nested_setting.title} />
              <ListItemSecondaryAction>
                <Switch
                  color="primary"
                  checked={
                    Array.isArray(stored_nested_settings) &&
                    Boolean(stored_nested_settings.find(item => item.name === nested_setting_name)?.value)
                  }
                  onChange={event => {
                    onChangeNested(id, nested_setting_name, event.target.checked ? 1 : 0);
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </>
  );
};

GlobalSettingsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    nested_settings: PropTypes.array,
  }),
  onChange: PropTypes.func,
  onChangeNested: PropTypes.func,
};

export default () => {
  const [timezone, setTimezone] = useState(getAdminTimezone(false));
  const [globalSettings, setGlobalSettings] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  useEffect(() => {
    const onChangeTimezone = () => {
      setTimezone(getAdminTimezone(false));
    };

    document.addEventListener('change-timezone', onChangeTimezone);

    return () => {
      document.removeEventListener('change-timezone', onChangeTimezone);
    };
  }, []);

  useEffect(() => {
    if (timezone !== getAdminTimezone(false)) {
      localStorage.setItem('admin_timezone', timezone);
      document.dispatchEvent(new Event('change-timezone'));
    }
  }, [timezone]);

  useEffect(() => {
    if (!permissions.length) {
      return;
    }

    if (permissions.indexOf('CAN_GLOBAL_SETTINGS_VIEW') === -1) {
      setLoading(false);
      return;
    }

    dataProvider
      .getList('global_settings?items_per_page=100', {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then(({ data }) => {
        setGlobalSettings(data);
      })
      .catch(error => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [dataProvider, permissions]);

  if (error) {
    notify(`Error: ${error.message}`, 'error');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeGlobalSetting = React.useCallback(
    debounce((id, value) => {
      if (id === 7 && !value) {
        handleChangeNestedSettings(id, 'auto_money_transfer_config', value, true);
        return;
      }

      dataProvider
        .update('global_settings', { id, data: { value } })
        .then(({ data }) => setGlobalSettings(globalSettings.map(item => (item.id === data.id ? data : item))))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }, 1000),
    [globalSettings],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeNestedSettings = React.useCallback(
    debounce((id, name, value, isDefault) => {
      const nested_settings = Object.keys(GLOBAL_SETTINGS_OPTIONS_MAP.auto_money_transfer_config.nested_settings).map(
        nested_setting_name => ({
          name: nested_setting_name,
          value: isDefault
            ? 0
            : name === nested_setting_name
              ? value
              : globalSettings
                  .find(item => item.id === id)
                  .nested_settings.find(item => item.name === nested_setting_name).value,
        }),
      );
      dataProvider
        .update('global_settings', {
          id,
          data: {
            value: isDefault ? 0 : 1,
            nested_settings,
          },
        })
        .then(({ data }) => setGlobalSettings(globalSettings.map(item => (item.id === data.id ? data : item))))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }, 1000),
    [globalSettings],
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item sm={9}>
        <Paper>
          <List subheader={<ListSubheader disableSticky>UI</ListSubheader>}>
            <ListItem>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Timezone" />
              <ListItemSecondaryAction>
                <Select value={timezone} onChange={event => setTimezone(event.target.value)}>
                  {Object.entries(TIMEZONE_OFFSET).map(([tz, offsetFunc]) => (
                    <MenuItem key={tz} value={tz}>
                      {tz}
                      {tz !== 'UTC' ? ` (UTC${timezoneOffsetLabel(offsetFunc())})` : ''}
                    </MenuItem>
                  ))}
                </Select>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
        {permissions.indexOf('CAN_GLOBAL_SETTINGS_VIEW') !== -1 ? (
          <Paper className={classes.mt2}>
            <List subheader={<ListSubheader disableSticky>System</ListSubheader>}>
              {loading || error || !Array.isArray(globalSettings) ? (
                <CircularProgress />
              ) : (
                globalSettings.map((item, idx) => (
                  <GlobalSettingsListItem
                    globalSettings={globalSettings}
                    key={idx}
                    item={item}
                    onChangeNested={handleChangeNestedSettings}
                    onChange={onChangeGlobalSetting}
                  />
                ))
              )}
            </List>
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
};
