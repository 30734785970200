import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  DeleteButton,
  Edit,
  maxLength,
  minLength,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import PropTypes from 'prop-types';
import { phoneNumber } from '../../../utils/adapter';
import { formatDatetime } from '../../../utils/formatter';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const UserEditToolbar = props => {
  const classes = useStyles();
  const { permissions = [] } = usePermissions();

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton />
      {permissions.indexOf('CAN_BLACK_LIST_DELETE') !== -1 && <DeleteButton />}
    </Toolbar>
  );
};

const FormWrapper = ({ save, record, ...props }) => {
  return (
    <SimpleForm
      save={(data, ...rest) => save(...[{ ...data, _params: { method: 'PATCH' } }, ...rest])}
      toolbar={<UserEditToolbar />}
      {...props}>
      <Grid container fullWidth spacing={2}>
        <Grid item xs={12} md={3}>
          <NumberInput fullWidth source="user_id" label="User id" disabled />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="dni"
            disabled={!!record.user_id}
            validate={[
              required('The DNI field is required'),
              maxLength(8, 'DNI length should be 8 symbols'),
              minLength(8, 'DNI length should be 8 symbols'),
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="first_name"
            validate={required('This field is required')}
            disabled={!!record.user_id}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="father_surname"
            validate={required('This field is required')}
            disabled={!!record.user_id}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="mother_surname"
            validate={required('This field is required')}
            disabled={!!record.user_id}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="phone_number"
            placeholder={phoneNumber.placeholder}
            parse={phoneNumber.parser}
            format={v => phoneNumber.formatter(v)}
            disabled={!!record.user_id}
            validate={required('This field is required')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="email"
            disabled={!!record.user_id}
            validate={required('This field is required')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextInput
            fullWidth
            source="created_at"
            label="Date"
            disabled
            format={v => (isNaN(Date.parse(v)) ? null : formatDatetime(v))}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            source="comment"
            validate={required('This field is required')}
            disabled={!!record.user_id}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    user_id: PropTypes.number,
  }),
};

const BlackListEdit = props => {
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <FormWrapper />
    </Edit>
  );
};

export default BlackListEdit;
