import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  EmailField,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  usePermissions,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import { formatDate, formatPhoneNumber, formatTime } from '../../../utils/formatter';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import QuickFilterInput from '../../filter/QuickFilterInput';
import Call from '../../call/Call';
import { useHandbook } from '../../../hooks/useHandbook';
import { marginZeroStyles } from '../../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const ListFilter = props => {
  const { choices: employmentTypeChoices } = useHandbook('employments');
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput source="search_by_name" label="Search by name" alwaysOn />
      <NumberInput label="Phone number" source="profile->phone_number" alwaysOn />
      <TextInput source="uuid" label="Search by user UUID" alwaysOn />
      <NumberInput label="Registration step" source="registration_step" />
      <SelectInput
        label="Status"
        source="state"
        choices={[
          { id: 'created', name: 'Created' },
          { id: 'confirmed', name: 'Confirmed' },
          { id: 'active', name: 'Active' },
          { id: 'blocked', name: 'Blocked' },
        ]}
      />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <TextInput label="Contract number" source="applications->contractNumber" />
      <TextInput label="Father surname" source="profile->father_surname" />
      <TextInput label="Mother surname" source="profile->mother_surname" />
      <TextInput label="Document number" source="userDocuments->number" />
      <TextInput label="Email" source="profile->email" />
      <TextInput label="Account number" source="bankAccounts->account_number" />
      <SelectInput label="Empl. type" source="employment->type->code" choices={employmentTypeChoices} />
      <QuickFilterInput source="sleeping_clients" label="Sleeping clients" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <QuickFilterInput source="has_failed_money_transfer" label="Has failed money transfer" defaultValue={true} />
      <QuickFilterInput
        source="disbursement_limit_monitoring"
        label="Disbursement Limit monitoring"
        defaultValue={true}
      />
    </Filter>
  );
};

export const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export const UserList = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  const canSeePhone = !permissions.includes('CAN_SEE_FULL_PHONE');

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filter={props.filter ?? null}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <FunctionField
          source="created_at"
          label="Registered at"
          sortBy="created_at"
          render={record => (
            <>
              <Box>{formatDate(record.created_at)}</Box>
              <Box>{formatTime(record.created_at)}</Box>
            </>
          )}
        />
        <FunctionField
          source="phone_number"
          sortBy="profile.phone_number"
          render={(record, key) =>
            record[key] && (
              <Call userId={record.id} userPhone={record.username}>
                {formatPhoneNumber(record[key], canSeePhone)}
              </Call>
            )
          }
        />
        <FunctionField
          label="Full name"
          render={({ first_name, father_surname, mother_surname }) => (
            <Box>
              <Box>
                {first_name} {father_surname} {mother_surname}
              </Box>
            </Box>
          )}
        />
        <FunctionField
          label="Gender"
          render={({ gender }) => {
            return (
              <Box>
                <Box>{gender}</Box>
              </Box>
            );
          }}
        />
        <EmailField source="email" sortBy="profile.email" />
        <TextField source="employment_type" label="Empl. type" sortBy="employment.type" />
        <FunctionField
          label="Step"
          source="registration_step"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
        />
        <FunctionField
          label="Affiliate"
          source="affiliate"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
          sortBy="affiliate.name"
        />
        {permissions.indexOf('CAN_IMPERSONATION') !== -1 && (
          <FunctionField
            label="Actions"
            render={({ id }) => (
              <Button
                variant="contained"
                color="primary"
                onClick={e => {
                  e.stopPropagation();
                  dataProvider
                    .query(`users/${id}/token`, { method: 'GET' })
                    .then(({ data }) => {
                      window.open(
                        `${process.env.REACT_APP_FRONT_HOST}/user/registration?impersonate=${encodeURIComponent(JSON.stringify(data))}`,
                      );
                    })
                    .catch(error => {
                      notify(`Error: ${error.message || 'token not found'}`, 'error');
                    });
                }}>
                Impersonate
              </Button>
            )}
          />
        )}
        <FunctionField
          label="New tab"
          render={record => (
            <Link href={`#users/${record.id}`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

UserList.propTypes = {
  filter: PropTypes.any,
};

export const UsersFiltered = (WrappedComponent, filter) => {
  return props => {
    return <WrappedComponent filter={filter} {...props} />;
  };
};
