import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

import TransactionType from '../../../field/TransactionType';
import { formatDatetime, formatMoneyNoCurrency } from '../../../../utils/formatter';
import { Typography } from '@material-ui/core';
import { CURRENCY } from '../../../../constants';
import IncomesDetails from './IncomeDetails';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  executedTransactionCheckbox: {
    margin: `-${theme.spacing(2)}px `.repeat(3).trim(),
  },
  executedTransactionSwitch: {
    margin: `-12px `.repeat(3).trim(),
  },
  border: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  wrapper: {
    padding: 0,
    width: '100%',
    border: 'none',
  },
  parentRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.03)' },
  },
  cell: {
    boxSizing: 'border-box',
  },
  tableHeader: {
    backgroundColor: '#d8e3f0',
    border: '1px solid #cfcfcf',
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  nestedTableRows: {
    backgroundColor: '#fff',
    border: '1px solid #edebeb',
  },
  sideHeaderWidth: {
    width: '145px',
  },
}));

const ColapsibleRow = props => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        className={classes.parentRow}
        style={{ borderBottom: open ? '1px solid #cfcfcf' : 'unset' }}>
        <TableCell className={`parent_cell_${row.uuid}`}>{row.loan_day}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>{row.days_past_due ?? '-'}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>{formatDatetime(row.executed_at)}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>
          <TransactionType type={row.type} />
        </TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>{formatMoneyNoCurrency(row.total.amount)}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>{formatMoneyNoCurrency(row.total.paid_amount)}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`}>{formatMoneyNoCurrency(row.total.unpaid_amount)}</TableCell>
        <TableCell className={`parent_cell_${row.uuid}`} align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            className={classes.executedTransactionSwitch}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.border}>
        <TableCell style={{ padding: 0, width: '100%', border: 'none' }} colSpan={8}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{
              paddingInline: '16px',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
            }}>
            <Typography variant="subtitle1" gutterBottom={false} style={{ border: 'unset', marginTop: '10px' }}>
              Details:
            </Typography>
            <Table size="small" style={{ marginBottom: '20px' }}>
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}></TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}>Amount, {CURRENCY}</TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}>Paid, {CURRENCY}</TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}>Not paid, {CURRENCY}</TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}></TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}></TableCell>
                  <TableCell className={`subTableCell_${row.uuid} ${classes.cell}`}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.nestedTableRows}>
                  <TableCell className={`${classes.tableHeader} ${classes.sideHeaderWidth}`}>Accrued</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.accrued.amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.accrued.paid_amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.accrued.unpaid_amount)}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className={classes.nestedTableRows}>
                  <TableCell className={`${classes.tableHeader} ${classes.sideHeaderWidth}`}>
                    {/* TODO: get IGV dynamically */}
                    IGV, 18%
                  </TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.igv.amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.igv.paid_amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.igv.unpaid_amount)}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow className={classes.nestedTableRows}>
                  <TableCell className={`${classes.tableHeader} ${classes.sideHeaderWidth}`}>
                    {/* TODO: get IGV dynamically */}
                    Amount with IGV, 18%
                  </TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.total.amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.total.paid_amount)}</TableCell>
                  <TableCell>{formatMoneyNoCurrency(row.total.unpaid_amount)}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {!!row.repayments.length && <IncomesDetails details={row.repayments} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

ColapsibleRow.propTypes = {
  row: PropTypes.shape({
    loan_day: PropTypes.number,
    days_past_due: PropTypes.number,
    id: PropTypes.number,
    executed_at: PropTypes.string,
    accrued: PropTypes.shape({
      amount: PropTypes.string,
      fully_paid: PropTypes.bool,
      paid_amount: PropTypes.string,
      unpaid_amount: PropTypes.string,
    }),
    igv: PropTypes.shape({
      amount: PropTypes.string,
      fully_paid: PropTypes.bool,
      paid_amount: PropTypes.string,
      unpaid_amount: PropTypes.string,
    }),
    is_fully_paid: PropTypes.bool,
    type: PropTypes.string,
    is_igv_applied: PropTypes.bool,
    is_reverted: PropTypes.bool,
    repayments: PropTypes.arrayOf(
      PropTypes.shape({
        executed_at: PropTypes.string,
        igv_amount: PropTypes.string,
        income: PropTypes.shape({
          amount: PropTypes.string,
          not_spent_amount: PropTypes.string,
          processed_at: PropTypes.string,
          received_at: PropTypes.string,
          uuid: PropTypes.string,
        }),
        loan_day: PropTypes.number,
        repaid_accrued_amount: PropTypes.string,
        total_amount: PropTypes.string,
        uuid: PropTypes.string,
      }),
    ),
    total: PropTypes.shape({
      amount: PropTypes.string,
      fully_paid: PropTypes.bool,
      paid_amount: PropTypes.string,
      unpaid_amount: PropTypes.string,
    }),
    uuid: PropTypes.string,
  }),
};

export default ColapsibleRow;
